import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb } from 'react-bootstrap';
import { CallRequest } from '../../data/callRequestData';
import { getDashboardCallRequest, getDashboardCourseInquery } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { Link } from 'react-router-dom';
const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);


const CourseInqueryPage: React.FC = () => {
  const [callRequests, setCallRequests] = useState<CallRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [selectedCallRequest, setSelectedCallRequest] = useState<CallRequest | null>(null);
  const [note, setNote] = useState<string>('');
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardCourseInquery();
        setCallRequests(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: number) => {
    axios
      .delete(`http://localhost/api/dashboard/courses/call-requests/${id}`)
      .then(() => {
        setCallRequests(callRequests.filter((request) => request.id !== id));
      })
      .catch((error) => {
        setError('Error deleting call request');
      });
  };

  const handleMarkVerified = (id: number) => {
    axios
      .put(`http://localhost/api/dashboard/courses/call-requests/${id}/verify`)
      .then(() => {
        setCallRequests(
          callRequests.map((request) =>
            request.id === id ? { ...request, status: 'Verified' } : request
          )
        );
      })
      .catch((error) => {
        setError('Error marking as verified');
      });
  };

  const handleOpenNotesModal = (callRequest: CallRequest) => {
    setSelectedCallRequest(callRequest);
    setNote(callRequest.notes || '');
    setShowNotesModal(true);
  };

  const handleCloseNotesModal = () => {
    setShowNotesModal(false);
    setNote('');
  };

  const handleSaveNotes = () => {
    if (selectedCallRequest) {
      axios
        .put(`http://localhost/api/dashboard/courses/call-requests/${selectedCallRequest.id}/notes`, {
          notes: note,
        })
        .then(() => {
          setCallRequests(
            callRequests.map((request) =>
              request.id === selectedCallRequest.id ? { ...request, notes: note } : request
            )
          );
          setShowNotesModal(false);
        })
        .catch((error) => {
          setError('Error saving notes');
        });
    }
  };

  const filteredData = callRequests.filter((request) => {
    return (
      request.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.mobile.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.message.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">

    <div className="container mt-4">
    <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
          <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>Course Inquery : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Status</th>
            <th>Requested</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((request) => (
            <tr key={request.id}>
              <td>{request.id}</td>
              <td>{request.name}</td>
              <td>{request.mobile}</td>
              <td>{request.message}</td>
              <td>{request.status}</td>
              <td>{request.insertDate}</td>
              <td>{request.updateDate}</td>
              <td>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(request.id)}
                  className="me-2 navBtn"
                >
                  Delete
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleMarkVerified(request.id)}
                  className="me-2 navBtn"
                >
                  Mark Verified
                </Button>
                <Button
                className='navBtn'
                  variant="primary"
                  size="sm"
                  onClick={() => handleOpenNotesModal(request)}
                >
                  Add Notes
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Notes Modal */}
      <Modal show={showNotesModal} onHide={handleCloseNotesModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNotesModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNotes}>
            Save Notes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>

  );
};

export default CourseInqueryPage;
