import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import { getMostClickedCourses } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { Course } from '../../data/coursesData';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const MostClickedPage: React.FC = () => {
  const [Courses, setCourse] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMostClickedCourses();
        setCourse(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = Courses.filter((request) => {
    return (
      request.title.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Table striped hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
      <thead>
        <tr>
          <th>Course</th>
          <th>View</th>
          <th>Buy Now</th>
          <th>Watch Now</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((request) => (
          <tr key={request.id}>
            <td>
              <Link
                className="me-2 text-primary"
                to={`/course-details/${request.courseDisplayId}/${request.id}`} // Pass the course ID for editing
              >
                {request.title}
              </Link>
            </td>
            <td className='text-center'>
              #{request.CourseCount?.viewDetailCount}</td>
            <td className='text-center'>
              #{request.CourseCount?.buyNowCount
              }</td>
            <td className='text-center'>
              #{request.CourseCount?.watchNowCount

              }</td>
          </tr>
        ))}
      </tbody>
    </Table>

  );
};

export default MostClickedPage;
