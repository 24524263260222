import React from 'react';
import './ProjectRequirement.scss';
import { Helmet } from 'react-helmet-async';
import { PROJECTNAME } from '../../utils/constants';

const ProjectRequirement: React.FC = () => {
  return (
    <div className="project">
      <Helmet>
        <title>Project Requirements - Comprehensive Project Support</title>
        <meta name="description" content="Need assistance with your projects? We provide end-to-end support for various types of projects, including web development, mobile applications, and data analytics." />
        <meta name="keywords" content="project support, web development, mobile applications, data analytics, project assistance" />
        <meta name="author" content={PROJECTNAME} />

        {/* Open Graph (OG) meta tags */}
        <meta property="og:title" content="Project Requirements - Comprehensive Project Support" />
        <meta property="og:description" content="Need assistance with your projects? We provide end-to-end support for various types of projects, including web development, mobile applications, and data analytics." />
        <meta property="og:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://courseecho.com/services/project" /> {/* Replace with the specific page URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CourseEcho" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Project Requirements - Comprehensive Project Support" />
        <meta name="twitter:description" content="Need assistance with your projects? We provide end-to-end support for various types of projects, including web development, mobile applications, and data analytics." />
        <meta name="twitter:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
      </Helmet>

      <h1>Project Requirements</h1>
      <p>
        Need assistance with your projects? We provide end-to-end support for various types of projects, including web development, mobile applications, and data analytics.
      </p>
      <h2>Why Choose Us:</h2>
      <ul>
        <li>Expert guidance from industry professionals</li>
        <li>Timely delivery and quality assurance</li>
        <li>Customizable solutions to meet your needs</li>
        <li>Support for academic, professional, and personal projects</li>
      </ul>
      <p>
        Let us know your requirements, and we’ll help you bring your project ideas to life. Contact us today to discuss your needs!
      </p>
    </div>
  );
};

export default ProjectRequirement;
