import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CourseCard from '../components/CourseCard';
import { Course } from '../data/coursesData';
import { getCourseByCategoryDisplayId, getCourses } from '../services/CourseService';
import './Courses.scss';
import MetaHelmet from '../components/MetaHelmet';

interface CoursesProps {
  courses: Course[];
  isImported: boolean;
}

const Courses: React.FC<CoursesProps> = ({ isImported }) => {
  const [loading, setLoading] = useState(true); // State to handle loading

  const [cartItems, setCartItems] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const categoryDisplayId = searchParams.get('course-category');
  let title = 'Courses - Course Echo | Learn Programming and Development'
  if (categoryDisplayId) {
    title = `Courses in Category ${categoryDisplayId} - Course Echo | Learn ${categoryDisplayId} Courses`;
  }

  // State for search term, sorting option, and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState<'asc' | 'desc' | 'relevant'>('relevant');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [courses, setCourses] = useState<Course[]>([]);
  // Fetch courses when the component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courseData = categoryDisplayId
          ? await getCourseByCategoryDisplayId(categoryDisplayId || '')
          : await getCourses();
        setCourses(courseData); // Ensure resolved data is passed here
        setLoading(false)
      } catch (error) {
        console.error('Failed to load courses:', error);
        setLoading(false)
      }
    };
    fetchCourses();
  }, [categoryDisplayId]); // Add dependency on categoryDisplayId


  // Handle adding items to the cart
  const handleAddToCart = () => {
    setCartItems(cartItems + 1);
  };

  // Function to handle search input
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle sort change
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as 'asc' | 'desc' | 'relevant');
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Function to clear the filters (search and sort)
  const clearFilters = () => {
    setSearchTerm('');
    setSortOption('relevant');
    setCurrentPage(1);
  };

  // Filter courses based on the search term
  const filteredCourses = courses.filter(course => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      course.title.toLowerCase().includes(lowercasedSearchTerm) ||
      course.description.toLowerCase().includes(lowercasedSearchTerm) ||
      course.badgeText.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // If categoryDisplayId is available, filter courses by categoryDisplayId first
  const filteredAndSortedCourses = categoryDisplayId
    ? filteredCourses.filter((course) => course.categoryDisplayId === (categoryDisplayId))
    : filteredCourses;

  // Sort courses by fee
  const sortedCourses = [...filteredAndSortedCourses].sort((a, b) => {
    if (sortOption === 'asc') {
      return a.fee - b.fee;
    } else if (sortOption === 'desc') {
      return b.fee - a.fee;
    } else if (sortOption === 'relevant') {
      // Sort by the number of topics
      return (b.Topics?.length ?? 0) - (a.Topics?.length ?? 0); // Safely handle undefined Topics
    }
    return 0;
  });


  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCourses = sortedCourses.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedCourses.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  if (loading) {
    return (
      <div className="course-details-container">
        <p className='text text-center'>Loading courses...</p>
      </div>
    );
  }
  else {
    // Schema Markup for Courses
    const generateSchemaData = () => {
      return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://courseecho.com/courses",
        "name": "Course Echo - Learn Programming",
        "description": "Discover top programming and development courses on Course Echo. Learn Java, Angular, Flask, AWS, and more with in-depth tutorials and hands-on projects.",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://courseecho.com/courses"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Course Echo",
          "logo": {
            "@type": "ImageObject",
            "url": "https://courseecho.com/logo.webp"
          }
        },
        "image": "https://courseecho.com/logo.webp",
        "keywords": "Course Echo, programming courses, development tutorials, Java, Angular, Flask, AWS, MySQL, PHP",
        "author": {
          "@type": "Organization",
          "name": "Course Echo"
        },
        "sameAs": [
          "https://www.facebook.com/CourseEcho",
          "https://twitter.com/CourseEcho",
          "https://www.linkedin.com/company/courseecho"
        ],
        "mainEntity": [
          ...filteredCourses.map(course => ({
            "@type": "Course",
            "name": course.title,
            "description": course.description,
            "provider": {
              "@type": "Organization",
              "name": "Course Echo"
            },
            "educationalCredentialAwarded": "Certificate of Completion",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 4,
              "ratingCount": 1234,
              "reviewCount": 450
            },
            "offers": {
              "@type": "Offer",
              "url": `https://courseecho.com/course-details/${course.courseDisplayId}/${course.id}`,
              "priceCurrency": "INR",
              "price": course.fee,
              "eligibleRegion": {
                "@type": "Place",
                "name": "Worldwide"
              },
              "priceValidUntil": "2025-12-31",
              "category": course.categoryDisplayId || "General",  // Add category field
            },
            "hasCourseInstance": {
              "@type": "CourseInstance",
              "courseMode": "Online",  // All courses are online, recorded only
              "location": {
                "@type": "Place",
                "name": "Online"
              },
              "startDate": new Date(),  // Replace with actual course start date
              "courseWorkload": "P1D",  // Indicating it's a self-paced course
            }
          }))
        ],
        "schema.org:additionalType": "https://schema.org/WebSite",
        "schema.org:potentialAction": {
          "@type": "SearchAction",
          "target": `https://courseecho.com/search?query={search_term}`,
          "query-input": "required name=search_term"
        }
      };
    };
    
    return (
      <div className="container courses">
        {!isImported ? (
          <MetaHelmet
            title="Course Echo - Learn Programming"
            description="Discover top programming and development courses on Course Echo. Learn Java, Angular, Flask, AWS, and more with in-depth tutorials and hands-on projects."
            keywords="Course Echo, programming courses, development tutorials, Java, Angular, Flask, AWS, MySQL, PHP"
            author="Course Echo Team"
            ogTitle="Course Echo - Learn Programming"
            ogDescription="Explore programming courses on Course Echo - Comprehensive tutorials in Java, Angular, Flask, AWS, and more."
            ogImage="https://images.courseecho.com/dark-logo.webp"
            ogUrl="https://courseecho.com/courses"
            twitterTitle="Learn programming with Course Echo"
            twitterDescription="Access tutorials and courses on Java, Angular, Flask, AWS, and more!"
            twitterImage="https://images.courseecho.com/dark-logo.webp"
            twitterCreator="@CourseEcho"
            schemaMarkup={generateSchemaData()}
            />
        ) : (
          <></> // Using an empty fragment instead of <br> for cleaner output when not imported
        )}

        <h2 className='header'>{categoryDisplayId ? `Courses in Category ${categoryDisplayId}` : 'Explore Our Courses'}</h2>

        {/* Search Bar and Clear Filter Button */}
        <div className="search-sort-container">
          <input
            type="text"
            placeholder="Search courses..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />

          {/* Clear Search Icon */}
          {searchTerm && (
            <button className="clear-button" onClick={clearFilters}>
              <span className="clear-icon">✖</span>
            </button>
          )}

          {/* Sort Dropdown */}
          <select value={sortOption} aria-label="Sort by" title="Sort by"
            onChange={handleSortChange} className="sort-dropdown">
            <option value="asc">Price: Low to High</option>
            <option value="desc">Price: High to Low</option>
            <option value="relevant">Relevant: Most Topics</option>
          </select>
        </div>

        {/* Items Per Page Dropdown */}
        <div className="items-per-page-container">
          <label htmlFor="items-per-page" className="items-per-page-label">Items per page:</label>
          <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange} className="items-per-page-select">
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        {/* Display Courses */}
        <div className="courses-container">
          <div className="row">
            {currentCourses.map((course) => (
              <div className="col-md-4 col-sm-12 mb-5" key={course.id}>
                <CourseCard course={course} onAddToCart={handleAddToCart} />
              </div>
            ))}
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="pagination">
          {totalPages > 1 && (
            <>
              <button
                className="page-button"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                &laquo; First
              </button>
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt; Prev
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next &gt;
              </button>
              <button
                className="page-button"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last &raquo;
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default Courses;
