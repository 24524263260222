import React, { useState, useEffect } from 'react';
import './SyllabusViewer.scss';

interface SyllabusViewerProps {
    pdfUrl: string;
}

const SyllabusViewer: React.FC<SyllabusViewerProps> = ({ pdfUrl }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the device is mobile
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIfMobile(); // Initial check
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    if (isMobile) {
        return (
            <div className="pdf-viewer">
                {/* Fallback for mobile devices */}
                <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                ></iframe>
                
            </div>
        );
    }

    return (
        <div className="pdf-viewer">
            {/* Desktop viewer */}
            <embed src={pdfUrl} width="100%" height="600px" type="application/pdf" />
        </div>
    );
};

export default SyllabusViewer;
