import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import './Profile.scss'; // Import your custom CSS for the profile
import { ENDPOINT } from '../../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
import UserCourses from '../../components/UserCourses';
import { Link } from 'react-router-dom';
import AuthorEditDetails from '../dashboard/AuthorEditDetails';
import { UserProfileInterface } from '../../data/userData';
import CounselorEditDetails from '../dashboard/CounselorEditDetails';

const Profile: React.FC = () => {
  // Allowed claims to access the dashboard
  const allowedClaims = ['SUPERADMIN', 'MANAGER', 'MENTOR', 'STUDENT'];

  // Get the default claim from localStorage
  const userClaim = localStorage.getItem('defaultClaim');

  // Check if the user's claim is in the allowed list
  const canAccessDashboard = allowedClaims.includes(userClaim || '');

  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const [isCounselor,setIsCounselor] = useState<boolean>(false)
  const [isMentor,setIsMentor] = useState<boolean>(false)
  const [user, setUser] = useState<any>(null); // Store user profile data
  const [userProfile, setUserProfile] = useState<UserProfileInterface>(); // Store user profile data
  const [loading, setLoading] = useState(true); // Loading state
  const [profileLoading, setProfileLoading] = useState(true); // Loading state

  const [error, setError] = useState<string | null>(null); // Error state
  const [activeSection, setActiveSection] = useState<'profile' | 'courses' | 'settings'>('profile'); // Track active section
  const { logout } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const email = localStorage.getItem('email');
        const response = await axios.get(`${ENDPOINT}/users/${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setUser(response.data);
        } else {
          setError('Failed to load profile. Please try again later.');
        }
      } catch (err: any) {
        setError('Error fetching profile data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const fetchCompleteUserProfile = async () => {
      try {
        const email = localStorage.getItem('email');
        const response = await axios.get(`${ENDPOINT}/users/user-profile/${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setUserProfile(response.data);

        } 
      } catch (err: any) {

      } finally
      {
        setProfileLoading(false)
      }
    };
    fetchCompleteUserProfile();
  }, []);

  const handleLogout = () => {
    logout();
    window.location.href = '/login'; // Redirect to login after logout
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container profile-container">
      <Helmet>
        <title>User Profile - {localStorage.getItem('email')} | Course Echo</title>
      </Helmet>

      <div className="row">
        <div className='col-12 col-md-12'>
          <h2>Profile</h2>
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Mobile:</strong> {user.mobile}</p>
          <p><strong>Claims:</strong> {user.defaultClaim}
          {!profileLoading ?
          (
            <span>
          {userClaim !== 'MENTOR' && userProfile?.mentor?.id ? (", MENTOR"):(null)}
          {userClaim !== 'COUNSELOR' && userProfile?.counselor?.id ? (", COUNSELOR"):(null)}
          </span>
          ):(null)
}
          </p>
        </div>
      </div>

      {userClaim === 'MENTOR' || isMentor ? (
        <AuthorEditDetails/>
      )

        : (null)}

{userProfile?.counselor?.id ? (
        <CounselorEditDetails/>
      )

        : (null)}

    </div>
  );
};

export default Profile;