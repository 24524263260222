import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  userClaims: string[];
  token: string | null;
  login: (email: string, token: string, claim: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userClaims, setUserClaims] = useState<string[]>([]);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    // Check if the user is authenticated by reading from localStorage
    const email = localStorage.getItem('email');
    const storedClaims = localStorage.getItem('defaultClaim');
    const storedToken = localStorage.getItem('token');

    if (email && storedClaims && storedToken) {
      setIsAuthenticated(true);
      setUserClaims([storedClaims]);
      setToken(storedToken);
    }
  }, []); // Empty dependency array to run this once on mount

  const login = (email: string, token: string, claim: string) => {
    // Store the user info and claims in localStorage
    localStorage.setItem('email', email);
    localStorage.setItem('token', token);
    localStorage.setItem('defaultClaim', claim);

    // Update the state
    setIsAuthenticated(true);
    setUserClaims([claim]);
    setToken(token);
  };

  const logout = () => {
    // Clear the localStorage
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('defaultClaim');

    // Reset the state
    setIsAuthenticated(false);
    setUserClaims([]);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userClaims, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
