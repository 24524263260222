import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InterviewModel } from '../../data/interviewData';
import './InterviewsList.scss';
import { getInterview } from '../../services/InterviewService';
import MetaHelmet from '../../components/MetaHelmet';
interface InterviewProps {
  isImported: boolean;
}

const InterviewsList: React.FC<InterviewProps> = ({ isImported }) => {
  const [interviews, setInterviews] = useState<InterviewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const response = await getInterview();
        setInterviews(response);
      } catch (err) {
        setError('Error fetching interviews');
      } finally {
        setLoading(false);
      }
    };

    fetchInterviews();
  }, []);

  if (loading) {
    return     <div className="container interviewPage text-center">
      <h1 className="text-center my-4 detail-h1">Unlock Expert Insights with Exclusive Interviews</h1>
<p className="text-center">
  Gain valuable knowledge and industry secrets from top experts in our exclusive interviews.
   Whether you're looking to ace your next interview or stay ahead in the job world,
   our expert-led discussions on demanding skills, and more will help you sharpen your skills and advance your career.
</p>
<br></br>
Loading interviews...</div>;
  }

  if (error) {
    return     <div className="container interviewPage">
      <h1 className="text-center my-4 detail-h1">Unlock Expert Insights with Exclusive Interviews</h1>
<p className="text-center">
  Gain valuable knowledge and industry secrets from top experts in our exclusive interviews.
   Whether you're looking to ace your next interview or stay ahead in the job world,
   our expert-led discussions on demanding skills, and more will help you sharpen your skills and advance your career.
</p>
{error}</div>;
  }
  const generateSchemaData = () => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Interviews - Course Echo",
    "url": `https://courseecho.com/interviews`,
    "description": "Browse top programming and development interviews on Course Echo. Discover valuable insights, tips, and best practices.",
    "author": {
      "@type": "Organization",
      "name": "Course Echo",
      "url": "https://courseecho.com"
    },
    "mainEntity": [
      ...interviews.map((interview) => ({
        "@type": "Interview",
        "name": interview.title,
        "description": interview.description,
        "provider": {
          "@type": "Organization",
          "name": "Course Echo",
          "url": "https://courseecho.com"
        },
        "offers": {
          "@type": "Offer",
          "url": `https://courseecho.com/interview-details/${interview.interviewDisplayId}/${interview.id}`,
          "priceCurrency": "INR",
          "price": 0,
          "eligibleRegion": {
            "@type": "Place",
            "name": "Worldwide"
          },
          "priceValidUntil": "2025-12-31",
          "category": "Interview",  // Added 'category' field for classification
        },
        "hasCourseInstance": {
          "@type": "CourseInstance",
          "courseMode": "Online",
          "location": {
            "@type": "Place",
            "name": "Online"
          },
          "startDate": "2024-12-26"  // Replace with actual interview date if available
        }
      }))
    ],
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://courseecho.com/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  });
  const pageTitle = "Discover Top Interviews - Course Echo";
  const pageDescription =
    "Browse top programming and development interviews on Course Echo. Explore detailed interviews with industry experts on topics like Java, Angular, Flask, AWS, and more.";
  const keywords =
    "programming interviews, developer interviews, Course Echo, Java, Angular, Flask, AWS, interview preparation";

  return (

    <div className="container interviewPage">
      {!isImported ? (
        <MetaHelmet
          title={pageTitle}
          description={pageDescription}
          keywords={keywords}
          author="Course Echo Team"
          ogTitle={pageTitle}
          ogDescription={pageDescription}
          ogImage="https://images.courseecho.com/dark-logo.webp"
          ogUrl="https://courseecho.com/interviews"
          twitterTitle={pageTitle}
          twitterDescription={pageDescription}
          twitterImage="https://images.courseecho.com/dark-logo.webp"
          twitterCreator="@CourseEcho"
          schemaMarkup={generateSchemaData()}
        />
      ) : (
        <></> // Using an empty fragment instead of <br> for cleaner output when not imported
      )}
<h1 className="text-center my-4 detail-h1">Unlock Expert Insights with Exclusive Interviews</h1>
<p className="text-center">
  Gain valuable knowledge and industry secrets from top experts in our exclusive interviews.
   Whether you're looking to ace your next interview or stay ahead in the job world,
   our expert-led discussions on demanding skills, and more will help you sharpen your skills and advance your career.
</p>

<div className="row mt-5">
        {interviews.map((interview) => (
          <div key={interview.id} className="col-12 col-md-3 col-lg-3 mb-4 ">
            <Link to={`/interview-details/${interview.interviewDisplayId}/${interview.id}`} className='NormalLink'>
              <div
                className="interview-card shadow-sm"
                style={{ cursor: 'pointer' }}
              >

                {/* <img
                  src={interview.image}
                  alt={interview.title}
                  className="img-fluid rounded interview-img"
                  height={200}
                  width={200}
                /> */}

                <h5 className="mt-1 mb-3 text-center ">{interview.title}</h5>
                <div className='mt-3 p-1 interview-description' dangerouslySetInnerHTML={{ __html: interview?.description || '' }} />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterviewsList;
