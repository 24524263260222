import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { InterviewModel, InterviewQuestionModel } from '../../data/interviewData';
import { addInterviewQ, getDashboardInterviewById, updateInterviewQ } from '../../services/DashboardService';

const AddEditInterviewQuestionPage: React.FC = () => {
    const { interview_id, id } = useParams<{ interview_id: string, id: string }>();
    const [InterviewQuestionModel, setInterviewQuestionModel] = useState<InterviewQuestionModel>({
        id: '',
        questionDisplayId:'',
        question_text: '',
        answer_text: '',
        interview_id: interview_id ? parseInt(interview_id) : 0
    });
    const [interviewModel, setInterviewModel] = useState<InterviewModel>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (interview_id) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getDashboardInterviewById(interview_id)
                .then((course) => {
                    setInterviewModel(course[0]);
                    const foundItem = course[0]?.interview_questions?.find((v: InterviewQuestionModel) => v.id === id);
                    if (foundItem) setInterviewQuestionModel(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [interview_id, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setInterviewQuestionModel((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (interviewQuestionModel: InterviewQuestionModel): string[] => {
        const errors: string[] = [];
        if (!interviewQuestionModel.question_text) errors.push('Question is required.');
        if (!interviewQuestionModel.answer_text) errors.push('Answer is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(InterviewQuestionModel);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateInterviewQ(InterviewQuestionModel.id || '', InterviewQuestionModel);
            } else {
                await addInterviewQ(InterviewQuestionModel);
            }
            navigate(`/dashboard/interview-questions/${interview_id}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleQEditorChange = (value: string) => {
        setInterviewQuestionModel((prev) => ({ ...prev, question_text: value }));
    };
    const handleAEditorChange = (value: string) => {
        setInterviewQuestionModel((prev) => ({ ...prev, answer_text: value }));
    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit course resource' : 'Add New course resource'} - Dashboard`}
                description="Dashboard to add/edit course resource"
                keywords="dashboard, course resource management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/interviews`}>Interview: {interviewModel?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/interview-questions/${interview_id}`}>Questions ({interviewModel?.interview_questions?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            
            <Form>
                <Row>
                    <Col md={6} xs={12}>
                    <Form.Group controlId="formquestion_tag">
                            <Form.Label>Question Display Tag : (ex - question-no-1)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter display tag"
                                name="question_display_tag"
                                value={InterviewQuestionModel.questionDisplayId}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formquestion_text">
                            <Form.Label>Question
                            </Form.Label>
                            <ReactQuill
                                value={InterviewQuestionModel.question_text}
                                onChange={handleQEditorChange}
                                placeholder="Write..."
                            />
                        </Form.Group>

                         <Form.Group controlId="formquestion_text">
                                                    <Form.Label>Question</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter question"
                                                        name="question_text"
                                                        value={InterviewQuestionModel.question_text}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>


                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formanswer_text">
                            <Form.Label>Answer
                            </Form.Label>
                            <ReactQuill
                                value={InterviewQuestionModel.answer_text}
                                onChange={handleAEditorChange}
                                placeholder="Write..."
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditInterviewQuestionPage;
