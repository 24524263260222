import React from "react";
import { Topics } from "../data/coursesData";
import './TopicProgressComponent.scss';

interface Props {
  Topics: Topics;
  selectedTopics: Topics | null;
  played: number;
}

const TopicProgressComponent: React.FC<Props> = ({ Topics, selectedTopics, played }) => {
  // Calculate progress value
  const getProgressValue = (): number => {
    if (Topics.TopicProgress?.statusData?.includes("99") || Topics.TopicProgress?.status === "completed") {
      return 100;
    } else if (Topics.TopicProgress?.statusData) {
      const progressData = parseInt(Topics.TopicProgress.statusData ?? "0");
      return progressData > Math.ceil(played) ? progressData : Math.ceil(played);
    } else if (selectedTopics?.id === Topics.id && played > 0) {
      return Math.ceil(played);
    }
    return 0;
  };

  const progressValue = getProgressValue();
  const isCompleted = progressValue === 100 && played === 0;

  // Determine the class based on progress value
  const getProgressClass = (): string => {
    if (progressValue <= 30) {
      return "bg-info";
    } else if (progressValue <= 60) {
      return "bg-primary";
    } else if (progressValue <= 90) {
      return "bg-warning";
    } else {
      return "bg-success";
    }
  };

  const progressClass = getProgressClass();

  return (
    <div className="progress-container">
      {/* Progress bar */}
      <div
        className="progress d-flex customd-flex  align-items-center justify-content-between proG"
        title={`Completion status: ${progressValue}%`}
      >
        <div
          className={`progress-bar ${progressClass}`}
          role="progressbar"
          style={{ width: `${progressValue}%` }}
          aria-valuenow={progressValue}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {progressValue > 0 && `${progressValue}%`}
        </div>
        &nbsp;
        {isCompleted ? (
           <span className="progress-spn">
          <i className="fa fa-check-square-o text-success" title="This topic completed 100%"></i>
          </span>
        ) : (
          <span className="text-success progress-spn">{progressValue} % completed</span>
        )}
      </div>
    </div>
  );
};

export default TopicProgressComponent;
