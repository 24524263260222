import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Author } from '../../data/authorData';
import { getAuthors, getAuthorsById } from '../../services/AuthorsService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addAuthor, editAuthorsById, editAuthorsByToken, getAuthorsByToken, getDashboardAuthors, getDashboardUsers, uploadAuthorImage } from '../../services/DashboardService';
import { UserModel } from '../profile/model/User.model';
import { UserInterface } from '../../data/userData';

const AddEditMentorPage: React.FC = () => {
    const [author, setAuthor] = useState<Author>({
        id: '',
        name: '',
        description: '',
        image: '',
        keywords: '',
        userId: ''
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<Author[]>([]);
    const [users, setUsers] = useState<UserInterface[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { id } = useParams<{ id: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection


    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const authorsResponse = await getDashboardAuthors();
                setAuthors(authorsResponse);
                const userData = await getDashboardUsers("STUDENT");
                setUsers(userData);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

        if (id) {
            setIsEditing(true);
            setLoading(true);
            setIsUrlSelected(true);
            if (localStorage.getItem("defaultClaim") !== "MENTOR") {
                getAuthorsById(id)
                    .then((author) => {
                        setAuthor(author[0]);
                    })
                    .catch((error) => {
                        setErrorMessage('Error fetching author data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
            else {
                getAuthorsByToken()
                    .then((author) => {
                        setAuthor(author[0]);
                    })
                    .catch((error) => {
                        setErrorMessage('Error fetching author data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setAuthor((prevCourse) => ({ ...prevCourse, [name]: value }));
    };

    const handleEditorChange = (value: string) => {
        setAuthor((prevCourse) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setAuthor((prevCourse) => ({ ...prevCourse, description: value }));
    };

    const handleSaveAuthor = async () => {
        const validateAuthor = (author: Author): string[] => {
            const errors: string[] = [];
            if (!author.name) errors.push('name is required.');
            if (!author.description) errors.push('Description is required.');
            if (!author.image && !imageFile) errors.push('Image is required.');

            if (!author.keywords || author.keywords.length === 0) {
                errors.push('SEO Keywords are required.');
            }
            return errors;
        };

        // Perform Validation
        const errors = validateAuthor(author);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            // Handle image upload if a file is selected
            if (imageFile) {
                const imageUrl = await uploadAuthorImage(imageFile);
                author.image = imageUrl; // Set the image URL after upload
            }


            if (isEditing) {
                if (localStorage.getItem("defaultClaim") === "MENTOR") {
                    await editAuthorsByToken(author); // Call the update service
                }
                else {
                    await editAuthorsById(author); // Call the update service

                }
            } else {
                const data = { ...author }
                await addAuthor(data); // Call the add service
            }
            if(localStorage.getItem("defaultClaim") !== "MENTOR")
            {
            navigate('/dashboard/authors');
        }
        else{
            navigate('/profile')
        }
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving the author.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setAuthor((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handleStatusChange = (status: string) => {
        setAuthor((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit Author' : 'Add New Author'} - ${localStorage.getItem('email')} - Author Echo`}
                description="Dashboard to add/edit authors"
                keywords="author echo dashboard,Dashboard to add/edit authors"
                author="Author Echo"
            />
            {localStorage.getItem("defaultClaim") !== "MENTOR"?(
            <Breadcrumb className='p-1'>
                <Breadcrumb.Item>
                    <Link to="/"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard"> Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard/mentors"> Authors</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{isEditing ? 'Edit Author' : 'Add Author'}</Breadcrumb.Item>
            </Breadcrumb>):(null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Author Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter author name"
                                name="title"
                                value={author.name}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('name is required')}
                            />
                        </Form.Group>



                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={author.description}
                                onChange={handleDescriptionEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>


                        <Form.Group controlId="formImage">
                            <Form.Label>Author Image</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Image URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setImageFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {author.image && (
                                    <div className="uploaded-image-preview">
                                        <img src={author.image} alt="Preview" />
                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={author.image}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('Image is required')}
                                    disabled={!isUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload Image"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setAuthor((prevCourse) => ({ ...prevCourse, image: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {imageFile && (
                                    <div className="uploaded-image-preview">
                                        <img src={URL.createObjectURL(imageFile)} alt="Preview" />
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageFileChange}
                                    disabled={isUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>

                    </Col>

                   

                    <Col md={3} xs={12} className='mb-5'>
                       

                        {/* Author Selection */}
                        {localStorage.getItem("defaultClaim") !== "MENTOR"?(

                        <Form.Group controlId="formAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                as="select"
                                name="authorId"
                                value={author.id}
                                onChange={handleChange}
                            >
                                <option value="">Select Author</option>
                                {authors.map((author) => (
                                    <option key={author.id} value={author.id}>
                                        {author.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        ):(null)}

                          {/* Author Selection */}
                          {localStorage.getItem("defaultClaim") !== "MENTOR"?(

                          <Form.Group controlId="formUser">
                            <Form.Label>Mapped User</Form.Label>
                            <Form.Control
                                as="select"
                                name="userId"
                                value={author.userId}
                                onChange={handleChange}
                            >
                                <option value="">Select User</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                          ):(null)}
                        <Form.Group controlId="formKeywords">
                            <Form.Label>SEO Keywords</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter SEO keywords"
                                name="keywords"
                                value={author.keywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveAuthor} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditMentorPage;
