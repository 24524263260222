import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CourseBulletPoints } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourseBulletPoint, addTopic, updateCourseBulletPoint, updateTopic, uploadVideo } from '../../services/DashboardService';
import ReactPlayer from 'react-player';
import { title } from 'process';

const AddEditBulletPointPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [courseBulletPoints, setCourseBulletPoints] = useState<CourseBulletPoints>({
        id: '',
        title: '',
        courseId: courseid ? parseInt(courseid) : 0
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.CourseBulletPoints?.find((v: CourseBulletPoints) => v.id === id);
                    if (foundItem) setCourseBulletPoints(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCourseBulletPoints((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (courseBulletPoints: CourseBulletPoints): string[] => {
        const errors: string[] = [];
        if (!courseBulletPoints.title) errors.push('Title is required.');
        if (!courseBulletPoints.icon) errors.push('Icon is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(courseBulletPoints);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCourseBulletPoint(courseBulletPoints.id || '', courseBulletPoints);
            } else {
                await addCourseBulletPoint(courseBulletPoints);
            }
            navigate(`/dashboard/bullet-points/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateChange = (title: string, icon: string) => {
        setCourseBulletPoints((prev) => ({ ...prev, title: title, icon: icon }));

    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit course bullet point' : 'Add New course bullet point'} - Dashboard`}
                description="Dashboard to add/edit course bullet point"
                keywords="dashboard, course bullet point management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/bullet-points/${courseid}`}>Bullet Points ({courseItem?.CourseBulletPoints?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit Bullet Point' : 'Add Bullet Point'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            <Row>

                <Col md={12} xs={12}>
                    <h5>Use Templates: </h5>
                    <div className="common-card-container">
                        {[{ title: 'In depth High-Quality videos', icon: 'fa fa-video-camera' },
                        { title: 'Hands-on experience', icon: 'fa fa-hand-rock-o' },
                        { title: 'Deep dive into the architecture', icon: 'fa fa-cogs' },
                        { title: 'Building Real world Projects from scratch', icon: 'fa fa-laptop' },
                        { title: 'Premium community of Developers', icon: 'fa fa-users' },
                        { title: 'All future course updates', icon: 'fa fa-refresh' },
                        { title: 'Latest course content', icon: 'fa fa-newspaper-o' },
                        { title: 'Interviews concepts explained', icon: 'fa fa-user-md' },
                        { title: '10+ hours of video content', icon: 'fa fa-clock-o' },
                        { title: 'Course Certificate', icon: 'fa fa-certificate' },
                        { title: 'Two year access to all course content', icon: 'fa fa-calendar-check-o' },


                        ].map((item: any, index: number) => (
                            <div
                                key={index}
                                className={`source-common common-card ${item.title === courseBulletPoints.title ? 'selected' : ''}`}
                                onClick={() => handleTemplateChange(item.title, item.icon)}
                            >
                                <i className={item.icon}></i>   {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            <hr></hr>
            <Form>
                <Row>
                    <h6>Add Custom:</h6>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={courseBulletPoints.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>

                    <Col md={6} xs={12}>
                        <Form.Group controlId="formIcon">
                            <Form.Label>Icon Class : {courseBulletPoints.icon ? (
                                    <i className={courseBulletPoints.icon}></i>
                                ) : (null)}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter icon class name ex: fa fa-video"
                                name="icon"
                                value={courseBulletPoints.icon}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditBulletPointPage;
