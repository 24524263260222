import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Table, Breadcrumb, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'; // Import Link
import {  getDashboardInterviewById } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { InterviewModel, InterviewQuestionModel } from '../../data/interviewData';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const InterviewQuestionPage: React.FC = () => {
  const [Interviews, setCourse] = useState<InterviewModel>();
  const [InterviewQuestionModel, setInterviewQuestionModel] = useState<InterviewQuestionModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardInterviewById(id || '');
        setCourse(response[0]);
        setInterviewQuestionModel(response[0].interview_questions || [])
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: string) => {

  };


  const filteredData = InterviewQuestionModel.filter((request) => {
    return (
      request.question_text.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item>
            <Link to="/dashboard/interviews"> Interviews : {Interviews?.title}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Questions : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>

        <div className="mb-3">
          <Link to={`/dashboard/add-interview-question/${Interviews?.id}`} className="btn btn-primary text-success">
            Add New
          </Link>
        </div>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>
                <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.question_text || '' }} />

                </td>
                <td>
                <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.answer_text || '' }} />

                </td>
                <td>{request.created_at}</td>
                <td>{request.updated_at}</td>
                <td>
                  <Link
                    className="me-2 btn btn-warning navBtn"
                    to={`/dashboard/edit-interview-question/${request.interview_id}/${request.id}`} // Pass the course ID for editing
                  >
                    Edit
                  </Link>
                  {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(request.id || '')}
                      className="me-2 navBtn"
                    >
                      Delete
                    </Button>
                  ) : (null)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </div>
  );
};

export default InterviewQuestionPage;
