import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CourseFor } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourseBulletPoint, addCourseFor, addTopic, updateCourseBulletPoint, updateCourseFor } from '../../services/DashboardService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditCourseForPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [courseFor, setCourseFor] = useState<CourseFor>({
        id: '',
        title: '',
        description: '',
        courseId: courseid ? parseInt(courseid) : 0
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.CourseFor?.find((v: CourseFor) => v.id === id);
                    if (foundItem) setCourseFor(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCourseFor((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (courseFor: CourseFor): string[] => {
        const errors: string[] = [];
        if (!courseFor.title) errors.push('Title is required.');
        if (!courseFor.description) errors.push('Description is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(courseFor);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCourseFor(courseFor.id || '', courseFor);
            } else {
                await addCourseFor(courseFor);
            }
            navigate(`/dashboard/course-for/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateChange = (title: string, description: string) => {
        setCourseFor((prev) => ({ ...prev, title: title, description: description }));

    };
    const handleEditorChange = (value: string) => {
        setCourseFor((prev) => ({ ...prev, description: value }));
    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit course for' : 'Add New course for'} - Dashboard`}
                description="Dashboard to add/edit course for"
                keywords="dashboard, course for management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/course-for/${courseid}`}>Course For ({courseItem?.CourseFor?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            <Row>

                <Col md={12} xs={12}>
                    <h5 className='mb-4'>Use Templates: </h5>
                    <div className='row'>

                        {[
                            {
                                title: 'Beginners and Students',
                                description: `Are you just starting out or exploring the world of <strong>${courseItem?.title}</strong>? 
        This course is designed to help you grasp the fundamentals and build a strong foundation in <strong>${courseItem?.title}</strong>. 
        Whether you're studying <strong>${courseItem?.title}</strong> in school, preparing for your career, or simply curious, this is the perfect place to begin. Start your journey today and unlock your potential! 🌟`
                            },
                            {
                                title: 'Early Professionals',
                                description: `Have you got some initial experience with <strong>${courseItem?.title}</strong>? 
        Take this opportunity to deepen your knowledge and enhance your skills. 
        This course provides practical insights and a deeper understanding of the principles and applications of <strong>${courseItem?.title}</strong>, whether in the workplace or beyond. 
        Elevate your expertise and set yourself apart. 🚀`
                            },
                            {
                                title: 'Experienced Professionals',
                                description: `Even as an experienced professional in <strong>${courseItem?.title}</strong>, there’s always more to learn! 
        Revisit essential concepts, explore advanced topics, and refine your expertise. 
        This course is perfect for bridging knowledge gaps, understanding advanced use cases, and staying ahead in your field. Continue to grow and lead with confidence. ✨`
                            }
                        ]
                            .map((item: any, index: number) => (

                                <div className={`job-posting col-md-4 col-sm-12 mb-3 ${item.title === courseFor.title ? 'selected' : ''}`} 
                                onClick={() => handleTemplateChange(item.title, item.description)}
                                >
                                    <h2 className='border-bottom text-center'>{item.title}</h2>
                                    {/* <p>{item.description}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: item?.description || '' }} />

                                </div>

                            ))}
                    </div>
                </Col>
            </Row>
            <hr></hr>
            <Form>
                <Row>
                    <h6 className='mt-2'>Add Custom:</h6>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={courseFor.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>

                    <Col md={6} xs={12}>
                        <Form.Group controlId="formIcon">
                            <Form.Label>Description
                            </Form.Label>
                            <ReactQuill
                                value={courseFor.description}
                                onChange={handleEditorChange}
                                placeholder="Write the course objectives..."
                            />
                        </Form.Group>

                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditCourseForPage;
