import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { UserModel } from '../profile/model/User.model';
import { UserInterface } from '../../data/userData';
import { Author, SocialLink } from '../../data/authorData';
import { addAuthorSocialLink, getAuthorsByToken, getDashboardAuthors, updateAuthorSocialLink, uploadSocialImage } from '../../services/DashboardService';
import { getAuthorsById } from '../../services/AuthorsService';

const AddEditMentorSocialPage: React.FC = () => {
    

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<Author[]>([]);
    const [users, setUsers] = useState<UserInterface[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { id, social_id } = useParams<{ id: string, social_id: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection
    const [socialLink, setSocialLink] = useState<SocialLink>({
        id: '',
        title: '',
        description: '',
        image: '',
        link: '',
        authorId: id,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const socialLinksResponse = await getDashboardAuthors();
                setAuthors(socialLinksResponse);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

        if (id) {
            setIsEditing(false)
            setLoading(true);
            setIsUrlSelected(true);
            if (localStorage.getItem("defaultClaim") !== "MENTOR") {
                getAuthorsById(id)
                    .then((author: Author[]) => {
                        if (social_id) {
                            const socialData = author[0]?.SocialLink?.find((v: SocialLink) => v.id === social_id);
                            if (socialData) {
                                setSocialLink(socialData)
                                setIsEditing(true);

                            }
                        }
                    })
                    .catch((error: any) => {
                        setErrorMessage('Error fetching social Link data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
            else {
                getAuthorsByToken()
                    .then((author: Author[]) => {
                        if (social_id) {
                            const socialData = author[0]?.SocialLink?.find((v: SocialLink) => v.id === social_id);
                            if (socialData) {
                                setSocialLink(socialData)
                                setIsEditing(true);

                            }
                        }
                    })
                    .catch((error: any) => {
                        setErrorMessage('Error fetching social Link data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setSocialLink((prevCourse: SocialLink) => ({ ...prevCourse, [name]: value }));
    };

    const handleEditorChange = (value: string) => {
        setSocialLink((prevCourse: SocialLink) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setSocialLink((prevCourse: SocialLink) => ({ ...prevCourse, description: value }));
    };

    const handleSaveSocialLink = async () => {
        const validateSocialLink = (socialLink: SocialLink): string[] => {
            const errors: string[] = [];
            if (!socialLink.title) errors.push('title is required.');
            if (!socialLink.description) errors.push('Description is required.');
            if (!socialLink.link) errors.push('Link is required.');
            if (!socialLink.image && !imageFile) errors.push('Image is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateSocialLink(socialLink);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            // Handle image upload if a file is selected
            if (imageFile) {
                const imageUrl = await uploadSocialImage(imageFile);
                socialLink.image = imageUrl; // Set the image URL after upload
            }


            if (isEditing) {
                await updateAuthorSocialLink(socialLink); // Call the update service

            } else {
                const data = { ...socialLink }
                await addAuthorSocialLink(data); // Call the add service
            }
            if (localStorage.getItem("defaultClaim") !== "MENTOR") {
                navigate('/dashboard/authors');
            }
            else {
                navigate('/profile')
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving the socialLink.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setSocialLink((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handleStatusChange = (status: string) => {
        setSocialLink((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit SocialLink' : 'Add New SocialLink'} - ${localStorage.getItem('email')} - SocialLink Echo`}
                description="Dashboard to add/edit socialLinks"
                keywords="socialLink echo dashboard,Dashboard to add/edit socialLinks"
                author="SocialLink Echo"
            />
            {localStorage.getItem("defaultClaim") !== "MENTOR" ? (
                <Breadcrumb className='p-1'>
                    <Breadcrumb.Item>
                        <Link to="/"> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard"> Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard/mentors"> SocialLinks</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit SocialLink' : 'Add SocialLink'}</Breadcrumb.Item>
                </Breadcrumb>) : (null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Social Link title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={socialLink.title}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('title is required')}
                            />
                        </Form.Group>



                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={socialLink.description}
                                onChange={handleDescriptionEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>
                        <Form.Group controlId="formLink">
                            <Form.Label>Social Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter link"
                                name="link"
                                value={socialLink.link}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('link is required')}
                            />
                        </Form.Group>



                    </Col>
                    <Col md={7} xs={12} className='mb-5'>
                        <Form.Group controlId="formImage">
                            <Form.Label>Social Link Image</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Image URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setImageFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {socialLink.image && (
                                    <div className="uploaded-image-preview">
                                        <img src={socialLink.image} alt="Preview" />
                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={socialLink.image}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('Image is required')}
                                    disabled={!isUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload Image"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setSocialLink((prevCourse) => ({ ...prevCourse, image: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {imageFile && (
                                    <div className="uploaded-image-preview">
                                        <img src={URL.createObjectURL(imageFile)} alt="Preview" />
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageFileChange}
                                    disabled={isUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveSocialLink} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditMentorSocialPage;
