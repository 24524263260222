import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import './Navbar.scss';
import { Course } from '../data/coursesData';
import { searchService } from '../services/SearchService';
import { CourseCategory } from '../data/categoryData';
import { Tutorial } from '../data/tutorialsData';
import { InterviewModel } from '../data/interviewData';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartItems } = useCart();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Use navigate hook
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<{
    courses: any[];
    categories: any[];
    tutorials: any[];
    interviews: any[];
  }>({ courses: [], categories: [], tutorials: [], interviews: [] });





  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference for timeout
  // Function to handle Enter key press
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      e.preventDefault();
      closeSearchResults();
      // Navigate to the search results page with the query in the URL
      navigate(`/search?query=${searchQuery}`);
    }
  };
  const handleSearch = async (query: string) => {
    if (!query) return; // If query is empty, return early
    setLoading(true); // Show loading spinner
    try {
      const results = await searchService.searchAll(query); // Call the search service
      setSearchResults(results);
    } catch (error) {
      console.error('Error during search:', error);
    }
    finally {
      setLoading(false); // Hide loading spinner once results are fetched
    }
  };
  // Use effect to debounce the API call
  useEffect(() => {
    // Clear the previous timeout if there's one
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Only call the API after user stops typing for 500ms
    if (searchQuery) {
      debounceTimeoutRef.current = setTimeout(() => {
        handleSearch(searchQuery); // Call the API after debounce
      }, 500); // Adjust debounce delay here (500ms)
    }

    return () => {
      // Cleanup the timeout when the component unmounts or searchQuery changes
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]); // Re-run the effect when searchQuery changes


  const closeSearchResults = () => {
    setSearchResults({ courses: [], categories: [], tutorials: [], interviews: [] });
    setSearchQuery('');
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const logoSrc = localStorage.getItem('theme') === 'light' ? 'https://images.courseecho.com/light-logo.webp' : 'https://images.courseecho.com/dark-logo.webp';

  return (
    <>

      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={logoSrc}
              height={60}
              width={60}
              alt="Course Echo Logo"
              className="navbar-logo"
            />
            <div className="logo-text">
              <span className="logo-title">Course Echo</span>
              <span className="logo-slogan">Echoing Knowledge, Empowering Minds</span>
            </div>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Search Bar */}
          <form className="search-form">
            <input
              type="text"
              className="nav-search-input"
              placeholder="Search Anything"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </form>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/courses" onClick={() => setIsOpen(false)}>
                  Courses
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tutorials" onClick={() => setIsOpen(false)}>
                  Tutorials
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="resourcesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                </Link>
                <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/resources/roadmap"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Roadmap <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/interviews"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Interviews <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="companyDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </Link>
                <ul className="dropdown-menu" aria-labelledby="companyDropdown">
                  <li>
                    <Link className="dropdown-item" to="/about" onClick={() => setIsOpen(false)}>
                      About <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/contact" onClick={() => setIsOpen(false)}>
                      Contact <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/career" onClick={() => setIsOpen(false)}>
                      Career <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/mentors" onClick={() => setIsOpen(false)}>
                      Mentors <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li className="dropdown-submenu">
                    <Link
                      className="dropdown-item dropdown-toggle"
                      to="#"
                      id="servicesDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/services/online-class"
                          onClick={() => setIsOpen(false)}
                        >
                          Online Class <i className="rightIcon fa fa-chevron-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/services/offline-class"
                          onClick={() => setIsOpen(false)}
                        >
                          Offline Class <i className="rightIcon fa fa-chevron-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/services/project"
                          onClick={() => setIsOpen(false)}
                        >
                          Project <i className="rightIcon fa fa-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link" to="/campus-counselor" onClick={() => setIsOpen(false)}>
                  CampusCounselor
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link cartWidth" to="/cart" onClick={() => setIsOpen(false)}>
                  <i className="cart-icon fa fa-shopping-cart"> ({cartItems}) </i>
                </Link>
              </li>
              {isAuthenticated ? (
                null
                // <li className="nav-item">
                //   <Link className="nav-link" to="/profile" onClick={() => setIsOpen(false)}>
                //     Profile
                //   </Link>
                // </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link navBtn" to="/login" onClick={() => setIsOpen(false)}>
                      Login/Register
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link navBtn" to="/signup" onClick={() => setIsOpen(false)}>
                      <i className="fa fa-user-plus"></i> Signup
                    </Link>
                  </li> */}
                </>
              )}
            </ul>
          </div>


        </div>
      </nav>
      {/* Display Search Results with Loading Spinner */}
      {searchQuery && (
        <div className="search-results">
          {loading ? (
            <div className="loading-spinner">Loading...</div> // Display loading spinner when loading
          ) : (
            <>
              <button className="close-btn" onClick={closeSearchResults}>
                ×
              </button>
              <h5><span className='highlight'>Search Results</span>:</h5>
              <div className="result-section">
                <h6>Courses <sup>({searchResults.courses.length})</sup></h6>
                <ul>
                  {searchResults.courses.map((course: Course) => (
                    <li key={course.id} onClick={closeSearchResults}>
                      <Link to={`/course-details/${course.categoryDisplayId}/${course.id}`}
                      >
                        {course.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="result-section">
                <h6>Categories <sup>({searchResults.categories.length})</sup></h6>
                <ul>
                  {searchResults.categories.map((category: CourseCategory) => (
                    <li key={category.id} onClick={closeSearchResults}>
                      <Link to={`/courses?course-category=${category.categoryDisplayId}`}
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="result-section">
                <h6>Tutorials <sup>({searchResults.tutorials.length})</sup></h6>
                <ul>
                  {searchResults.tutorials.map((tutorial: Tutorial) => (
                    <li key={tutorial.id} onClick={closeSearchResults}>
                      <Link to={`/tutorials-details/${tutorial.courseId}`}
                      >
                        {tutorial.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="result-section">
                <h6>Interviews <sup>({searchResults.interviews.length})</sup></h6>
                <ul>
                  {searchResults.interviews.map((interview: InterviewModel) => (
                    <li key={interview.id} onClick={closeSearchResults}>
                      <Link to={`/interview-details/${interview.interviewDisplayId}/${interview.id}`}
                      >
                        {interview.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      )}

    </>
  );
};

export default Navbar;
