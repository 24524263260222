import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Author } from '../../data/authorData';
import { getAuthors } from '../../services/AuthorsService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addInterview, getDashboardAuthors, getDashboardInterviewById, updateInterview, uploadInterviewImage } from '../../services/DashboardService';
import { InterviewModel } from '../../data/interviewData';

const AddEditInterviewPage: React.FC = () => {
    const [interview, setInterview] = useState<InterviewModel>({
        id: '',
        title: '',
        description: '',
        image: '',
        interviewDisplayId: '',
        conclusion: '',
        authorId: '',
        seoKeywords: '',
        status:'Pending'
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<Author[]>([]);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { interviewDisplayid, interview_id } = useParams<{ interviewDisplayid: string; interview_id: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection


    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const authorsResponse = await getDashboardAuthors();
                setAuthors(authorsResponse);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

        if (interview_id) {
            setIsEditing(true);
            setLoading(true);
            setIsUrlSelected(true);
            getDashboardInterviewById(interview_id)
                .then((interview) => {
                    setInterview(interview[0]);
                })
                .catch((error) => {
                    setErrorMessage('Error fetching interview data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [interview_id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setInterview((prevCourse) => ({ ...prevCourse, [name]: value }));
    };

    const handleEditorChange = (value: string) => {
        setInterview((prevCourse) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setInterview((prevCourse) => ({ ...prevCourse, description: value }));
    };

    const handleSaveInterview = async () => {
        const validateInterview = (interview: InterviewModel): string[] => {
            const errors: string[] = [];
            if (!interview.title) errors.push('Title is required.');
            if (!interview.interviewDisplayId) errors.push('Display Tag is required.');
            if (!interview.description) errors.push('Description is required.');
            if (!interview.image && !imageFile) errors.push('Image is required.');
            if (!interview.conclusion || interview.conclusion.trim() === '') {
                errors.push('Conclusion is required and cannot be empty.');
            }
            if (!interview.seoKeywords || interview.seoKeywords.length === 0) {
                errors.push('SEO Keywords are required.');
            }
            if (!interview.authorId) errors.push('Author is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateInterview(interview);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            // Handle image upload if a file is selected
            if (imageFile) {
                const imageUrl = await uploadInterviewImage(imageFile);
                interview.image = imageUrl; // Set the image URL after upload
            }


            if (isEditing) {
                await updateInterview(interview.id.toString(), interview); // Call the update service
            } else {
                const data={...interview,interviewDisplayid:interview.interviewDisplayId?.trim().replace(/\s+/g, '-')}
                await addInterview(data); // Call the add service
            }
            navigate('/dashboard/interviews');
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving the interview.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setInterview((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handleStatusChange = (status: string) => {
        setInterview((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit Interview' : 'Add New Interview'} - ${localStorage.getItem('email')} - Interview Echo`}
                description="Dashboard to add/edit interviews"
                keywords="interview echo dashboard,Dashboard to add/edit interviews"
                author="Interview Echo"
            />

            <Breadcrumb className='p-1'>
                <Breadcrumb.Item>
                    <Link to="/"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard"> Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard/interviews"> Interviews</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{isEditing ? 'Edit Interview' : 'Add Interview'}</Breadcrumb.Item>
            </Breadcrumb>
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Interview Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter interview title"
                                name="title"
                                value={interview.title}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Title is required')}
                            />
                        </Form.Group>

                       

                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={interview.description}
                                onChange={handleDescriptionEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>


                        <Form.Group controlId="formImage">
                            <Form.Label>Interview Image</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Image URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setImageFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {interview.image && (
                                    <div className="uploaded-image-preview">
                                        <img src={interview.image} alt="Preview" />
                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={interview.image}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('Image is required')}
                                    disabled={!isUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload Image"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setInterview((prevCourse) => ({ ...prevCourse, image: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {imageFile && (
                                    <div className="uploaded-image-preview">
                                        <img src={URL.createObjectURL(imageFile)} alt="Preview" />
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageFileChange}
                                    disabled={isUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>

                    </Col>

                    <Col md={4} xs={12} className='mb-5'>


                    {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                        <Form.Group controlId="formSource">
                            <Form.Label>Interview Status</Form.Label>
                            <div className="source-card-container">
                                {['Approved', 'Pending','Rejected'].map((status:string) => (
                                    <div
                                        key={status}
                                        className={`source-${status.toLowerCase()} source-card ${interview.status === status ? 'selected' : ''}`}
                                        onClick={() => handleStatusChange(status)}
                                    >
                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                    ):(null)}
                        
                        <Form.Group controlId="formObjective">
                            <Form.Label>Interview Conculsion</Form.Label>
                            <ReactQuill
                                value={interview.conclusion}
                                onChange={handleEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>
                    </Col>

                    <Col md={3} xs={12} className='mb-5'>
                    <Form.Group controlId="formCourseDisplayId">
                            <Form.Label>Interview Display Tag</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter interview display tag"
                                name="interviewDisplayId"
                                value={interview.interviewDisplayId}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Interview Display is required')}
                            />
                        </Form.Group>

                        {/* Author Selection */}
                        <Form.Group controlId="formAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                as="select"
                                name="authorId"
                                value={interview.authorId}
                                onChange={handleChange}
                            >
                                <option value="">Select Author</option>
                                {authors.map((author) => (
                                    <option key={author.id} value={author.id}>
                                        {author.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formKeywords">
                            <Form.Label>SEO Keywords</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter SEO keywords"
                                name="seoKeywords"
                                value={interview.seoKeywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveInterview} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditInterviewPage;
