import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'; // Import Link
import './CallRequestPage.scss';
import { CourseOrders } from '../../data/coursesData';
import { getDashboardOrders } from '../../services/DashboardService';
import MetaHelmet from '../../components/MetaHelmet';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const OrdersPage: React.FC = () => {
  const { status } = useParams<{ status: string }>();

  const [Orders, setOrders] = useState<CourseOrders[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardOrders(status || 'Completed');
        setOrders(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [status]);

  const handleDelete = (id: number) => {
    axios
      .delete(`http://localhost/api/dashboard/courses/call-requests/${id}`)
      .then(() => {
        setOrders(Orders.filter((request) => request.id !== id));
      })
      .catch((error) => {
        setError('Error deleting call request');
      });
  };


  const filteredData = Orders.filter((request) => {
    return (
      request.name?.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.mobile?.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
      <MetaHelmet
      title={`Orders ${status} - ${localStorage.getItem('email')} |  Course Echo`}
      description='Order page'
      ></MetaHelmet>
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>Orders {status} : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Course</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Status</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>{request.name}</td>
                <td>{request.mobile}</td>
                <td>{request.email}</td>
                <td>
                  <img src={request.course?.image} height={100} width={100} className='img-fluid rounded'></img>
                  <br></br>
                  {request.course?.title}</td>
                <td>{request.quantity}</td>
                <td>
                  <i className='fa fa-inr text-danger'></i> {request.totalPrice}</td>
                <td>
                  <div className="badge-card-container">
                    <div
                      key={request.status}
                      className={`badge-${request.status.toLowerCase()} badge-card selected}`}
                    >
                      {request.status}
                    </div>
                  </div>
                </td>

                <td>{request.created_at}</td>
                <td>{request.updated_at}</td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(request.id || 0)}
                    className="me-2 navBtn"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </div>
  );
};

export default OrdersPage;
