import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Course } from '../../data/coursesData';
import { getCategory, getCourseById } from '../../services/CourseService';
import { CourseCategory } from '../../data/categoryData';
import { Author } from '../../data/authorData';
import { getAuthors } from '../../services/AuthorsService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourse, getDashboardAuthors, updateCourse, uploadImage, uploadPDF } from '../../services/DashboardService';
import SyllabusViewer from '../../components/SyllabusViewer';

const AddEditCoursePage: React.FC = () => {
    const [course, setCourse] = useState<Course>({
        id: 0,
        title: '',
        description: '',
        image: '',
        fee: 0,
        rating: 1,
        badgeText: 'Standard', // Default value for badgeText
        source: 'local', // Default value for source
        categoryDisplayId: '',
        courseDisplayId: '',
        objective: '',
        discount: 0,
        syllabusUrl: '',
        authorId: '',
        keywords: '',
        numberOfTopics: 10,
        status:'Pending'
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<Author[]>([]);
    const [categories, setCategories] = useState<CourseCategory[]>([]);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { courseDisplayid, courseid } = useParams<{ courseDisplayid: string; courseid: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection

    const [syllabusPdfFile, setSyllabusPdfFile] = useState<File | null>(null); // State for handling file upload
    const [isPdfUrlSelected, setIsPdfUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection


    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const authorsResponse = await getDashboardAuthors();
                const categoriesResponse = await getCategory();
                setAuthors(authorsResponse);
                setCategories(categoriesResponse);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

        if (courseid) {
            setIsEditing(true);
            setLoading(true);
            setIsUrlSelected(true);
            setIsPdfUrlSelected(true)
            getCourseById(courseid)
                .then((course) => {
                    setCourse(course);
                })
                .catch((error) => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCourse((prevCourse) => ({ ...prevCourse, [name]: value }));
    };

    const handleEditorChange = (value: string) => {
        setCourse((prevCourse) => ({ ...prevCourse, objective: value }));
    };

    const handleSaveCourse = async () => {
        const validateCourse = (course: Course): string[] => {
            const errors: string[] = [];
            if (!course.title) errors.push('Title is required.');
            if (!course.courseDisplayId) errors.push('Course Display Tag is required.');
            if (!course.description) errors.push('Description is required.');
            if (!course.image && !imageFile) errors.push('Image is required.');
            if ((course.badgeText.toLowerCase() !== 'free' && course.fee < 0)) {
                if (!course.fee || isNaN(course.fee)) {
                    errors.push('Fee is required, should be a valid number, and cannot be negative.');
                }
            }
            if (!course.objective || course.objective.trim() === '') {
                errors.push('Objective is required and cannot be empty.');
            }
            if (!course.keywords || course.keywords.length === 0) {
                errors.push('Keywords are required.');
            }
            if (!course.authorId) errors.push('Author is required.');
            if (!course.categoryDisplayId) errors.push('Category is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateCourse(course);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            // Handle image upload if a file is selected
            if (imageFile) {
                const imageUrl = await uploadImage(imageFile);
                course.image = imageUrl; // Set the image URL after upload
            }

            if (syllabusPdfFile) {
                const syllabusUrl = await uploadPDF(syllabusPdfFile);
                course.syllabusUrl = syllabusUrl; // Set the image URL after upload
            }

            if (isEditing) {
                await updateCourse(course.id.toString(), course); // Call the update service
            } else {
                const data={...course,courseDisplayid:course.courseDisplayId?.trim().replace(/\s+/g, '-')}
                await addCourse(data); // Call the add service
            }
            navigate('/dashboard/courses');
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving the course.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setCourse((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handlePdfFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setSyllabusPdfFile(file);
            setCourse((prevCourse) => ({ ...prevCourse, syllabusUrl: '' })); // Clear the image URL if file is selected
        }
    };

    // Handle selecting Badge Text
    const handleBadgeTextChange = (badge: string) => {
        setCourse((prevCourse) => ({ ...prevCourse, badgeText: badge }));
    };

    // Handle selecting Source
    const handleSourceChange = (source: string) => {
        setCourse((prevCourse) => ({ ...prevCourse, source: source }));
    };

    const handleStatusChange = (status: string) => {
        setCourse((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit Course' : 'Add New Course'} - ${localStorage.getItem('email')} - Course Echo`}
                description="Dashboard to add/edit courses"
                keywords="course echo dashboard,Dashboard to add/edit courses"
                author="Course Echo"
            />

            <Breadcrumb className='p-1'>
                <Breadcrumb.Item>
                    <Link to="/"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard"> Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard/courses"> Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{isEditing ? 'Edit Course' : 'Add Course'}</Breadcrumb.Item>
            </Breadcrumb>
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter course title"
                                name="title"
                                value={course.title}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Title is required')}
                            />
                        </Form.Group>

                        <Form.Group controlId="formCourseDisplayId">
                            <Form.Label>Course Display Tag</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter course display tag"
                                name="courseDisplayId"
                                value={course.courseDisplayId}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Course Display is required')}
                            />
                        </Form.Group>

                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter course description"
                                name="description"
                                value={course.description}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Description is required')}
                            />
                        </Form.Group>


                        <Form.Group controlId="formImage">
                            <Form.Label>Course Image</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Image URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setImageFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {course.image && (
                                    <div className="uploaded-image-preview">
                                        <img src={course.image} alt="Preview" />
                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={course.image}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('Image is required')}
                                    disabled={!isUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload Image"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setCourse((prevCourse) => ({ ...prevCourse, image: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {imageFile && (
                                    <div className="uploaded-image-preview">
                                        <img src={URL.createObjectURL(imageFile)} alt="Preview" />
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageFileChange}
                                    disabled={isUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formObjective">
                            <Form.Label>Course Objective</Form.Label>
                            <ReactQuill
                                value={course.objective}
                                onChange={handleEditorChange}
                                placeholder="Write the course objectives..."
                            />
                        </Form.Group>
                    </Col>

                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formFee">
                            <Form.Label>Fee</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter course fee"
                                name="fee"
                                value={course.fee}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('Fee is required and should be a number')}
                            />
                        </Form.Group>

                        {/* Badge Text Selection */}
                        <Form.Group controlId="formBadgeText">
                            <Form.Label>Badge Text</Form.Label>
                            <div className="badge-card-container">
                                {['Standard', 'Premium', 'Free'].map((badge) => (
                                    <div
                                        key={badge}
                                        className={`badge-${badge.toLowerCase()} badge-card ${course.badgeText === badge ? 'selected' : ''}`}
                                        onClick={() => handleBadgeTextChange(badge)}
                                    >
                                        {badge}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Source Selection */}
                        <Form.Group controlId="formSource">
                            <Form.Label>Source</Form.Label>
                            <div className="source-card-container">
                                {['youtube', 'local'].map((source) => (
                                    <div
                                        key={source}
                                        className={`source-${source.toLowerCase()} source-card ${course.source === source ? 'selected' : ''}`}
                                        onClick={() => handleSourceChange(source)}
                                    >
                                        {source.charAt(0).toUpperCase() + source.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                        {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                        <Form.Group controlId="formSource">
                            <Form.Label>Course Status</Form.Label>
                            <div className="source-card-container">
                                {['Approved', 'Pending','Rejected'].map((status:string) => (
                                    <div
                                        key={status}
                                        className={`source-${status.toLowerCase()} source-card ${course.status === status ? 'selected' : ''}`}
                                        onClick={() => handleStatusChange(status)}
                                    >
                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                        ):(null)}

                        <Form.Group controlId="formSyllabusUrl">
                            <Form.Label>Syllabus URL</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Syllabus PDF URL"
                                    checked={isPdfUrlSelected}
                                    onChange={() => {
                                        setIsPdfUrlSelected(true);
                                        setSyllabusPdfFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {isPdfUrlSelected && (
                                    <div className="uploaded-image-preview-pdf">
                                        <SyllabusViewer pdfUrl={course.syllabusUrl || ''} />

                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={course.syllabusUrl}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('PDF is required')}
                                    disabled={!isPdfUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload PDF"
                                    checked={!isPdfUrlSelected}
                                    onChange={() => {
                                        setIsPdfUrlSelected(false);
                                        setCourse((prevCourse) => ({ ...prevCourse, syllabusUrl: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {!isPdfUrlSelected && (
                                    <div className="uploaded-image-preview-pdf">
                                        {syllabusPdfFile ? (
                                            <SyllabusViewer pdfUrl={URL.createObjectURL(syllabusPdfFile)} />

                                        ) : (null)}
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="application/pdf"
                                    onChange={handlePdfFileChange}
                                    disabled={isPdfUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md={2} xs={12} className='mb-5'>
                        <Form.Group controlId="formDiscount">
                            <Form.Label>Discount (%)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter discount percentage"
                                name="discount"
                                max={100}
                                value={course.discount}
                                onChange={handleChange}
                            />
                        </Form.Group>


                        {/* Category Selection */}
                        <Form.Group controlId="formCategory">
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                as="select"
                                name="categoryDisplayId"
                                value={course.categoryDisplayId}
                                onChange={handleChange}
                            >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                    <option key={category.categoryDisplayId} value={category.categoryDisplayId}>
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Author Selection */}
                        <Form.Group controlId="formAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                as="select"
                                name="authorId"
                                value={course.authorId}
                                onChange={handleChange}
                            >
                                <option value="">Select Author</option>
                                {authors.map((author) => (
                                    <option key={author.id} value={author.id}>
                                        {author.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formKeywords">
                            <Form.Label>SEO Keywords</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter SEO keywords"
                                name="keywords"
                                value={course.keywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveCourse} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add Course'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditCoursePage;
