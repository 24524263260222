import axios from 'axios';
import { Course, CourseQA, CourseQAReply } from '../data/coursesData';
import { ENDPOINT } from '../utils/Endpoints';
import { CourseCategory } from '../data/categoryData';

const COURSE_API_URL = `${ENDPOINT}/courses/short`;
const CAETGORY_API_URL = `${ENDPOINT}/courses/category`;
const USER_COURSE_API_URL = `${ENDPOINT}/courses/user/related`;
const COURSEQA_API_URL = `${ENDPOINT}/course-qa/by-course`;
const COURSEQA_BY_ID_API_URL = `${ENDPOINT}/course-qa/by-qa`;


export const getCourseQA = async (courseId:string): Promise<CourseQA[]> => {
  try {
    const response = await axios.get(`${COURSEQA_API_URL}/${courseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};
export const getCourseQAById = async (id:string): Promise<CourseQA[]> => {
  try {
    const response = await axios.get(`${COURSEQA_BY_ID_API_URL}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};
export const addCourseQAReply = async (data: CourseQAReply): Promise<CourseQAReply> => {
  const response = await axios.post(
    `${ENDPOINT}/course-qa-reply`,
    {
      ...data, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

export const addCourseQA = async (data: CourseQA): Promise<CourseQA> => {
  const response = await axios.post(
    `${ENDPOINT}/course-qa`,
    {
      ...data, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};
export const getCourses = async (): Promise<Course[]> => {
  try {
    const response = await axios.get(COURSE_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};


export const getCourseById = async (courseId: string): Promise<Course> => {
      const response = await axios.get<Course>(`${ENDPOINT}/courses/${courseId}/related`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
  
        },
      });
      return response.data;
  };

export const getCourseByCategoryDisplayId = async (courseId: string): Promise<Course[]> => {
  try {
    const response = await axios.get(`${ENDPOINT}/courses/categroy/${courseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`

      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

  export const getCategory = async (): Promise<CourseCategory[]> => {
    try {
      const response = await axios.get(CAETGORY_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
      throw error;
    }
  };

  export const getUserCourses = async (): Promise<Course[]> => {
    try {
      const response = await axios.get(USER_COURSE_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
      throw error;
    }
  };