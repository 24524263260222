import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';
import { InterviewModel } from '../data/interviewData';

const INTERVIEW_API_URL = `${ENDPOINT}/interview/short`;

export const getInterview = async (): Promise<InterviewModel[]> => {
  try {
    const response = await axios.get(INTERVIEW_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};



export const getInterviewById = async (itemId: string): Promise<InterviewModel[]> => {
      const response = await axios.get<InterviewModel[]>(`${ENDPOINT}/interview/${itemId}/related`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
  
        },
      });
      return response.data;
  };

  export const getInterviewByQuestion = async (id:string,questionId: string): Promise<InterviewModel[]> => {
    const response = await axios.get<InterviewModel[]>(`${ENDPOINT}/interview/${id}/${questionId}/question`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`

      },
    });
    return response.data;
};

