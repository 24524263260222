import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Author } from '../../data/authorData';
import AuthorCard from '../../components/AuthorCard';
import { getAuthors } from '../../services/AuthorsService';
import { Helmet } from 'react-helmet-async';
import './AuthorsPage.scss';
import BecomeMentorCard from '../../components/BecomeMentorCard';

interface AuthorProps {
    authors: Author[];
    isImported: boolean;
}

const AuthorsPage: React.FC<AuthorProps> = ({ isImported }) => {
    const [authors, setAuthors] = useState<Author[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                setLoading(true);
                const data = await getAuthors(); // Fetch authors using service
                setAuthors(data);
                setError(null);
            } catch (err) {
                console.error('Error fetching mentors:', err);
                setError('Failed to load mentors. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchAuthors();
    }, []);

    const handleAuthorClick = (id: string,name:string) => {
        navigate(`/mentor-details/${name}/${id}`);
    };


    if (loading) {
        return (
            <div className="course-details-container">
                <p className="text text-center">Loading mentors...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="course-details-container">
                <p className="text text-center">{error}</p>
            </div>
        );
    }

    return (
        <>
            {/* Add Helmet for better SEO */}
            <Helmet>
                <title>Explore Mentors - Course Echo</title>
                <meta
                    name="description"
                    content="Discover a diverse range of mentors and their impactful works at Course Echo. Read their biographies, works, and more."
                />
                <meta
                    name="keywords"
                    content="mentors, writers, biographies, books, Course Echo, mentor details, famous mentors"
                />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <div className="container authorPage">
                {/* Main Heading */}
                <h1 className="text-center mb-4">Explore Our Featured Mentors</h1>

                {/* Subheading */}
                <h2 className="text-center mb-5">Discover their Stories, Works, and Achievements</h2>

                <div className="row">
                    {authors.map((author) => (
                        <div key={author.id} className="col-sm-12 col-md-4">
                            <AuthorCard author={author} onClick={handleAuthorClick} />
                        </div>
                    ))}
                </div>
                <div className="container mt-5">
                <BecomeMentorCard></BecomeMentorCard>

                </div>

            </div>
        </>
    );
};

export default AuthorsPage;
