import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { editCounselorById, getCounselorByToken, getDashboardUsers } from '../../services/DashboardService';
import { UserInterface, UserProfileInterface } from '../../data/userData';
import { Counselor } from '../../data/campusData';

const AddEditCounselorPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const navigate = useNavigate();
    const [author, setAuthor] = useState<Counselor>({
        id: '',
        name: '',
        description: '',
        seoKeyword: '',
        userId: '',
        verified: '1'
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<UserProfileInterface[]>([]);
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [isCounselor, setIsCounselor] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
  

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const authorsResponse = await getCounselorByToken();
                setAuthors(authorsResponse);
                const userData = await getDashboardUsers("STUDENT");
                setUsers(userData);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };
    
        fetchDropdownData();
    }, [id]);
    
    // Watching authors for changes
    useEffect(() => {
        if (authors.length > 0 && authors[0]?.counselor?.id) {
            setAuthor(authors[0].counselor);
            setIsCounselor(true);
            setLoading(false);
            setIsEditing(true)
        }
        else
        {
            setIsEditing(false)
        }
    }, [authors]); // This effect triggers when 'authors' changes
    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setAuthor((prevCourse) => ({ ...prevCourse, [name]: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setAuthor((prevCourse) => ({ ...prevCourse, description: value }));
    };

    const handleSaveAuthor = async () => {
        const validateAuthor = (author: Counselor): string[] => {
            const errors: string[] = [];
            if (!author.name) errors.push('name is required.');
            if (!author.description) errors.push('Description is required.');
            if (!author.seoKeyword || author.seoKeyword.length === 0) {
                errors.push('SEO Keywords are required.');
            }
            return errors;
        };

        // Perform Validation
        const errors = validateAuthor(author);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            if (isEditing) {
                let payload = { ...author }
                delete payload['colleges'];
                delete payload['schools'];
                await editCounselorById(payload); // Call the update service
            }

            navigate('/profile')
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit Counselor' : 'Add New Counselor'} - ${localStorage.getItem('email')} - Counselor Echo`}
                description="Dashboard to add/edit Counselors"
                keywords="Counselor echo dashboard,Dashboard to add/edit Counselors"
                author="Counselor Echo"
            />
            {localStorage.getItem("defaultClaim") === "SUPERADMIN" ? (
                <Breadcrumb className='p-1'>
                    <Breadcrumb.Item>
                        <Link to="/"> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard"> Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard/mentors"> Counselors</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit Counselor' : 'Add Counselor'}</Breadcrumb.Item>
                </Breadcrumb>) : (null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={5} xs={12} className='mb-5'>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ente name"
                                name="title"
                                value={author.name}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('name is required')}
                            />
                        </Form.Group>



                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={author.description}
                                onChange={handleDescriptionEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>
                    </Col>



                    <Col md={3} xs={12} className='mb-5'>



                        {/* Author Selection */}
                        {localStorage.getItem("defaultClaim") === "SUPERADMIN" ? (

                            <Form.Group controlId="formUser">
                                <Form.Label>Mapped User</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="userId"
                                    value={author.userId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select User</option>
                                    {users.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        ) : (null)}
                        <Form.Group controlId="formKeywords">
                            <Form.Label>SEO Keywords (, seperated)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter SEO keywords"
                                name="seoKeyword"
                                value={author.seoKeyword}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveAuthor} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditCounselorPage;
