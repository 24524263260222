import React, { useState, useEffect, useRef } from 'react';
import './ChatComponent.scss'; // Ensure CSS is updated
import { Message, SessionBooking } from '../data/campusData';
import { campusService } from '../services/CampusService';
import { useParams } from 'react-router-dom';
import notify from '../services/notificationService';
import { Helmet } from 'react-helmet-async';
import { PROJECTNAME } from '../utils/constants';

const ChatComponent: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [booking, setBooking] = useState<SessionBooking>();
    const [error, setError] = useState<string | null>(null);
    const [chattingWith, setChattingWith] = useState<string>('');
    const [newMessage, setNewMessage] = useState<string>('');
    const { id } = useParams<{ id: string }>();

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const fetchMessages = async () => {
        setLoading(true);
        try {
            const response = await campusService.getSessionBookedMessageById(id || '');
            setMessages(response);
        } catch (error) {
            console.error('Error fetching messages:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                const data = await campusService.getSessionBookedByMe();
                setBooking(data[0]);
                const bookingData = data[0];
                if (localStorage.getItem('email') === bookingData?.counselor?.user?.email) {
                    setChattingWith(bookingData?.counselor?.user?.name ?? '');
                } else {
                    setChattingWith(bookingData?.user?.name ?? '');
                }
                fetchMessages();
            } catch (err) {
                setError('Failed to fetch. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchBooking();
    }, [id]);

    useEffect(() => {
        const interval = setInterval(fetchMessages, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleRefresh = () => {
        fetchMessages();
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        try {
            let senderId = '';
            let receiverId = '';
            if (localStorage.getItem('email') === booking?.counselor?.user?.email) {
                senderId = booking.counselor.user.id.toString();
                receiverId = booking?.user?.id.toString() || '';
            } else {
                receiverId = booking?.counselor?.user?.id.toString() || '';
                senderId = booking?.user?.id.toString() || '';
            }
            const payload: Message = {
                bookingId: id || '',
                senderId: senderId,
                receiverId: receiverId,
                content: newMessage,
            };
            const response = await campusService.sendSessionBookedMessageById(id || '', payload);
            if (response) {
                setNewMessage('');
                fetchMessages();
            } else {
                notify.error('Failed to send message', {
                    position: 'top-right',
                    autoClose: 2000,
                });
            }
        } catch (err) {
            console.error('Error sending message:', err);
            notify.error('Error sending message:', {
                position: 'top-right',
                autoClose: 2000,
            });
        }
    };

    const getInitials = (name: string) => {
        return name
            .split(' ')
            .map((word) => word[0].toUpperCase())
            .join('');
    };

    return (
        <div className="chat-container">
            <Helmet>
                <title>Chatting With {chattingWith} - CounselorBooking #{id} | Course Echo</title>
                <meta name="description" content={`Chatting With ${chattingWith}`} />
                <meta name="keywords" content={'chat, online chatting,course echo'} />
                <meta name="author" content={PROJECTNAME} />
            </Helmet>
            <div className="chat-header">
                <p>Chatting With {chattingWith}</p>
                <button className="refresh-button" onClick={handleRefresh} disabled={loading}>
                    {loading ? 'Refreshing...' : 'Refresh'}
                </button>
            </div>

            <div className="chat-messages">
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`chat-message ${localStorage.getItem('email') === message.senderUser?.email ? 'sender' : 'receiver'
                            }`}
                    >
                        <div
                            className={` ${localStorage.getItem('email') === message.senderUser?.email ? 'sender-circle' : 'receiver-circle'
                                }`}
                        >
                            {getInitials(
                                localStorage.getItem('email') === message.senderUser?.email
                                    ? message.senderUser?.name ?? ''
                                    : message.receiverUser?.name ?? ''
                            )}
                        </div>
                        <div className="message-content">

                            <p>{message.content}</p>
                            <small>{message.dateAdded}</small>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className="chat-input-container">
                <textarea
                    placeholder="Type a message..."
                    className="chat-input"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                />
                <button className="send-button" onClick={handleSendMessage}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatComponent;
