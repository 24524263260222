import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./SessionBooking.scss"; // Include your custom styles
import { UserProfileInterface } from "../../data/userData";
import { getCounselorById } from "../../services/DashboardService";
import notify from "../../services/notificationService";
import { RAZRPAYKEY, RAZRPAYSECRETKEY } from "../../utils/Keys";
import { ENDPOINT } from "../../utils/Endpoints";
import axios from "axios";
import { UserModel } from "../profile/model/User.model";
import { duration } from "html2canvas/dist/types/css/property-descriptors/duration";
import GoogleLoginComponent from "../../components/GoogleLoginComponent";
import { Helmet } from "react-helmet-async";

// Mock service function to add session data
const bookSession = async (sessionDetails: any) => {
    try {
        const response = await fetch("https://api.example.com/book-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(sessionDetails),
        });

        if (!response.ok) {
            throw new Error("Failed to book session");
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const SessionBooking: React.FC = () => {
    useEffect(() => {
        // Check if the script is already present
        if (!document.querySelector(`script[src="https://checkout.razorpay.com/v1/checkout.js"]`)) {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);
    const [formErrors, setFormErrors] = useState({ name: '', email: '', mobile: '', duration: '', date: "", time: "" });

    const { id } = useParams<{ id: string }>();

    const [sessionDetails, setSessionDetails] = useState({
        id: "",
        duration: "30-min",
        date: "",
        time: "",
        name: "",
        email: "",
        mobile: "",
        message: "",
    });
    const [author, setAuthor] = useState<UserProfileInterface | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<UserModel | null>(null);

    const navigate = useNavigate();
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setLoading(true);
                const email = localStorage.getItem("email");
                if (email) {
                    const response = await axios.get(`${ENDPOINT}/users/${email}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    if (response.status === 200) {
                        setUser(response.data);
                        setSessionDetails({
                            id: response.data.id,
                            name: response.data.name,
                            email: response.data.email,
                            mobile: response.data.mobile || '',
                            duration: "30-min",
                            date: "",
                            time: "",
                            message: "",
                        });
                    }
                }
            } catch (err) {
                console.error('Error fetching user profile:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    useEffect(() => {
        const fetchAuthorDetails = async () => {
            try {
                setLoading(true);
                const data = await getCounselorById(id || '');
                setAuthor(data[0]); // Assuming the response is an array and you're interested in the first item
                setError(null);
            } catch (err) {
                console.error('Error fetching details:', err);
                setError('Failed to load details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchAuthorDetails();
        }
    }, [id]);
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setSessionDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleCardSelect = (duration: string) => {
        setSessionDetails((prev) => ({ ...prev, duration }));
    };


    const updatePaymentStatus = async (payload: any) => {
        setLoading(true);
        payload.status = 'Completed'
        const response = await axios.put(`${ENDPOINT}/campus-counselor-booking/orders/update/${payload.id}`, payload, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        });
        if (response.status === 200) {
            notify.success('Great , thanks for booking session', {
                position: 'top-right',
                autoClose: 2000,
            });

            navigate('/dashboard')

        }
        else {
            notify.error('Error: while updateing status', {
                position: 'top-right',
                autoClose: 2000,
            });
        }
        setLoading(false);
    };
    const handlePayment = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!localStorage.getItem("token")) {
            notify.error('Please login first ', {
                position: 'top-right',
                autoClose: 2000,
            });
            return false;
        }

        // Form validation before proceeding with payment
        if (!sessionDetails.name || !sessionDetails.email || !sessionDetails.mobile || !sessionDetails.duration || !sessionDetails.date || !sessionDetails.time || !isValidDateAndTime) {
            setFormErrors({
                duration: !sessionDetails.duration ? 'Duration is required' : '',
                date: !sessionDetails.date ? 'Date is required' : '',
                time: !sessionDetails.time ? 'Time number is required' : '',
                name: !sessionDetails.name ? 'Name is required' : '',
                email: !sessionDetails.email ? 'Email is required' : '',
                mobile: !sessionDetails.mobile ? 'Mobile number is required' : '',

            });
            console.log(formErrors)
            notify.error('Please fill required ', {
                position: 'top-right',
                autoClose: 2000,
            });
            return;
        }

        setFormErrors({ name: '', email: '', mobile: '', duration: "", date: "", time: "" }); // Reset errors

        setLoading(true);
        try {
            let payload = {
                counselorId: author?.counselor?.id,
                email: sessionDetails.email,
                name: sessionDetails.name,
                mobile: sessionDetails.mobile,
                orderDate: new Date(),
                quantity: "1",
                status: "Processing",
                totalPrice: sessionDetails.duration === "30-min" ? 250 : 450,
                userId: user?.id,
                date: sessionDetails.date,
                time: sessionDetails.time,
                duration: sessionDetails.duration,
                message: sessionDetails.message ?? "",
            }

            const response = await fetch(`${ENDPOINT}/campus-counselor-booking/orders/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`

                },
                body: JSON.stringify(payload),
            });

            if (response.status !== 201) {
                setLoading(false);
                notify.error('Failed to create order', {
                    position: 'top-right',
                    autoClose: 2000,
                });
                throw new Error('Failed to create order');
            }

            const order = await response.json();
            console.log('Order data:', order);
            const amountInPaise = order.data.totalPrice * 100; // Convert to paise if the currency is INR

            const options = {
                key: RAZRPAYKEY, // Replace with your Razorpay key ID
                key_secret: RAZRPAYSECRETKEY,
                amount: amountInPaise,
                currency: 'INR',
                name: 'Order',
                description: 'Order Payment',
                id: order.data.id,
                // order_id: order.data.id,
                handler: (response: any) => {
                    console.log(response)
                    notify.success(`Payment successful! Payment ID: ${response.razorpay_payment_id}`, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                    updatePaymentStatus(order.data)
                },
                sessionDetails: {
                    name: sessionDetails.name,
                    contact: sessionDetails.mobile,
                    email: sessionDetails.email
                },
                theme: {
                    color: '#33250cc',
                },
            };
            const razorpay = new (window as any).Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.log(error)
            notify.error('Failed to initiate payment. Please try again.', {
                position: 'top-right',
                autoClose: 2000,
            });
        } finally {
            setLoading(false);
        }

    };
    const isValidDateAndTime = (): boolean => {
        const { date, time } = sessionDetails;
        const selectedDate = new Date(`${date}T${time}`);
        const now = new Date();

        if (!date || !time) {
            notify.error('Please select both a valid date and time.', {
                position: 'top-right',
                autoClose: 2000,
            });
            return false;
        }

        if (selectedDate < now) {
            notify.error('Selected date and time cannot be in the past.', {
                position: 'top-right',
                autoClose: 2000,
            });
            return false;
        }

        return true;
    };
    const minDate = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format

    return (
        <div className="session-booking-container">
            <Helmet>
                <title>Session Booking with {`${author?.counselor?.name}`} | Course Echo</title>
                <meta
                    name="description"
                    content="Book session with campus counselor,courseecho"
                />
                <meta
                    name="keywords"
                    content="campus counselor,college counselor,school counselor,online,course echo"
                />
                <meta name="author" content="Course Echo" />
            </Helmet>
            <h3 className="text-primary">Book a Video Session with {author?.counselor?.name}</h3>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handlePayment}>
                {/* Duration Selection */}
                <div className="session-options">
                    <strong className="text-secondary pt-5 mt-5">Session Duration</strong>
                    <div className="session-columns">
                        <ul title="Video Meeting 30 mint" className={`session-price ${sessionDetails.duration === "30-min" ? "selected" : ""}`} onClick={() => handleCardSelect("30-min")}>
                            <li className="session-header">Basic</li>
                            <li className="session-grey"><i className="fa fa-clock-o text-secondary"></i> 30 / Mint</li>
                            <li className="text-danger">₹250</li>
                            <li className="text-primary">50 Chat</li>
                        </ul>
                    </div>

                    <div className={`session-columns`}
                        onClick={() => handleCardSelect("1-hour")}

                    >
                        <ul title="Video Meeting 1 hour" className={`session-price ${sessionDetails.duration === "1-hour" ? "selected" : ""}`}>
                            <li className="session-header bg-success text-white">Premium</li>
                            <li className="session-grey"><i className="fa fa-clock-o"></i> 1 / Hour</li>
                            <li className="text-danger">₹450</li>
                            <li className="text-success">Unlimited Chat</li>
                        </ul>
                    </div>
                </div>

                {/* Date and Time Picker */}
                <div className="form-group">
                    <label className="customlabel" htmlFor="date">Select Date</label>
                    <input
                        className="customInput"
                        type="date"
                        id="date"
                        name="date"
                        min={minDate}
                        value={sessionDetails.date}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="customlabel" htmlFor="time">Select Time</label>
                    <input
                        className="customInput"

                        type="time"
                        id="time"
                        name="time"
                        value={sessionDetails.time}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* User Details */}
                <div className="form-group">
                    <label className="customlabel" htmlFor="name">Name</label>
                    <input
                        className="customInput"
                        type="text"
                        id="name"
                        name="name"
                        value={sessionDetails.name}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="customlabel" htmlFor="email">Email</label>
                    <input
                        className="customInput"

                        type="email"
                        id="email"
                        name="email"
                        value={sessionDetails.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="customlabel" htmlFor="mobile">Mobile</label>
                    <input
                        className="customInput"

                        type="tel"
                        id="mobile"
                        name="mobile"
                        value={sessionDetails.mobile}
                        onChange={handleInputChange}
                        placeholder="Enter your mobile number"
                        required
                    />
                </div>

                {/* Message for Counselor */}
                <div className="form-group">
                    <label className="customlabel" htmlFor="message">Message for Counselor</label>
                    <textarea
                        className="customInput"

                        id="message"
                        name="message"
                        value={sessionDetails.message}
                        onChange={handleInputChange}
                        placeholder="Write a message for the counselor (optional)"
                    />
                </div>

                {/* Submit Button */}
                <button type="submit" className="btn viewBtn">
                    Book
                </button>
            </form>
            {!localStorage.getItem('token') ?
                (
                    <div className='text-center'>
                        <br></br>
                        <p className="text-danger">Please login first to proceed next!</p>
                        <Link to='/login' className='btn navBtn'>Login now</Link>
                        <br></br><br></br>
                        <p>
                            <GoogleLoginComponent isLoginScreen={true} />
                        </p>
                    </div>
                ) : (null)

            }
        </div>
    );
};

export default SessionBooking;
