import React from 'react';
import './TutorialsCard.scss'; // Optional styling
import { Link } from 'react-router-dom';
import { Tutorial } from '../data/tutorialsData';

interface TutorialsCardProps {
  tutorials: Tutorial;
}

const TutorialsCard: React.FC<TutorialsCardProps> = ({ tutorials }) => { 
  const {
    title,
    courseId,
    externalLink,
    total,
    Courses
  } = tutorials;

  return (
    <div className="tut-c-card ">

      <div className="tut-card mpt-tutorials-card cardBg">
        <div className="tut-card-image-container">
          
        <div className="course-details tut-details p-4 text-center">
        <div className="ribbon-container" title={`${Courses?.badgeText.toLowerCase()} Tutorial`}>
            <span className={`ribbon ${Courses?.badgeText.toLowerCase()}`}>{Courses?.badgeText}</span>
          </div>
        {/* <span className="course-badge free-course">{Courses?.badgeText}</span> */}

<div className="rating tut-rating">
  <span>{Courses?.rating} ⭐</span>
</div>
<div className='course-badge course-topics' title='Total topic list'>Topics : {total}</div>

</div>
          {/* <img src={Courses?.image} alt={title} className="img-fluid" /> */}
        </div>
        <div className="tut-card-body cardBg">
          {Courses?.source !== 'local' && externalLink !== '' ? (
            <h5 className="tut-card-title tut-truncate1 text-center" title={Courses?.title}>{Courses?.title}</h5>

          ) : (

           
              <h5 title={title}>
                 <Link
              to={`/tutorials-details/${courseId}`} className='tut-tut-card-title tut-truncate1'
            >
                {title}
                </Link>

                </h5>
          )}
          <div className="tut-button-container mt-4 cardBg">
           
                  <Link
                    to={`/tutorials-details/${courseId}`}
                    className="tut-button viewBtn"
                  >
                    Learn now
                  </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialsCard;
