// src/pages/Mentor.tsx
import React, { Suspense } from 'react';
import './Mentor.scss'; // Optional: You can create a CSS file for styling
import { APPLYNOW } from '../utils/Endpoints';
import { PROJECTNAME } from '../utils/constants';
import MetaHelmet from '../components/MetaHelmet';
import { Link } from 'react-router-dom';
const MentorEarningCalculator = React.lazy(() => import('../components/MentorEarningCalculator'));
const HowItWorks = React.lazy(() => import('../components/HowItWorks'));
const MentorSignup = React.lazy(() => import('./authentication/MentorSignup'));

const Mentor: React.FC = () => {
  // Define schema markup or fetch dynamically if needed
  const generateSchemaData = () => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Mentor Us - Course Echo",
    "url": "https://courseecho.com/mentor",
    "description": "Join Course Echo as a mentor to inspire learners, earn up to 30k per month, and access exclusive tech content.",
    "author": {
      "@type": "Organization",
      "name": "Course Echo",
      "url": "https://courseecho.com"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://courseecho.com/mentor?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  });
  return (
    <div className="Mentor">
      <MetaHelmet
        title="Become a Mentor at Course Echo | Empower Learners & Advance Careers"
        description="Join Course Echo as a mentor to inspire learners, earn up to 30k per month, and access exclusive tech content. Shape the future of coding education with us!"
        keywords="Mentorship, Course Echo, mentor opportunities, coding tutorials, Java, Angular, Flask, AWS, online courses, earn as a mentor, tech career, programming education"
        author="Course Echo Team"
        ogTitle="Become a Mentor at Course Echo | Empower Learners & Advance Careers"
        ogDescription="Join Course Echo to mentor aspiring coders and professionals. Earn rewards, network with like-minded individuals, and make an impact!"
        ogImage="https://images.courseecho.com/mentor/1.webp"
        ogUrl="https://courseecho.com/mentor"
        twitterTitle="Become a Mentor at Course Echo | Empower Learners & Advance Careers"
        twitterDescription="Mentor aspiring professionals with Course Echo. Earn up to 30k, access exclusive content, and build your tech network."
        twitterImage="https://images.courseecho.com/mentor/1.webp"
        twitterCreator="@CourseEcho"
        schemaMarkup={generateSchemaData()}
      />

      <div className="Mentor container">
        <div className="row">
          <div className="col-sm-12 col-md-4 text-start mentorHead">
            <h1 className='mentor_header'>
              Empower as a Mentor, Inspire Change
            </h1>

            <Link to="/join-mentor" className="btn hero-button">
              Apply Now <sup className='fa fa-paper-plane-o'></sup>
            </Link>
          </div>
          <div className="col-sm-12 col-md-8">
            <img src='https://images.courseecho.com/mentor/1.webp' alt='Course echo mentor' className='img-fluid mentor_banner_image'></img>
            <div className="feature-box top-right">
              <div className="col-sm-12 col-md-2">
                <i className='fa fa-unlock dollarIcon shadow text-primary'></i>
              </div>
              <div className="col-sm-12 col-md-10">
                Free access to <strong>exclusive content & Live classes</strong>
              </div>
            </div>
            <div className="feature-box middle-right">
              <div className="col-sm-12 col-md-2">
                <i className='fa fa-inr dollarIcon shadow text-success'></i>
              </div>
              <div className="col-sm-12 col-md-10">
                Earn upto <strong>30k</strong> per month
              </div>
            </div>
            <div className="feature-box bottom-right">
              <div className="col-sm-12 col-md-2">
                <i className='fa fa-snowflake-o dollarIcon shadow bg-warning'></i>
              </div>
              <div className="col-sm-12 col-md-10">
                Build a <strong>network</strong> with like minded people
              </div>
            </div>
            <div className="banner_circle banner_circle_top">

            </div>
            <div className="banner_circle banner_circle_bottom">

            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h2 className='mentorHeading'>
            Upskill with {PROJECTNAME} — Your Gateway to Tech Excellence!
          </h2>



        </div>
      </div>
      <div className="container mt-2">
        <div className="row insight-box-row">
          <div className="col-sm-12 col-md-3">
            <div className="insight-box">
              <div className="col-sm-12 col-md-4">
                <i className='fa fa-users insightIcon shadow text-info'></i>
              </div>
              <div className="col-sm-12 col-md-8">
                100+ <strong>Students</strong>
              </div>
            </div>


          </div>
          <div className="col-sm-12 col-md-3">
            <div className="insight-box">
              <div className="col-sm-12 col-md-4">
                <i className='fa fa-code insightIcon shadow text-danger'></i>
              </div>
              <div className="col-sm-12 col-md-8">
                5K Strong
                <strong> Community</strong>
              </div>
            </div>


          </div>
          <div className="col-sm-12 col-md-3">
            <div className="insight-box">
              <div className="col-sm-12 col-md-4">
                <i className='fa fa-graduation-cap insightIcon shadow text-success'></i>
              </div>
              <div className="col-sm-12 col-md-8">
                1K+ Successful <strong> Alumnis</strong>
              </div>
            </div>


          </div>
          <div className="col-sm-12 col-md-3">
            <div className="insight-box">
              <div className="col-sm-12 col-md-4">
                <i className='fa fa-id-card-o insightIcon shadow text-primary'></i>
              </div>
              <div className="col-sm-12 col-md-8">
                15+ Expert <strong>Mentors</strong>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Suspense fallback={<div className='container'>Loading Mentor Timeline...</div>}>

        <HowItWorks></HowItWorks>
      </Suspense>
      <br></br>
      <Suspense fallback={<div className='container'>Loading Calculator...</div>}>
        <MentorEarningCalculator />
      </Suspense>
      <br></br>
      <Suspense fallback={<div className='container'>Loading Mentor Singup...</div>}>
        <MentorSignup isImported={true} />
      </Suspense>
    </div>
  );
};

export default Mentor;
