import React, { useState } from 'react';
import { CourseTest } from '../data/coursesData';
import './TestComponent.scss';

interface TestProps {
  tests: Array<CourseTest>;
}

const TestComponent: React.FC<TestProps> = ({ tests }) => {
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAnswerChange = (questionId: number, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const getScore = () => {
    let score = 0;
    tests.forEach((test) => {
      if (answers[test.id] === test.correctAnswer) {
        score += 1;
      }
    });
    return score;
  };

  const renderOptionStatus = (testId: number, option: string) => {
    const selectedAnswer = answers[testId];
    const correctAnswer = tests.find(test => test.id === testId)?.correctAnswer;

    if (!isSubmitted) return null;

    if (selectedAnswer === option) {
      return selectedAnswer === correctAnswer ? (
        <span className="text-success">✔</span> // Green checkmark
      ) : (
        <span className="text-danger">✘</span> // Red cross
      );
    }

    return null;
  };

  const renderQuestionStatus = (testId: number) => {
    const selectedAnswer = answers[testId];
    const correctAnswer = tests.find(test => test.id === testId)?.correctAnswer;
  
    if (selectedAnswer === undefined) {
      // If no answer was selected
      return <span className="text-black text-bold">❗ Unanswered</span>; // Show a warning for unanswered questions
    }
  
    return selectedAnswer === correctAnswer ? (
      <span className="text-success text-bold">✔ Correct</span> // Correct answer
    ) : (
      <span className="text-danger text-bold">✘ Incorrect</span> // Incorrect answer
    );
  };
  

  const getMessage = () => {
    const score = getScore();
    if (score === tests.length) {
      return "Excellent! You aced the test!";
    } else if (score >= tests.length * 0.75) {
      return "Great job! You did really well!";
    } else if (score >= tests.length * 0.5) {
      return "Good effort! Keep practicing!";
    } else {
      return "Better luck next time! Keep learning!";
    }
  };

  return (
    <div className="test-container">
      {tests.map((test,index) => (
        <div key={test.id} className="test-question">
          <h5>({index+1}) {test.title}</h5>
          <p className='truncate' dangerouslySetInnerHTML={{ __html: test?.description || '' }} />

          <div className="test-options">
            {/* Ensure options is parsed into an array if it's a string */}
            {Array.isArray(test.options) ? test.options.map((option, index) => (
              <div key={index} className="form-check">
                <input
                  type="radio"
                  id={`question-${test.id}-option-${index}`}
                  name={`question-${test.id}`}
                  value={option}
                  checked={answers[test.id] === option}
                  onChange={() => handleAnswerChange(test.id, option)}
                  className="form-check-input"
                  disabled={isSubmitted} // Disable options after submission
                />
                <label
                  htmlFor={`question-${test.id}-option-${index}`}
                  className="form-check-label"
                >
                  {option}
                  {renderOptionStatus(test.id, option)} {/* Show green/red icon */}
                </label>
              </div>
            )) : null}
            {/* If options are provided as a string, parse it into an array */}
            {typeof test.options === 'string' ? JSON.parse(test.options).map((option: string, index: number) => (
              <div key={index} className="form-check">
                <input
                  type="radio"
                  id={`question-${test.id}-option-${index}`}
                  name={`question-${test.id}`}
                  value={option}
                  checked={answers[test.id] === option}
                  onChange={() => handleAnswerChange(test.id, option)}
                  className="form-check-input"
                  disabled={isSubmitted} // Disable options after submission
                />
                <label
                  htmlFor={`question-${test.id}-option-${index}`}
                  className="form-check-label"
                >
                  {option}
                  {renderOptionStatus(test.id, option)} {/* Show green/red icon */}
                </label>
              </div>
            )) : null}
          </div>
          {isSubmitted && renderQuestionStatus(test.id)} {/* Display status for each question */}
        </div>
      ))}
      {!isSubmitted ? (
        <button onClick={handleSubmit} className="btn btn-primary mt-3">
          Submit Test
        </button>
      ) : (
        <div className="test-result">
          <h5>Your Score: {getScore()} / {tests.length}</h5>
          <h6>{getMessage()}</h6> {/* Display the interesting message based on score */}
        </div>
      )}
    </div>
  );
};

export default TestComponent;
