import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';

class SearchService {
  private headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  /**
   * Fetch courses by search query.
   * @param query The search query.
   * @returns Promise<any>
   */
  async searchCourses(query: string): Promise<any> {
    try {
      const response = await axios.get(`${ENDPOINT}/courses/search`, {
        params: { query },
        headers: this.headers,
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return [];  // Return empty array if not found
      }
      throw error; // Re-throw if it's a different error
    }
  }

  /**
   * Fetch categories by search query.
   * @param query The search query.
   * @returns Promise<any>
   */
  async searchCategories(query: string): Promise<any> {
    try {
      const response = await axios.get(`${ENDPOINT}/courses/category/search`, {
        params: { query },
        headers: this.headers,
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return [];  // Return empty array if not found
      }
      throw error; // Re-throw if it's a different error
    }
  }

  /**
   * Fetch tutorials by search query.
   * @param query The search query.
   * @returns Promise<any>
   */
  async searchTutorials(query: string): Promise<any> {
    try {
      const response = await axios.get(`${ENDPOINT}/tutorials/search`, {
        params: { query },
        headers: this.headers,
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return [];  // Return empty array if not found
      }
      throw error; // Re-throw if it's a different error
    }
  }

  /**
   * Fetch courses by search query.
   * @param query The search query.
   * @returns Promise<any>
   */
  async searchInterviews(query: string): Promise<any> {
    try {
      const response = await axios.get(`${ENDPOINT}/interview/search`, {
        params: { query },
        headers: this.headers,
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return [];  // Return empty array if not found
      }
      throw error; // Re-throw if it's a different error
    }
  }

  /**
   * Fetch all results (courses, categories, tutorials).
   * @param query The search query.
   * @returns Combined search results.
   */
  async searchAll(query: string): Promise<{ courses: any[]; categories: any[]; tutorials: any[],interviews:[] }> {
    const [courses, categories, tutorials, interviews] = await Promise.all([
      this.searchCourses(query),
      this.searchCategories(query),
      this.searchTutorials(query),
      this.searchInterviews(query)
    ]);

    return {
      courses: courses || [],
      categories: categories || [],
      tutorials: tutorials || [],
      interviews: interviews || []
    };
  }
}

export const searchService = new SearchService();
