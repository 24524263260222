// src/pages/About.tsx
import React from 'react';
import './About.scss'; // Optional: You can create a CSS file for styling
import { Helmet } from 'react-helmet-async';

const About: React.FC = () => {
  return (
    <div className="container about">
      <Helmet>
        <title>About Us - Course Echo | Courses for Every Field</title>
        <meta name="description" content="Explore Course Echo - Your go-to destination for diverse courses across tech, management, social media, arts, medical, and more!" />
        <meta name="keywords" content="Course Echo, coding tutorials, online courses, management, social media, arts, medical courses, drama, blogging, 10th 12th classes, career skills, education" />
        <meta name="author" content="Course Echo Team" />
        <meta property="og:title" content="About Us - Course Echo | Courses for Every Field" />
        <meta property="og:description" content="Learn diverse skills at Course Echo with courses in tech, management, social media, arts, medical, and more." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://courseecho.com/about" />
        <meta property="og:image" content="https://images.courseecho.com/dark-logo.webp" />
        <meta name="twitter:card" content="https://images.courseecho.com/dark-logo.webp" />
        <meta name="twitter:title" content="About Us - Course Echo | Courses for Every Field" />
        <meta name="twitter:description" content="Explore Course Echo - Courses in tech, management, social media, arts, 10th 12th classes, and more!" />
        <meta name="twitter:image" content="https://images.courseecho.com/dark-logo.webp" />
        <meta name="twitter:creator" content="@CourseEcho" />
      </Helmet>

      <h1 className="header">Welcome to Course Echo</h1>

      <p>
        Your ultimate destination for diverse and comprehensive learning! 🚀 At Course Echo, we offer a wide range of courses for every career and passion—whether you're focused on technology, management, social media, arts, medical, or other fields, we have something for everyone.
      </p>

      <p>
        🖥️ At <strong>Course Echo</strong>, we provide courses across a variety of domains, including:
        - <strong>Tech Courses</strong>: Master programming languages and frameworks like Java, Angular, Flask, AWS, MySQL, PHP, and more. Build your career in the fast-paced tech industry.
        - <strong>Management Courses</strong>: Learn essential business management skills, leadership, and strategies to advance your career and business.
        - <strong>Social Media & Digital Marketing</strong>: Learn how to grow and manage social media platforms like YouTube, Instagram, and blogging to create a successful online presence. Perfect for aspiring influencers, marketers, and content creators.
        - <strong>Arts & Communication</strong>: Explore creative fields such as drama, acting, mass communication, and public speaking. Develop the skills needed to excel in the arts and communication industry.
        - <strong>Medical Courses</strong>: Dive into healthcare-related subjects with comprehensive learning resources for aspiring medical professionals.
        - <strong>Finance Courses</strong>: Understand the principles of financial management, investing, banking, and more.
        - <strong>Grade-Specific Courses</strong>: Whether you're in high school or preparing for competitive exams, we offer courses tailored for 10th, 12th, and other academic learners.
      </p>

      <p>
        🌟 To deliver the best course content, we're constantly expanding our team and hiring:
        - <strong>Freelance Trainers</strong>: Share your expertise across various subjects and help build top-notch tutorials.
        - <strong>Remote Trainers</strong>: Work with us from anywhere in the world to empower learners globally.
      </p>

      <p>
        🌐 Whether you're a beginner or an experienced learner, our step-by-step guides, interactive sessions, and expert instructors will help you master any field. Whether it's building dynamic web applications, creating a personal brand on social media, or excelling in arts and communication, we have you covered.
      </p>

      <p>
        🚀 Join our vibrant community of learners and trainers to stay ahead in your chosen field. Don’t just learn; create! Subscribe now for regular updates and elevate your skills with <strong>Course Echo</strong>.
      </p>

      <p>
        🚀 Java | Angular | JavaScript | Flask | Hostinger | AWS | MySQL | PHP | Management | Social Media | Digital Marketing | Arts | Medical | Finance | 10th & 12th Grades 🚀
      </p>

      <h2>Follow Us for More Updates!</h2>
      <p>
        #Coding #Programming #Management #SocialMedia #DigitalMarketing #Arts #MassCommunication #Finance #MedicalCourses #FreelanceTrainers #RemoteLearning #courseecho
      </p>
    </div>
  );
};

export default About;
