import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { UserModel } from '../profile/model/User.model';
import { UserInterface, UserProfileInterface } from '../../data/userData';
import { Author, WorkHistory } from '../../data/authorData';
import { addAuthorWorkHistory, addCounselorCollege, editCounselorCollegeById, getAuthorsByToken, getCounselorByToken, getDashboardAuthors, updateAuthorWorkHistory, uploadSocialImage } from '../../services/DashboardService';
import { getAuthorsById } from '../../services/AuthorsService';
import { College, MappedCollege } from '../../data/campusData';
import { campusService } from '../../services/CampusService';

const AddEditCounselorCollegePage: React.FC = () => {


    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<UserProfileInterface[]>([]);
    const [users, setUsers] = useState<College[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { id, counselor_id } = useParams<{ id: string, counselor_id: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection
    const [workHistory, setWorkHistory] = useState<MappedCollege>({
        id: '',
        counselorId: '',
        collegeId: '',
        title: "",
        description: "",
        city: ""
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const workHistorysResponse = await getCounselorByToken();
                setAuthors(workHistorysResponse);
                const userData = await campusService.getColleges();
                setUsers(userData);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

    }, [id]);

    // Watching authors for changes
    useEffect(() => {
        if (authors.length > 0 && authors[0]?.counselor?.id && id && counselor_id) {
            const payload = authors[0].counselor?.colleges?.find((v) => { return v.id === id })
            if (payload)
                setWorkHistory({ counselorId: authors[0]?.counselor?.id || "", collegeId: payload.collegeId,id:payload.id, title: "", description: "", city: "" });
            setLoading(false);
            setIsEditing(true)
        }
        else {
            setWorkHistory({ counselorId: authors[0]?.counselor?.id || "", collegeId: "", title: "", description: "", city: "" });
            setIsEditing(false)
        }
    }, [authors]); // This effect triggers when 'authors' changes



    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWorkHistory((prevCourse: MappedCollege) => ({ ...prevCourse, [name]: value }));
    };
    const handleBadgeTextChange = (badge: string) => {
        setWorkHistory((prevCourse) => ({ ...prevCourse, isCurrent: badge }));
    };

    const handleEditorChange = (value: string) => {
        setWorkHistory((prevCourse: MappedCollege) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setWorkHistory((prevCourse: MappedCollege) => ({ ...prevCourse, description: value }));
    };

    const handleSaveWorkHistory = async () => {
        const validateWorkHistory = (workHistory: MappedCollege): string[] => {
            const errors: string[] = [];
            if (!workHistory.counselorId) errors.push('counselor is required.');
            if (!workHistory.collegeId) errors.push('College is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateWorkHistory(workHistory);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {


            if (isEditing) {
                let payload = { ...workHistory }
                delete payload['title'];
                delete payload['description'];
                delete payload["city"];
                await editCounselorCollegeById(payload); // Call the update service

            } else {
                let payload = { ...workHistory }
                delete payload['title'];
                delete payload['description'];
                delete payload["city"];
                await addCounselorCollege(payload); // Call the add service
            }
            if (localStorage.getItem("defaultClaim") === "SUPERADMIN") {
                navigate('/dashboard/counselors');
            }
            else {
                navigate('/profile')
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setWorkHistory((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handleStatusChange = (status: string) => {
        setWorkHistory((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit WorkHistory' : 'Add New WorkHistory'} - ${localStorage.getItem('email')} - WorkHistory Echo`}
                description="Dashboard to add/edit workHistorys"
                keywords="workHistory echo dashboard,Dashboard to add/edit workHistorys"
                author="WorkHistory Echo"
            />
            {localStorage.getItem("defaultClaim") === "SUPERADMIN" ? (
                <Breadcrumb className='p-1'>
                    <Breadcrumb.Item>
                        <Link to="/"> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard"> Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard/counselors"> College</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit Counselor College' : 'Add Counselor College'}</Breadcrumb.Item>
                </Breadcrumb>) : (null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6} xs={12} className='mb-5'>
                        <Form.Group controlId="formUser">
                            <Form.Label>Map College</Form.Label>
                            <Form.Control
                                as="select"
                                name="collegeId"
                                value={workHistory.collegeId}
                                onChange={handleChange}
                            >
                                <option value="">Select college</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.title} - ({user.city})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveWorkHistory} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditCounselorCollegePage;
