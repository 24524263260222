import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { useAuth } from '../../context/AuthContext';
import './Login.scss'; // Import custom CSS if needed
import { ENDPOINT } from '../../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
import GoogleLoginComponent from '../../components/GoogleLoginComponent';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from AuthContext
  if (localStorage.getItem('token'))
  {
    switch (localStorage.getItem('defaultClaim')) {
      case 'MANAGER':
        navigate('/dashboard')
        break;
      case 'SUPERADMIN':
        navigate('/dashboard')
        break;
      case 'MENTOR':
        navigate('/dashboard')
        break;
      case 'STUDENT':
        navigate('/dashboard')
        break;
      default:
        navigate('/dashboard')
    }
  }
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [loading, setLoading] = useState(false); // State to handle loading
  const [error, setError] = useState<string | null>(null); // State to handle errors

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Show loading state
    setError(null); // Reset error state

    try {
      // API call to login (replace URL with your actual API endpoint)
      const response = await axios.post(`${ENDPOINT}/users/login`, credentials, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      // Handle API response
      if (response.status === 200) {
        login(response.data.user.email, response.data.token, response.data.defaultClaim); // Simulate login action
        switch (response.data.defaultClaim) {
          case 'MANAGER':
            navigate('/dashboard')
            break;
          case 'SUPERADMIN':
            navigate('/dashboard')
            break;
          case 'MENTOR':
            navigate('/dashboard')
            break;
          case 'STUDENT':
            navigate('/dashboard')
            break;
          default:
            navigate('/dashboard')
        }
        window.location.reload();
      } else {
        setError('Invalid credentials. Please try again.'); // Handle invalid credentials
      }
    } catch (err: any) {
      setError('Login fail'); // Handle any other errors
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container loginP">
      <Helmet>
        <title>Login - Course Echo | Access Your Account</title>
        <meta
          name="description"
          content="Login to your Course Echo account to access your learning materials and track your progress. Enter your email and password to get started."
        />
        <meta
          name="keywords"
          content="Course Echo, login, user login, access account, online learning, educational platform"
        />
        <meta name="author" content="Course Echo Team" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Login - Course Echo | Access Your Account" />
        <meta
          property="og:description"
          content="Login to your Course Echo account to access learning materials, track your progress, and manage your courses."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://courseecho.com/login" />
        <meta
          property="og:image"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta property="og:image:alt" content="Course Echo Logo" />

        {/* Twitter card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - Course Echo | Access Your Account" />
        <meta
          name="twitter:description"
          content="Login to your Course Echo account to access learning materials and track your progress."
        />
        <meta
          name="twitter:image"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta name="twitter:image:alt" content="Course Echo Logo" />
      </Helmet>
      <div className="row justify-content-center logins">
        <div className="col-md-6 col-lg-6 col-sm-12 login-bg shadow">
          <div className='bg-text-container'>
            <h2 className='text-center'>Turn your ideas into reality</h2>
            <p className='text-center'>Start for free and get attractive offers from the community</p>

          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12 no-padding">
          <div className=" shadow min-height">
            <div className=" formDiv">
              <h2 className="card-title text-center whiteScreenColor pt-5">Welcome Back!</h2>
              <p className="text-center whiteScreenColor subHeading">
                Ready to continue your learning journey? Log in to unlock your personalized dashboard, courses, and progress!
              </p>

              <form onSubmit={handleSubmit} className='login-form'>
                <div className="mb-1">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={credentials.email}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-1 input-group">
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    name="password"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                  <span
                    className="input-group-text bg-white border-0 passIcon"
                    onClick={togglePasswordVisibility}
                  >
                    <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
                {error && <div className="alert alert-danger">{error}</div>} {/* Display error if any */}
                <button type="submit" className="btn btn-primary w-100 loginbtn navBtn" disabled={loading}>
                  {loading ? 'Logging in...' : 'Login'}
                </button>
              </form>

              <div className='text text-center bottomText mt-5'>
                <p> <span className="text-primary">Not registered Yet? </span>
                  <Link to='/signup' className='secandoryText noUnderLine'>Create an account</Link>

                </p>
                <p>
                  <GoogleLoginComponent isLoginScreen={true} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
