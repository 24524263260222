import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './AuthorDetails.scss';
import { UserProfileInterface } from '../data/userData';
import { getCounselorById } from '../services/DashboardService';
import notify from '../services/notificationService';

const CounselorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [author, setAuthor] = useState<UserProfileInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [mentorBanner, setMentorBanner] = useState<string>('');
    const navigate = useNavigate();

  // Function to select random banner image
  const getRandomBannerImage = () => {
    const banners = ['https://images.courseecho.com/backgrounds/contact-banner-1.webp', 'https://images.courseecho.com/backgrounds/contact-banner.webp'];
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  };
  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        setLoading(true);
        const data = await getCounselorById(id || '');
        setMentorBanner(getRandomBannerImage());
        setAuthor(data[0]); // Assuming the response is an array and you're interested in the first item
        setError(null);
      } catch (err) {
        console.error('Error fetching details:', err);
        setError('Failed to load details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchAuthorDetails();
    }
  }, [id]);
  const handleFollow = () => {
    if (!localStorage.getItem('token')) {
        notify.info('Please login first', {
            position: 'top-right',
            autoClose: 2000,
        });
    }
}

  if (loading) {
    return <div className="text-center mt-5">Loading counselor details...</div>;
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5">
        {error}
      </div>
    );
  }

  if (!author) {
    return (
      <div className="text-center mt-5">
        <p>No author details found.</p>
        <Link to="/" className="navBtn mt-4">
          Back to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="container authorDetailsPage">
      <Helmet>
        <title>{author.counselor?.name} - Campus Counselor Details | Course Echo</title>
        <meta name="keywords" content={`${author.counselor?.seoKeyword}`} />
        <meta name="description" content={`Learn more about ${author.counselor?.name}, including their colleges, shools and book session to get guidance.`} />
        <meta property="og:title" content={author.counselor?.name} />
        <meta property="og:description" content={`Explore the colleges and schools of ${author.counselor?.name}.`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="row">
        <div className="col-sm-12">
          <h1 className="text-center mb-4">{author.counselor?.name}</h1>
          <div className="card mx-auto d-flex customd-flex  align-items-center mb-4 authorImgCard"
            style={{
              backgroundImage: `url(${mentorBanner})`,
              backgroundRepeat: 'no-repeat', // Prevents repetition
              backgroundSize: 'cover',      // Ensures the image covers the whole element
            }}
          >
            <img src="https://images.courseecho.com/authors/author-avtar.webp" className="card-img-top img-fluid shadow"
              style={{ width: '200px' }} alt={author.counselor?.name} />
            <div className="card-body shadow">
              <div className='card-text p-2' dangerouslySetInnerHTML={{ __html: author?.counselor?.description || '' }} />

          
            </div>
            <div className='row'>
                                            <button className="btn btn-outline-primary me-2 viewBtn m-2" onClick={() => handleFollow()}>Follow</button>
                                            <button
                                                className="btn btn-primary applyBtn m-2"
                                                onClick={() => navigate(`/book-session/${author?.counselor?.id}`)}
                                            >
                                                Book Video Session
                                            </button>
                                        </div>
          </div>
        </div>
        <div className="col-sm-12">

          {author.counselor?.colleges && author.counselor?.colleges.length > 0 && (
            <>
              <h2 className='detail-h1'>Colleges ({author.counselor?.colleges.length}) </h2>
              <ul className="list-group mb-4">
                {author.counselor?.colleges.map((work) => (
                  <li key={work.id} className="list-group-item">
                    <h3>
                      <a href={work.link} className='text-primary' title={`Visit to ${work.title}`} target="_blank" rel="noopener noreferrer">
                        {work.title}
                      </a>
                    </h3>
                    {work.description && (
                      <div dangerouslySetInnerHTML={{ __html: work?.description || '' }} />
                    )}
                    {/* Styling for "Currently working" */}

                    <div className='linkIcon mt-2'>
                      {work.link ?
                        (<Link to={`${work.link}`} title='click to visit website' className='text-primary'><i className='fa fa-globe'></i> {work.link} </Link>) : (null)} &nbsp;
                      {work.mobile ?
                        (
                          <Link to={`tel:${work.mobile}`} title='click to call' className='text-primary'><i className='fa fa-phone-square'></i> {work.mobile}</Link>) : (null)} &nbsp;
                      {work.email ?
                        (
                          <Link to={`mailto:${work.email}`} title='click to send mail' className='text-success'><i className='fa fa-envelope'></i> {work.email}</Link>) : (null)}

                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="col-sm-12">


          {author.counselor?.schools && author.counselor?.schools.length > 0 && (
            <>
              <h3 className='detail-h1'>Schools ({author.counselor?.schools.length})</h3>
              <ul className="list-group mb-4">
                {author.counselor?.schools.map((link) => (
                  <li key={link.id} className="list-group-item d-flex customd-flex  align-items-center">
                    {/* Add icon for social link */}
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      {link.title}
                    </a>
                    {link.description && (
                      <div dangerouslySetInnerHTML={{ __html: link?.description || '' }} />
                    )}

                    <div className='linkIcon mt-2'>
                      {link.link ?
                        (<Link to={`${link.link}`} title='click to visit website' className='text-primary'><i className='fa fa-globe'></i> {link.link} </Link>) : (null)} &nbsp;
                      {link.mobile ?
                        (
                          <Link to={`tel:${link.mobile}`} title='click to call' className='text-primary'><i className='fa fa-phone-square'></i> {link.mobile}</Link>) : (null)} &nbsp;
                      {link.email ?
                        (
                          <Link to={`mailto:${link.email}`} title='click to send mail' className='text-success'><i className='fa fa-envelope'></i> {link.email}</Link>) : (null)}

                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="col-sm-12">

          <Link to="/campus-counselor" className="navBtn mt-4 p-2">
            Back to search
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CounselorDetails;
