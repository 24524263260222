import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { UserProfileInterface } from '../../data/userData';
import { addCounselorSchool,  editCounselorSchoolById, getCounselorByToken } from '../../services/DashboardService';
import { campusService } from '../../services/CampusService';
import { MappedSchool, School } from '../../data/campusData';

const AddEditCounselorSchoolPage: React.FC = () => {


    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<UserProfileInterface[]>([]);
    const [users, setUsers] = useState<School[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { id, counselor_id } = useParams<{ id: string, counselor_id: string }>();
    const [workHistory, setWorkHistory] = useState<MappedSchool>({
        id: '',
        counselorId: '',
        schoolId: '',
        title: "",
        description: "",
        city: ""
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const workHistorysResponse = await getCounselorByToken();
                setAuthors(workHistorysResponse);
                const userData = await campusService.getSchools();
                setUsers(userData);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

    }, [id]);

    // Watching authors for changes
    useEffect(() => {
        if (authors.length > 0 && authors[0]?.counselor?.id && id && counselor_id) {
            const payload = authors[0].counselor?.schools?.find((v) => { return v.id === id })
            if (payload)
                setWorkHistory({ counselorId: authors[0]?.counselor?.id || "", schoolId: payload.schoolId, id: payload.id, title: "", description: "", city: "" });
            setLoading(false);
            setIsEditing(true)
        }
        else {
            setWorkHistory({ counselorId: authors[0]?.counselor?.id || "", schoolId: "", title: "", description: "", city: "" });
            setIsEditing(false)
        }
    }, [authors]); // This effect triggers when 'authors' changes



    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWorkHistory((prevCourse: MappedSchool) => ({ ...prevCourse, [name]: value }));
    };
    const handleBadgeTextChange = (badge: string) => {
        setWorkHistory((prevCourse) => ({ ...prevCourse, isCurrent: badge }));
    };

    const handleEditorChange = (value: string) => {
        setWorkHistory((prevCourse: MappedSchool) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setWorkHistory((prevCourse: MappedSchool) => ({ ...prevCourse, description: value }));
    };

    const handleSaveWorkHistory = async () => {
        const validateWorkHistory = (workHistory: MappedSchool): string[] => {
            const errors: string[] = [];
            if (!workHistory.counselorId) errors.push('counselor is required.');
            if (!workHistory.schoolId) errors.push('School is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateWorkHistory(workHistory);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {


            if (isEditing) {
                let payload = { ...workHistory }
                delete payload['title'];
                delete payload['description'];
                delete payload["city"];
                await editCounselorSchoolById(payload); // Call the update service

            } else {
                let payload = { ...workHistory }
                delete payload['title'];
                delete payload['description'];
                delete payload["city"];
                await addCounselorSchool(payload); // Call the add service
            }
            if (localStorage.getItem("defaultClaim") === "SUPERADMIN") {
                navigate('/dashboard/counselors');
            }
            else {
                navigate('/profile')
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit School' : 'Add New School'} - ${localStorage.getItem('email')} - Course Echo`}
                description="Dashboard to add/edit Schools"
                keywords="School echo dashboard,Dashboard to add/edit Schools"
                author="WorkHistory Echo"
            />
            {localStorage.getItem("defaultClaim") === "SUPERADMIN" ? (
                <Breadcrumb className='p-1'>
                    <Breadcrumb.Item>
                        <Link to="/"> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard"> Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard/counselors"> School</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit Counselor School' : 'Add Counselor School'}</Breadcrumb.Item>
                </Breadcrumb>) : (null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6} xs={12} className='mb-5'>
                        <Form.Group controlId="formUser">
                            <Form.Label>Map School</Form.Label>
                            <Form.Control
                                as="select"
                                name="schoolId"
                                value={workHistory.schoolId}
                                onChange={handleChange}
                            >
                                <option value="">Select school</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.title} - ({user.city})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveWorkHistory} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditCounselorSchoolPage;
