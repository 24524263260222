import { Course } from "./coursesData";

export interface Tutorial {
    id: number;
    title: string;
    description: string;
    courseId: string;
    createdDate: string;
    updatedDate: string;
    externalLink?: string;
    imageLink?: string;
    parentId?: number;
    Courses?:Course;
    total:number; // total list
    seoKeywords?: string; // New property for SEO keywords
  }

  export const tutorials: Tutorial[] = [];
