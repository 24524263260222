import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InterviewModel, InterviewQuestionModel } from '../../data/interviewData';
import './InterviewDetails.scss';
import { getInterviewById } from '../../services/InterviewService';
import MetaHelmet from '../../components/MetaHelmet';
import { Breadcrumb } from 'react-bootstrap';

const InterviewDetails: React.FC = () => {
    const { interviewDisplayId, id } = useParams<{ interviewDisplayId: string, id: string }>();
    const [interview, setInterview] = useState<InterviewModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchInterviewDetails = async () => {
            try {
                const response = await getInterviewById(id!);
                setInterview(response.length > 0 ? response[0] : null);
            } catch (err) {
                setError('Error fetching interview details');
            } finally {
                setLoading(false);
            }
        };

        fetchInterviewDetails();
    }, [id]);

    // Schema and meta data generation for the current interview
    const generateSchemaData = () => ({
        "@context": "https://schema.org",
        "@type": "Interview",
        "name": interview?.title,
        "url": `https://courseecho.com/interview-details/${interview?.interviewDisplayId}/${interview?.id}`,
        "description": interview?.description,
        "author": {
            "@type": "Person",
            "name": interview?.author?.name || "Course Echo Team",  // Replace with the author's name if available
        },
        "image": interview?.image,
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://courseecho.com/interview-details/${interview?.interviewDisplayId}/${interview?.id}`
        },
        "question": interview?.interview_questions?.map((question) => ({
            "@type": "Question",
            "name": question.question_text,
            "text": question.answer_text,
            "dateCreated": question.created_at
        })),
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://courseecho.com/search?query={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const pageTitle = interview?.title || "Interview Details - Course Echo";
    const pageDescription = interview?.description || "Detailed interview discussion from Course Echo.";

    return (
        <div className="container interviewDetailsPage">
            <MetaHelmet
                title={pageTitle}
                description={pageDescription}
                keywords={interview?.seoKeywords}
                author="Course Echo Team"
                ogTitle={pageTitle}
                ogDescription={pageDescription}
                ogImage={interview?.image || "https://images.courseecho.com/dark-logo.webp"}
                ogUrl={`https://courseecho.com/interview-details/${interview?.interviewDisplayId}/${interview?.id}`}
                twitterTitle={pageTitle}
                twitterDescription={pageDescription}
                twitterImage={interview?.image || "https://images.courseecho.com/dark-logo.webp"}
                twitterCreator="@CourseEcho"
                schemaMarkup={generateSchemaData()}
            />

            <Breadcrumb className='p-1 mb-2'>
                <Breadcrumb.Item>
                    <Link to="/"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/interviews/`}> Interviews</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{interview?.title}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="card p-3">
                <h1 className="text-center my-4 detail-h1">{interview?.title}</h1>
                <img src={interview?.image} alt={interview?.title} className="img-fluid mb-4 interviewDetailsImg" />


                {/* Interview Description */}
                <div dangerouslySetInnerHTML={{ __html: interview?.description || '' }} />

                <h3 className="mt-5 detail-h1">Questions and Answers</h3>
                {interview?.interview_questions?.map((question: InterviewQuestionModel, index: number) => (
                    <div key={question.id} className="interview-question mb-4">
                        <h4 className='text-primary'>
                            <Link to={`/interview-question/${encodeURIComponent(question.questionDisplayId)}/${question.interview_id}/${question.id}`}>
                                {index + 1}.Question:
                            </Link>
                        </h4>
                        <div dangerouslySetInnerHTML={{ __html: question?.question_text || '' }} />
                        <h5 className="mt-3">Answer:</h5>
                        <div dangerouslySetInnerHTML={{ __html: question.answer_text }} />
                    </div>
                ))}

                <h5 className="mt-3 detail-h1">Conclusion:</h5>
                <div dangerouslySetInnerHTML={{ __html: interview?.conclusion || '' }} />

                {/* Author Details */}
                {interview?.author && (
                    <div className="author-details my-4">
                        <h5 className='detail-h1'>Author: {interview.author.name}</h5>
                        {interview.author.description &&  <div  dangerouslySetInnerHTML={{ __html: interview.author?.description || '' }}/>
                    }
                        {interview.author.image && (
                            <img
                                src={interview.author.image}
                                alt={interview.author.name}
                                className="author-profile-image"
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                            />
                        )}
                        <Link
                            className="m-5 text-primary"
                            to={`/mentor-details/${interview.author.name}/${interview.author.id}`}
                        >View Details
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InterviewDetails;
