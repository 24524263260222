import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";  // Add Row and Col from React Bootstrap
import { CourseQA } from "../data/coursesData";
import QAItem from "./QAItem";
import { addCourseQA, getCourseQAById } from "../services/CourseService";
import notify from "../services/notificationService";
import { useParams } from "react-router-dom";
import CourseCard from "./CourseCard";
import { Helmet } from "react-helmet-async";

const QADetailPage: React.FC = () => {
    const { id, course_id } = useParams<{ id: string, course_id: string }>();
    const [questions, setQuestions] = useState<CourseQA[]>([]);
    const [newQuestion, setNewQuestion] = useState<{
        id: string;
        title: string;
        description: string;
        status: string;
        courseId: string;
        userId: string;
    }>({
        id: "",
        title: "",
        description: "",
        status: "approved",
        userId: '',
        courseId: course_id ?? "",
    });

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await getCourseQAById(id ?? "");
            setQuestions(response);
        };
        fetchQuestions();
    }, [id]);

    const handleNewQuestionSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!localStorage.getItem('token')) {
            notify.info('Info , Please login first!', {
                position: 'top-right',
                autoClose: 2000,
            });
            return false;
        }

        const response = await addCourseQA(newQuestion);
        if (response) {
            const question = await response;
            setQuestions((prev) => [...prev, { ...question, replies: [] }]);
            setNewQuestion({
                id: "",
                title: "",
                description: "",
                status: "approved",
                courseId: course_id ?? "",
                userId: ''
            });
        } else {
            notify.error('Error , Error while adding QA. Try after sometime!', {
                position: 'top-right',
                autoClose: 2000,
            });
        }
    };

    const handleAddToCart = () => {
        // Add to cart logic here
    };

    return (
        <Container className="mt-5 pt-3">
            <h2 className="my-4 detail-h1">Questions & Answers ({questions.length})</h2>
            {questions.map((qa) => (
                <div key={qa.id}>
                    

                     <Helmet>
                              <title>QA - {qa.title} | Course Echo</title>
                              <meta name="description" content={`QA question and answer at course echo`} />
                              <meta name="keywords" content={qa.title || 'q&a, ask question at course echo'} />
                              <meta name="author" content="Course Echo" />
                            </Helmet>

                    {qa?.course && (
                        <div className="button-container d-flex customd-flex  justify-content-center mb-4">
                            <CourseCard course={qa.course} onAddToCart={handleAddToCart} />
                        </div>
                    )}

                    <QAItem key={qa.id} question={qa} />
                </div>
            ))}
        </Container>
    );
};

export default QADetailPage;
