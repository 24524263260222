import React from 'react';
import { Helmet } from 'react-helmet-async';
import { MetaHelmetProps } from '../data/metaData';

const MetaHelmet: React.FC<MetaHelmetProps> = ({
  title,
  description,
  keywords,
  author,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterCardType = 'summary_large_image',
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterCreator,
  schemaMarkup
}) => {
  const schemaMarkupJson = JSON.stringify(schemaMarkup);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {author && <meta name="author" content={author} />}

      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content={twitterCardType} />
      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
      <script type="application/ld+json">{schemaMarkupJson}</script>
    </Helmet>
  );
};

export default MetaHelmet;
