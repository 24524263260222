import { Course } from "./coursesData";

// models.ts
export interface Author {
    id: string;
    name: string;
    description: string;
    image: string;
    backgroundImageLink?: string;
    WorkHistory?: WorkHistory[];
    SocialLink?: SocialLink[];
    course?:Course[];
    totalRevenue?:string;
    totalOrders?:string;
    keywords?:string;
    userId?:string;
  }
  
  export interface WorkHistory {
    id: string;
    companyName: string;
    image: string;
    isCurrent?: string;
    skills?: string;
    achivements?:string;
    webLink:string;
    authorId?:string;
    description?:string;
  }
  
  export interface SocialLink {
    id: string;
    title: string;
    description: string;
    link: string;
    image?: string;
    authorId?:string;
  }
  
  export const authorList: Author[] = [];
  