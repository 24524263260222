import React from 'react';
import './OfflineClass.scss';
import { Helmet } from 'react-helmet-async';
import { PROJECTNAME } from '../../utils/constants';

const OfflineClass: React.FC = () => {
  return (
    <div className="OfflineClass">
       <Helmet>
        <title>Offline Classes - Hands-on Learning Experience</title>
        <meta name="description" content="Join our offline classes for personalized, hands-on learning in a collaborative environment. Enroll now for an immersive experience." />
        <meta name="keywords" content="offline classes, hands-on learning, interactive education, learning experience" />
        <meta name="author" content={PROJECTNAME} />

        {/* Open Graph (OG) meta tags */}
        <meta property="og:title" content="Offline Classes - Hands-on Learning Experience" />
        <meta property="og:description" content="Join our offline classes for personalized, hands-on learning in a collaborative environment. Enroll now for an immersive experience." />
        <meta property="og:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://courseecho.com/services/offline-class" /> {/* Replace with the specific page URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CourseEcho" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Offline Classes - Hands-on Learning Experience" />
        <meta name="twitter:description" content="Join our offline classes for personalized, hands-on learning in a collaborative environment. Enroll now for an immersive experience." />
        <meta name="twitter:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
      </Helmet>
      <h1>Offline Classes</h1>
      <p>
        Our offline classes are conducted in state-of-the-art facilities with hands-on learning opportunities. 
        Interact directly with our instructors and other learners in a collaborative environment.
      </p>
      <h2>Key Highlights:</h2>
      <ul>
        <li>Personalized attention in small groups</li>
        <li>Hands-on practice and practical examples</li>
        <li>Classroom activities to reinforce learning</li>
        <li>Located in multiple cities for easy accessibility</li>
      </ul>
      <p>
        Enroll now to enjoy an immersive learning experience. Contact us for details on schedules and locations.
      </p>
    </div>
  );
};

export default OfflineClass;
