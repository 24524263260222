// src/pages/Mentor.tsx
import React, { Suspense } from 'react';
import './Mentor.scss'; // Optional: Add custom styles here
import { APPLYNOW } from '../utils/Endpoints';
import { PROJECTNAME } from '../utils/constants';
import MetaHelmet from '../components/MetaHelmet';
import { Link } from 'react-router-dom';

const MentorEarningCalculator = React.lazy(() => import('../components/MentorEarningCalculator'));
const HowItWorks = React.lazy(() => import('../components/HowItWorks'));

const MentorEarningCalculatorPage: React.FC = () => {
  // Define schema markup or fetch dynamically if needed
  const generateSchemaData = () => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Mentor Earning Calculator - Course Echo",
    "url": "https://courseecho.com/mentor-earning-calculator",
    "description": "Calculate your potential earnings as a mentor on Course Echo. Inspire learners, earn a steady income, and access a thriving tech community.",
    "author": {
      "@type": "Organization",
      "name": "Course Echo",
      "url": "https://courseecho.com"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://courseecho.com/mentor-earning-calculator?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  });

  return (
    <div className="Mentor">
      <MetaHelmet
        title="Mentor Earning Calculator | Maximize Your Impact with Course Echo"
        description="Discover how much you can earn as a mentor on Course Echo. Join today to inspire learners, earn up to 30k per month, and access exclusive tech content."
        keywords="Mentor earnings, mentorship opportunities, Course Echo, tech community, earning potential, programming tutorials, coding education"
        author="Course Echo Team"
        ogTitle="Mentor Earning Calculator | Maximize Your Impact with Course Echo"
        ogDescription="Use the Mentor Earning Calculator to see your earning potential. Join Course Echo to mentor learners and shape future tech professionals."
        ogImage="https://images.courseecho.com/mentor/earning-calculator.webp"
        ogUrl="https://courseecho.com/mentor-earning-calculator"
        twitterTitle="Mentor Earning Calculator | Unlock Your Potential with Course Echo"
        twitterDescription="Calculate your mentorship earning potential with Course Echo. Join us to inspire learners and earn rewards in the tech industry."
        twitterImage="https://images.courseecho.com/mentor/earning-calculator.webp"
        twitterCreator="@CourseEcho"
        schemaMarkup={generateSchemaData()}
      />

      <div className="Mentor container">
        <div className="row">
          <div className="col-12">
            <h1 className='mentor_header'>
              Empower as a Mentor, Shape Future Tech Leaders
            </h1>
            <p className="text-center">
              Inspire learners, share your expertise, and maximize your earning potential.
            </p>
          </div>
        </div>
      </div>

      <Suspense fallback={<div className='container'>Loading Calculator...</div>}>
        <MentorEarningCalculator />
      </Suspense>
      <Suspense fallback={<div className='container'>Loading Mentor Timeline...</div>}>
        <HowItWorks />
      </Suspense>
    </div>
  );
};

export default MentorEarningCalculatorPage;
