import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { getTutorialsById } from '../services/TutorialsService';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Tutorial } from '../data/tutorialsData';
import './TutorialsDetails.scss';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

const TutorialsDetails: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const [loading, setLoading] = useState(true);
  const [groupedTutorials, setGroupedTutorials] = useState<
    Record<number, { parent: Tutorial; children: Tutorial[] }>
  >({});
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(null);

  useEffect(() => {
    const fetchTutorialsDetails = async () => {
      if (!courseId) return;

      setLoading(true);
      const tutorialsData = await getTutorialsById(courseId);

      const parentTutorials = tutorialsData.filter((tutorial) => tutorial.parentId === null);
      const childTutorials = tutorialsData.filter((tutorial) => tutorial.parentId !== null);

      const grouped = parentTutorials.reduce((acc, parent) => {
        acc[parent.id] = {
          parent,
          children: childTutorials.filter((child) => child.parentId === parent.id),
        };
        return acc;
      }, {} as Record<number, { parent: Tutorial; children: Tutorial[] }>);

      setGroupedTutorials(grouped);

      const firstGroupId = Object.keys(grouped)[0];
      if (firstGroupId) {
        const firstGroup = grouped[Number(firstGroupId)];
        setActiveKey(firstGroupId);
        setSelectedTutorial(firstGroup.children[0] || null);
      }

      setLoading(false);
    };

    fetchTutorialsDetails();
  }, [courseId]);

  const handleAccordionSelect = (key: AccordionEventKey) => {
    if (Array.isArray(key)) {
      setActiveKey(key[0] ?? null); // Use the first active key
    } else {
      setActiveKey(key ?? null); // Handle string or null
    }
  };

  const getNextTutorial = () => {
    if (!selectedTutorial) return;

    const currentGroup = groupedTutorials[selectedTutorial.parentId!];
    const currentIndex = currentGroup.children.findIndex((t) => t.id === selectedTutorial.id);
    const nextIndex = currentIndex + 1;

    if (nextIndex < currentGroup.children.length) {
      setSelectedTutorial(currentGroup.children[nextIndex]);
    } else {
      const nextGroupId = Object.keys(groupedTutorials)
        .map(Number)
        .find((groupId) => groupId > selectedTutorial.parentId!);

      if (nextGroupId) {
        setActiveKey(String(nextGroupId));
        const nextGroup = groupedTutorials[nextGroupId];
        setSelectedTutorial(nextGroup.children[0]);
      }
    }
  };

  const getPreviousTutorial = () => {
    if (!selectedTutorial) return;

    const currentGroup = groupedTutorials[selectedTutorial.parentId!];
    const currentIndex = currentGroup.children.findIndex((t) => t.id === selectedTutorial.id);
    const prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      setSelectedTutorial(currentGroup.children[prevIndex]);
    } else {
      const prevGroupId = Object.keys(groupedTutorials)
        .map(Number)
        .reverse()
        .find((groupId) => groupId < selectedTutorial.parentId!);

      if (prevGroupId) {
        setActiveKey(String(prevGroupId));
        const prevGroup = groupedTutorials[prevGroupId];
        setSelectedTutorial(prevGroup.children[prevGroup.children.length - 1]);
      }
    }
  };

  if (loading) {
    return (
      <div className="tutorials-details-container">
        <p className="text-center">Loading details...</p>
      </div>
    );
  }

  return (
    <div className="tutorials-details-container">
      <Helmet>
        <title>{selectedTutorial?.title} - Tutorials | Course Echo</title>
        <meta name="description" content={selectedTutorial?.description} />
        <meta name="keywords" content={selectedTutorial?.seoKeywords || 'Tutorials, online learning, education'} />
        <meta property="og:title" content={selectedTutorial?.title} />
        <meta property="og:description" content={selectedTutorial?.description} />
        <meta property="og:image" content={selectedTutorial?.imageLink} />
      </Helmet>

      <div className="row">
        <div className="col-md-4">
          <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
            {Object.entries(groupedTutorials).map(([parentId, group]) => (
              <Accordion.Item eventKey={parentId} key={parentId}>
                <Accordion.Header>
                  {group.parent.title} ({group.children.length})
                </Accordion.Header>
                <Accordion.Body>
                  {group.children.map((tutorial) => (
                    <div
                      key={tutorial.id}
                      onClick={() => setSelectedTutorial(tutorial)}
                      className={selectedTutorial?.id === tutorial.id ? 'selected-tutorial' : ''}
                    >
                      <i className='fa fa-dot-circle-o'></i> {tutorial.title}
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div> 

        <div className="col-md-8">
          <div className="button-container">
            <Button
              variant="secondary"
              onClick={getPreviousTutorial}
              disabled={!selectedTutorial}
              className="mr-2"
            >
              Previous
            </Button>
            <Button variant="primary" onClick={getNextTutorial} disabled={!selectedTutorial}>
              Next
            </Button>
          </div>
          <div className="tutorial-content">
            {selectedTutorial ? (
              <>
                <h3>{selectedTutorial.title}</h3>
                <p>{selectedTutorial.description}</p>
              </>
            ) : (
              <p>Please select a tutorial to view details.</p>
            )}
            <div className="button-container">
              <Button
                variant="secondary"
                onClick={getPreviousTutorial}
                disabled={!selectedTutorial}
                className="mr-2"
              >
                Previous
              </Button>
              <Button variant="primary" onClick={getNextTutorial} disabled={!selectedTutorial}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialsDetails;
