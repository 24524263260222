import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

interface CampusCounselorIntroProps {
    isImported: boolean;
}

const CampusCounselorIntro: React.FC<CampusCounselorIntroProps> = ({ isImported }) => {
    return (
        <div className="p-6 course-details-container campusIntro container text-center mb-5">
            {!isImported ? (
                <Helmet>
                    <title>Become a Campus Counselor - Empower Students | Course Echo</title>
                    <meta
                        name="description"
                        content="Join Course Echo as a Campus Counselor to guide students, earn rewards, and enhance your leadership skills. Empower others while advancing your career."
                    />
                    <meta
                        name="keywords"
                        content="campus counselor, mentorship, guide students, leadership development, Course Echo, student career guidance, earn rewards"
                    />
                    <meta name="author" content="Course Echo Team" />
                   
                </Helmet>
            ) : null}

            <div>
                <header className="m-3">
                    <h2 className="how-it-works-heading">Become a <span className="highlight">Campus Counselor</span></h2>
                    <p className="mt-2"><span className="text-success">Empower</span>, <span className="text-success">mentor</span>, and <span className="text-danger">earn</span> while making a difference!</p>
                </header>

                <main className="p-6 space-y-6">
                    <div className="row m-5">
                        <div className="col-sm-12 col-md-6">
                    <section>
                        <h2>What is a <span className="highlight">Campus Counselor</span>?</h2>
                        <p className="text-center">
                            A Campus Counselor is a student representative who guides their peers, provides mentorship, and helps them make informed decisions about their careers.
                            <br /> As a counselor, you'll play a key role in empowering others while growing your skills.
                        </p>
                    </section>
                    </div>
                    <div className="col-sm-12 col-md-6">

                    <section>
                        <h2>Why Join as a <span className="highlight">Campus Counselor</span>?</h2>
                        <p>
                            Joining as a Campus Counselor allows you to positively impact your peers' lives, gain recognition, and build a strong foundation for your professional journey.
                        </p>
                    </section>
                    </div>
            </div>
                    <section>
                        <div className="session-options">
                            <div className="session-columns">
                                <ul title="Benefits" className="session-price">
                                    <li className="session-header">Benefits of Being a Campus Counselor</li>
                                    <li className="session-grey">✅ Make a positive difference in your peers' careers.</li>
                                    <li className="text-danger">💰 Earn rewards and recognition for your mentorship.</li>
                                    <li className="text-primary">🎯 Develop leadership and communication skills.</li>
                                    <li className="text-primary">💼 Expand your network and gain valuable experience.</li>
                                    <li className="session-grey">
                                        <Link to="/join-campus-counselor" className="btn bg-primary text-white navBtn">Join Now</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold">Video <span className="highlight">Sessions & Pricing</span></h2>
                        <p>
                            Book personalized video sessions with our <span className="highlight">Campus Counselor</span> to gain valuable insights and guidance:
                        </p>
                        <div className="mt-4">
                            <div className="session-options">
                                <div className="session-columns">
                                    <ul title="Video Meeting 30 minutes" className="session-price">
                                        <li className="session-header">Basic</li>
                                        <li className="session-grey"><i className="fa fa-clock-o text-secondary"></i> 30 Minutes</li>
                                        <li className="text-danger">₹250</li>
                                        <li className="text-primary">50 Realtime Chats</li>
                                    </ul>
                                </div>

                                <div className="session-columns">
                                    <ul title="Video Meeting 1 hour" className="session-price">
                                        <li className="session-header bg-success text-white">Premium</li>
                                        <li className="session-grey"><i className="fa fa-clock-o"></i> 1 Hour</li>
                                        <li className="text-danger">₹450</li>
                                        <li className="text-success">Unlimited Realtime Chats</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="text-center mb-5 mt-5">
                        <h2 className="m-3">Ready to <span className="highlight">Get Started?</span></h2>
                        <p className="text-gray-700 mt-2">
                            Join us today and become a part of a platform that empowers students to thrive. &nbsp;
                            <Link to="/join-campus-counselor" className="text-primary" aria-label="Sign Up Now">Sign Up</Link>
                        </p>
                    </section>
                </main>

                <footer>
                    <p>
                        Visit our platform to learn more: &nbsp;
                        <Link
                            to="/campus-counselor"
                            className="text-primary"
                            aria-label="Visit Campus Counselor Page"
                        >
                            Find Campus Counselor
                        </Link>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default CampusCounselorIntro;
