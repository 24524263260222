import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../utils/Keys';
import './GoogleLoginComponent.scss';
import { ENDPOINT } from '../utils/Endpoints';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { generatePassword } from '../utils/common';
import notify from '../services/notificationService';

interface GoogleLoginProps {
  isLoginScreen: boolean;
}

const GoogleLoginComponent: React.FC<GoogleLoginProps> = ({ isLoginScreen }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from AuthContext

  useEffect(() => {
    // Check if user is already logged in (e.g., from localStorage)
    const storedUser = localStorage.getItem('token')||null;
    if (storedUser) {
      // setUser(JSON.parse(storedUser));
      setIsLoggedIn(true);
    } else {
      // If not logged in, load Google Identity Services
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });

        // Automatically show the login prompt
        window.google.accounts.id.prompt();
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleCredentialResponse = async (response: any) => {
    console.log('Google Credential Response:', response);
    const decoded = decodeJWT(response.credential);

    if (decoded) {
      // const userData = {
      //   googleId: decoded.sub,
      //   name: decoded.name,
      //   email: decoded.email,
      //   imageUrl: decoded.picture,
      // };
      const payload = {
        defaultClaim:'STUDENT',
        name: decoded.name,
        email: decoded.email,
        mobile: decoded.mobile || '',
        password: generatePassword(),
      };

      // API call to login (replace URL with your actual API endpoint)
      const apiResponse = await axios.post(`${ENDPOINT}/users/google-verify-add`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Handle API response
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        notify.success('Success!', {
            position: 'top-right',
            autoClose: 2000,
          });
        login(apiResponse.data.data.email, apiResponse.data.token,apiResponse.data.defaultClaim); // Simulate login action
        switch (response.data.defaultClaim) {
          case 'MANAGER':
            navigate('/dashboard')
            break;
          case 'SUPERADMIN':
            navigate('/dashboard')
            break;
          case 'MENTOR':
            navigate('/dashboard')
            break;
          case 'STUDENT':
            navigate('/dashboard')
            break;
          default:
            navigate('/dashboard')
        }
        // Save user data in state and localStorage
        // setUser(userData);
        setIsLoggedIn(true);
        window.location.reload();
      }
      else
      {
        notify.error('Login faild, Try again!', {
            position: 'top-right',
            autoClose: 2000,
          });
      }
    }
  };

  const decodeJWT = (token: string) => {
    try {
      const [, payload] = token.split('.');
      return JSON.parse(atob(payload));
    } catch (error) {
      notify.error(`Invalid token:', ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
      return null;
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    setUser(null);
    setIsLoggedIn(false);
    // Optionally re-prompt for login
    window.google.accounts.id.prompt();
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      {isLoginScreen ? (
        <div>
          {isLoggedIn && user ? (
            <div>
              <h2>Welcome, {user.name}</h2>
              <img
                src={user.imageUrl}
                alt="Profile"
                width={100}
                height={100}
              />
              <p>Email: {user.email}</p>
              <button onClick={handleSignOut}>Sign Out</button>
            </div>
          ) : (
            <div className="googleBtn">
              {/* Explicit Sign-In Button */}
              <GoogleLogin
                onSuccess={handleCredentialResponse}
                onError={() => console.error('Login Failed')}
              />
            </div>
          )}
        </div>
      ) : (
        // If not in the login screen, you can return other content or null
        <></>
      )}
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
