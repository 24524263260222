import React from 'react';
import './BecomeMentorCard.scss'
import { Link } from 'react-router-dom';

const BecomeMentorCard: React.FC = () => {
  return (
    <div className="container py-5 bcm shadow rounded">
      <div className="row align-items-center bcm">
        {/* Image Section */}
        <div className="col-sm-12 col-md-4 text-center bcm">
          <img
            src="https://images.courseecho.com/backgrounds/mentor-img.webp"
            alt="Mentor"
            width={178} height={178}
            loading='lazy'
            className="img-fluid rounded-circle shadow mentorImg"
          />
        </div>

        {/* Title and Description */}
        <div className="col-sm-12 col-md-4 text-center bcm">
          <h2 className="fw-bold">Become a Mentor</h2>
          <p className='mentorP'>
            Share your knowledge, inspire others, and make an impact in their journey. Join our mentorship program and grow together.
          </p>
        </div>

        {/* Button Section */}
        <div className="col-sm-12 col-md-4 text-center bcm">
          <Link to='/mentor' className="btn btn-primary btn-lg viewBtn shadow">
            Join Now <sup className='fa fa-paper-plane-o'></sup>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BecomeMentorCard;
