import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import {  getDashboardUsers } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { UserInterface } from '../../data/userData';
import MetaHelmet from '../../components/MetaHelmet';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="m-3">
    <Row>
      <Col md={1} xs={12}>
      <span>Search: </span>

      </Col>
      <Col md={10} xs={12}>

        <input
          className="form-control mb-2"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
          placeholder="Search records..."
        />
      </Col>
      <Col md={1} xs={12}>

        {/* <Link to="/dashboard/add-mentor" title='Add new' className="navBtn p-2 mb-2">
          <i className='fa fa-plus-circle'></i>
        </Link> */}
      </Col>
    </Row>

  </div>
);

const MentorPage: React.FC = () => {
  const [Students, setStudents] = useState<UserInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardUsers('MENTOR');
        setStudents(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: number) => {
    axios
      .delete(`http://localhost/api/dashboard/courses/call-requests/${id}`)
      .then(() => {
        setStudents(Students.filter((request) => request.id !== id));
      })
      .catch((error) => {
        setError('Error deleting call request');
      });
  };


  const filteredData = Students.filter((request) => {
    return (
      request.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.mobile.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
      <MetaHelmet
      title={`Mentors - ${localStorage.getItem('email')} |  Course Echo`}
      description='Mentor page'
      ></MetaHelmet>
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
          <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>Mentors : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Default Claim</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>{request.name}</td>
                <td>{request.mobile}</td>
                <td>{request.email}</td>
                <td> <div className="badge-card-container">
                    <div
                      key={request.defaultClaim}
                      className={`badge-${request.defaultClaim.toLowerCase()} badge-card selected}`}
                    >
                      {request.defaultClaim}
                    </div>
                  </div></td>
                <td>{request.insertDate}</td>
                <td>{request.updateDate}</td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(request.id)}
                    className="me-2 navBtn"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </div>
  );
};

export default MentorPage;
