import axios from "axios";
import { ENDPOINT } from "../utils/Endpoints";
import { College, Counselor, CounselorFilter, Message, School, SessionBooking } from "../data/campusData";

class CampusService {
  private headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  // Fetch counselors
  async getCounselors(filters: CounselorFilter) {
    try {
      const response = await axios.post<Counselor[]>(
        `${ENDPOINT}/campus/search`,
        filters, // Send filters in the request body
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getColleges() {
    try {
      const response = await axios.get<College[]>(
        `${ENDPOINT}/dashboard/campus/colleges`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getSchools() {
    try {
      const response = await axios.get<School[]>(
        `${ENDPOINT}/dashboard/campus/schools`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getSessionBookedForMe() {
    try {
      const response = await axios.get<SessionBooking[]>(
        `${ENDPOINT}/dashboard/campus/session-booked-for-me`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getSessionBookedByMe() {
    try {
      const response = await axios.get<SessionBooking[]>(
        `${ENDPOINT}/dashboard/campus/session-booked-by-me`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getSessionBookedById(id:string) {
    try {
      const response = await axios.get<SessionBooking[]>(
        `${ENDPOINT}/dashboard/campus/session-booked-by-id/${id}`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async getSessionBookedMessageById(id:string) {
    try {
      const response = await axios.get<Message[]>(
        `${ENDPOINT}/dashboard/campus/session-booked-message-by-id/${id}`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async sendSessionBookedMessageById(id:string,data:Message) {
    try {
      const response = await axios.post<Message>(
        `${ENDPOINT}/dashboard/campus/session-booked-message`,
        data,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }
  async updateSessionBookedMessageById(id:string,data:Message) {
    try {
      const response = await axios.put<Message>(
        `${ENDPOINT}/dashboard/campus/session-booked-message/${id}`,
        data,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }

  async deleteSessionBookedMessageById(id:string,data:Message) {
    try {
      const response = await axios.delete<Message>(
        `${ENDPOINT}/dashboard/campus/session-booked-message/delete/${id}`,
        {
          headers: this.headers, // Attach headers properly
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching counselors:", error);
      throw error;
    }
  }
}

export const campusService = new CampusService();
