import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for ReactQuill
import { CourseQA, CourseQAReply } from "../data/coursesData";
import { Link } from "react-router-dom";
import './QAItem.scss';
import notify from "../services/notificationService";
import { addCourseQAReply } from "../services/CourseService";

interface QAItemProps {
    question: CourseQA;
}

const QAItem: React.FC<QAItemProps> = ({ question }) => {
    const [showReplies, setShowReplies] = useState(false);
    const [replies, setReplies] = useState<CourseQAReply[]>(question.CourseQAReply || []);
    const [newReply, setNewReply] = useState<{
        id: string;
        title: string;
        description: string;
        status: string;
        courseId: string;
        userId: string;
        QAId: string;
    }>({
        id: "",
        QAId: question.id,
        title: "",
        description: "",
        status: "approved",
        userId: '',
        courseId: question.courseId,
    });

    const handleReplySubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!localStorage.getItem('token')) {
            notify.info('Info , Please login first!', {
                position: 'top-right',
                autoClose: 2000,
            });
            return false;
        }

        const response = await addCourseQAReply(newReply)

        if (response) {
            const reply = await response
            setReplies((prev) => [...prev, reply]);
            setNewReply({
                id: "",
                QAId: question.id,
                title: "",
                description: "",
                status: "approved",
                userId: '',
                courseId: question.courseId,
            });
        } else {
            notify.error('Error , Error while adding Reply. Try aftersometime!', {
                position: 'top-right',
                autoClose: 2000,
            });
        }
    };

    return (
        <div className="qa-item">
            <div className="meta">
                <Link to={`/course-details/qa/${question.id}/${question.courseId}`}>{question.title}</Link>

                <div className="stats">
                    <span title="Click to upvote">
                        <i className="fa fa-thumbs-up"></i> {question.upvotes || 0}
                    </span>
                    <span onClick={() => setShowReplies((prev) => !prev)} title="Click to reply or see replies">
                        <i className="fa fa-comments"></i> {replies.length || 0}
                    </span>
                </div>

            </div>


            <p dangerouslySetInnerHTML={{ __html: question.description }} />
            <div className="details">
                <span>Asked by:&nbsp;<i>{question.user?.name}</i></span>
                <span>Date:&nbsp;<i>{question.dateAdded}</i></span>
            </div>
            {showReplies && (
                <div className="replies-container">
                    <h5>Replies</h5>
                    {replies.map((reply) => (
                        <div key={reply.id} className="reply">
                            <h6>{reply.title}</h6>
                            <p dangerouslySetInnerHTML={{ __html: reply.description }} />

                            <div className="details">
                                <span>Reply by:&nbsp;<i>{reply.user?.name}</i></span>
                                <span>Date:&nbsp;<i>{reply.dateAdded}</i></span>
                            </div>
                        </div>
                    ))}

                    <form onSubmit={handleReplySubmit}>
                        <h6>Add a Reply</h6>
                        <input
                            type="text"
                            placeholder="Title"
                            value={newReply.title}
                            onChange={(e) => setNewReply({ ...newReply, title: e.target.value })}
                            required
                        />
                        <ReactQuill
                            theme="snow"
                            value={newReply.description}
                            onChange={(value) => setNewReply({ ...newReply, description: value })}
                        />
                        <button className="btn navBtn" type="submit">Reply</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default QAItem;
