import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CoursePerksBenifites } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourseBulletPoint, addCoursePerksBenifites, addTopic, updateCourseBulletPoint, updateCoursePerksBenifites, updateTopic, uploadVideo } from '../../services/DashboardService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditPerkRewardPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [coursePerksBenifites, setCoursePerksBenifites] = useState<CoursePerksBenifites>({
        id: '',
        title: '',
        description: '',
        courseId: courseid ? parseInt(courseid) : 0
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.CoursePerksBenefits?.find((v: CoursePerksBenifites) => v.id === id);
                    if (foundItem) setCoursePerksBenifites(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCoursePerksBenifites((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (coursePerksBenifites: CoursePerksBenifites): string[] => {
        const errors: string[] = [];
        if (!coursePerksBenifites.title) errors.push('Title is required.');
        if (!coursePerksBenifites.icon) errors.push('Icon is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(coursePerksBenifites);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCoursePerksBenifites(coursePerksBenifites.id || '', coursePerksBenifites);
            } else {
                await addCoursePerksBenifites(coursePerksBenifites);
            }
            navigate(`/dashboard/perks-rewards/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateChange = (title: string, icon: string, description: string) => {
        setCoursePerksBenifites((prev) => ({ ...prev, title: title, icon: icon, description: description }));

    };
    const handleEditorChange = (value: string) => {
        setCoursePerksBenifites((prev) => ({ ...prev, description: value }));
    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit perks & reward' : 'Add New perks & reward'} - Dashboard`}
                description="Dashboard to add/edit perks & reward"
                keywords="dashboard, perks & reward management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/perks-rewards/${courseid}`}>Perks & Rewards ({courseItem?.CoursePerksBenefits?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            <Row>

                <Col md={12} xs={12}>
                    <h5>Use Templates: </h5>
                    <div className="perks-card-container">
                        {[
                            {
                                title: 'In-depth High-Quality Videos',
                                icon: 'fa fa-video-camera',
                                description: 'Learn through detailed, professional-grade videos designed for an engaging learning experience.',
                            },
                            {
                                title: 'Hands-on Experience',
                                icon: 'fa fa-hand-rock-o',
                                description: 'Get practical experience with real-world examples and exercises to strengthen your skills.',
                            },
                            {
                                title: 'Deep Dive into Architecture',
                                icon: 'fa fa-cogs',
                                description: 'Understand the core architecture and design principles for a comprehensive learning journey.',
                            },
                            {
                                title: 'Real-world Projects from Scratch',
                                icon: 'fa fa-laptop',
                                description: 'Build projects that simulate real-world scenarios and prepare you for industry challenges.',
                            },
                            {
                                title: 'Premium Developer Community',
                                icon: 'fa fa-users',
                                description: 'Join a vibrant community of developers to share ideas, collaborate, and grow together.',
                            },
                            {
                                title: 'Future Course Updates Included',
                                icon: 'fa fa-refresh',
                                description: 'Stay up to date with the latest trends and additions to the course content at no extra cost.',
                            },
                            {
                                title: 'Interview Concepts Explained',
                                icon: 'fa fa-user-md',
                                description: 'Master essential interview topics and confidently tackle technical assessments.',
                            },
                            {
                                title: `Course Certificate`,
                                icon: 'fa fa-certificate',
                                description: `Receive a professional <strong>${courseItem?.title}</strong> course certificate upon completion to showcase your achievements.`,
                            },
                        ].map((item, index) => (
                            <div
                                key={index}
                                className={`perks-common perks-card ${item.title === coursePerksBenifites?.title ? 'selected' : ''}`}
                                onClick={() => handleTemplateChange(item.title, item.icon, item.description)}
                            >
                                <i className={item.icon}></i>
                                    <h6>{item.title}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: item?.description || '' }} />

                            </div>
                        ))}
                    </div>

                </Col>
            </Row>
            <hr></hr>
            <Form>
                <Row>
                    <h6>Add Custom:</h6>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={coursePerksBenifites.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIcon">
                            <Form.Label>Icon Class : {coursePerksBenifites.icon ? (
                                <i className={coursePerksBenifites.icon}></i>
                            ) : (null)}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter icon class name ex: fa fa-video"
                                name="icon"
                                value={coursePerksBenifites.icon}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formIcon">
                            <Form.Label>Description
                            </Form.Label>
                            <ReactQuill
                                value={coursePerksBenifites.description}
                                onChange={handleEditorChange}
                                placeholder="Write the course objectives..."
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditPerkRewardPage;
