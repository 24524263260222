import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';
import { Author } from '../data/authorData';

const AUTHORS_API_URL = `${ENDPOINT}/authors/short`;
const AUTHORS_DETAIL_API_URL = `${ENDPOINT}/authors/all`;

export const getAuthors = async (): Promise<Author[]> => {
  try {
    const response = await axios.get(AUTHORS_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const getAuthorsInDetail = async (): Promise<Author[]> => {
  try {
    const response = await axios.get(AUTHORS_DETAIL_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const getAuthorsById = async (itemId: string): Promise<Author[]> => {
      const response = await axios.get<Author[]>(`${ENDPOINT}/authors/${itemId}/related`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
  
        },
      });
      return response.data;
  };
