import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';
import notify from './notificationService';

export interface CourseInquiryData {
    name: string;
    mobile: string;
    email: string;
    courseId: string;
    message: string;
}

const API_BASE_URL = `${ENDPOINT}/inquery`; // Replace with your backend API base URL

class CourseInquiryService {
    static async sendCourseInquiry(data: CourseInquiryData): Promise<void> {
        try {
            const response = await axios.post(`${API_BASE_URL}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if(response.status===200 || response.status===202)
            {
            notify.success('Inquiry submitted successfully:!', {
                position: 'top-right',
                autoClose: 2000,
              });
            }
        } catch (error) {
            notify.error(`Error submitting inquiry:, ${error}`, {
                position: 'top-right',
                autoClose: 2000,
              });
            throw new Error('Failed to submit course inquiry');
        }
    }
}

export default CourseInquiryService;
