import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import './CertificateGenerator.scss';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';
import notify from '../services/notificationService';

interface CertificateProps {
  studentName: string;
  courseName: string;
  description: string;
  image?: string; // Image URL or Base64 string
  author: string; // The trainer/author of the certificate
  directorName: string; // Name of the director
  uid: string;
}

const CertificateGenerator: React.FC<CertificateProps> = ({ studentName, courseName, description, image, author, directorName, uid }) => {


  const handleDownloadPNG = () => {
    if(!localStorage.getItem('token'))
      {
          notify.info('Info , Please login first!', {
              position: 'top-right',
              autoClose: 2000,
            });
            return false;
          }
    const certificateElement = document.getElementById('certificate');
    const downloadButton = document.getElementById('downloadBtn');

    if (certificateElement) {
      // Hide the download button before capturing
      if (downloadButton) {
        downloadButton.style.display = 'none';
      }

      html2canvas(certificateElement).then((canvas) => {
        // Convert the canvas to PNG image and trigger a download
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${studentName}_CourseEcho_Certificate.png`;
        link.click();

        // Restore the download button visibility after capture
        if (downloadButton) {
          downloadButton.style.display = 'block';
        }
      });
    }
  };

  return (
    <div className="certibody">
      <div className="certifcontainer" id="certificate">
        <div className="logo">CourseEcho</div>

        <div className="marquee">Certificate of Completion</div>

        <div className="assignment">This certificate is presented to</div>

        <div className="certificate-content">
          <div className="person text-secondary">{studentName}</div>
          <div className="reason">
            <span className="description">{description}</span>
            <br />
            <strong className="text-primary">{courseName}</strong>
          </div>
          <p className="text-muted">Issued on: {new Date().toLocaleDateString()}</p>
          <p className="text-success uid">Certificate UID: {uid}</p>
        </div>

        {/* Footer Section in HTML */}
        <div className="certificate-footer">
          <div className="footer-left">
            <p className="text-start">
              <u>Trainer</u> <br />
              <i>{author}</i>
            </p>
          </div>
          <div className="footer-center"
          >
            <i className='fa fa-certificate'></i>
          </div>
          <div className="footer-right">
            <p className="text-start">
              <u>Department</u> <br />
              <i>{directorName}</i>
            </p>
          </div>
        </div>

        {/* The download button that will be hidden during the capture */}
        <div className="row">
          <button className="navBtn" id="downloadBtn" title='Download' onClick={handleDownloadPNG}>
            <i className="fa fa-download text-lg"></i>
          </button>
        </div>
      </div>
    </div >
  );
};

export default CertificateGenerator;
