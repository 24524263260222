import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';
import { Tutorial } from '../data/tutorialsData';

const TUTORIALS_API_URL = `${ENDPOINT}/tutorials/short`;
const TUTORIALS_DETAIL_API_URL = `${ENDPOINT}/tutorials/all`;

export const getTutorials = async (): Promise<Tutorial[]> => {
  try {
    const response = await axios.get(TUTORIALS_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getTutorialsInDetail = async (): Promise<Tutorial[]> => {
  try {
    const response = await axios.get(TUTORIALS_DETAIL_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getTutorialsById = async (courseId: string): Promise<Tutorial[]> => {
      const response = await axios.get<Tutorial[]>(`${ENDPOINT}/tutorials/${courseId}/related`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
  
        },
      });
      return response.data;
  };
