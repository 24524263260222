import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Contact.scss'; // Optional: You can create a CSS file for styling

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us - Course Echo | Get in Touch</title>
        <meta
          name="description"
          content="Have questions or need support? Contact Course Echo via email, phone, or WhatsApp. We're here to help you with your learning journey."
        />
        <meta
          name="keywords"
          content="Course Echo, contact us, email support, phone support, WhatsApp support, coding help, Course Echo contact details"
        />
        <meta name="author" content="Course Echo Team" />
        <meta property="og:title" content="Contact Us - Course Echo | Get in Touch" />
        <meta
          property="og:description"
          content="Reach out to Course Echo for any queries. Contact us via email, phone, or WhatsApp for support and assistance."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://courseecho.com/contact" />
        <meta
          property="og:image"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta
          name="twitter:card"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta name="twitter:title" content="Contact Us - Course Echo | Get in Touch" />
        <meta
          name="twitter:description"
          content="Need help? Contact Course Echo for queries or support via email, phone, or WhatsApp."
        />
        <meta
          name="twitter:image"
          content="https://courseecho.com/dark-dark-logo.webp"
        />
        <meta name="twitter:creator" content="@CourseEcho" />
      </Helmet>

      <div className="banner">
          <img className="banner__image1" alt="Course echo contact" src="https://images.courseecho.com/backgrounds/contact-banner.webp"/>
              <h1 className="banner__header">
                A Step Closer! <sup className='fa fa-paper-plane-o'></sup>
              </h1>
            </div>
            
            <p>
              Email: <a href="mailto:courseecho@gmail.com">courseecho@gmail.com</a>
            </p>
            <p>
              Mobile: <a href="tel:+918677040031">8677040031</a>
            </p>
            <p>
              WhatsApp:{" "}
              <a
                href="https://wa.me/918677040031"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat with us!
              </a>
            </p>
          </div >
          );
};

export default Contact;
