import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './tutorials.scss';
import { Tutorial } from '../../data/tutorialsData';
import { getTutorials } from '../../services/TutorialsService';
import TutorialsCard from '../../components/TutorialsCard';
import MetaHelmet from '../../components/MetaHelmet';

interface TutorialsProps {
  tutorials: Tutorial[];
  isImported:boolean;
}

const TutorialsPage: React.FC<TutorialsProps> = ({isImported}) => {
  const [loading, setLoading] = useState(true); // State to handle loading

  const [searchParams] = useSearchParams();
  const categoryDisplayId = searchParams.get('tutorials-category');
  let title ='Tutorials - Course Echo | Learn Programming and Development'
  if(categoryDisplayId)
  {
    title= `Tutorials in Category ${categoryDisplayId} - Course Echo | Learn ${categoryDisplayId} Tutorials`;
  }

  // State for search term, sorting option, and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tutorials, setTutorials] = useState<Tutorial[]>([]);
  // Fetch tutorials when the component mounts
  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const tutorialsData = await getTutorials();
        setTutorials(tutorialsData); // Ensure resolved data is passed here
        setLoading(false)
      } catch (error) {
        console.error('Failed to load tutorials:', error);
        setLoading(false)
      }
    };
    fetchTutorials();
  }, [categoryDisplayId]); // Add dependency on categoryDisplayId
  

  // Function to handle search input
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle sort change
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as 'asc' | 'desc');
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Function to clear the filters (search and sort)
  const clearFilters = () => {
    setSearchTerm('');
    setSortOption('asc');
    setCurrentPage(1);
  };

  // Filter tutorials based on the search term
  const filteredTutorials = tutorials.filter(tutorials => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      tutorials.Courses?.title.toLowerCase().includes(lowercasedSearchTerm) ||
      tutorials.Courses?.description.toLowerCase().includes(lowercasedSearchTerm) ||
      tutorials.Courses?.badgeText.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // If categoryDisplayId is available, filter tutorials by categoryDisplayId first
  const filteredAndSortedTutorials = categoryDisplayId
    ? filteredTutorials.filter((tutorials) => tutorials.Courses?.categoryDisplayId === (categoryDisplayId))
    : filteredTutorials;

  // Sort tutorials by fee
  const sortedTutorials = [...filteredAndSortedTutorials].sort((a, b) => {
    if (sortOption === 'asc') {
      return a.total - b.total;
    } else {
      return b.total - a.total;
    }
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTutorials = sortedTutorials.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedTutorials.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  if(loading)
    {
  return (
    <div className="tutorials-details-container">
  <p className='text text-center'>Loading tutorials...</p>
  </div>
  );
    }
else
{
  // Schema Markup
  const generateSchemaData = () => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "url": `https://courseecho.com/tutorials`,
    "description": "Discover top programming and development tutorials on Course Echo. Learn Java, Angular, Flask, AWS, and more with in-depth tutorials and hands-on projects.",
    "author": {
      "@type": "Organization",
      "name": "Course Echo",
      "url": "https://courseecho.com"
    },
    "mainEntity": [
          ...filteredTutorials.map(course => ({
            "@type": "Course",
            "name": course.title,
            "description": course.description,
            "provider": {
              "@type": "Organization",
              "name": "Course Echo"
            },
            "educationalCredentialAwarded": "Quiz",
            "offers": {
              "@type": "Offer",
              "url": `https://courseecho.com/tutorials-details/${course.id}`,
              "priceCurrency": "INR",
              "price": 0,
              "eligibleRegion": {
                "@type": "Place",
                "name": "Worldwide"
              },
              "priceValidUntil": "2025-12-31",
              "category": "General",  // Add category field
            },
            "hasCourseInstance": {
            "@type": "CourseInstance",
            "courseMode": "Online",  // Assuming all courses are offered online for now
            "location": {
              "@type": "Place",
              "name": "Online"
            },
            "startDate": "2024-12-26"  // You can replace this with the actual course start date
          }
          }))
        ],
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://courseecho.com/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  });
  return (
    <div className="container tutorials">
     {!isImported ? (
  <MetaHelmet
  title={title}
  description="Discover top programming and development tutorials on Course Echo. Learn Java, Angular, Flask, AWS, and more with in-depth tutorials and hands-on projects."
  keywords="Course Echo, programming tutorials, development tutorials, Java, Angular, Flask, AWS, MySQL, PHP, learn programming, coding tutorials"
  author="Course Echo Team"
  ogTitle={title}
  ogDescription="Explore programming tutorials on Course Echo - Comprehensive tutorials in Java, Angular, Flask, AWS, and more. Kickstart your coding journey today!"
  ogImage="https://images.courseecho.com/dark-logo.webp"
  ogUrl="https://courseecho.com/tutorials"
  twitterTitle={title}
  twitterDescription="Learn programming with Course Echo. Access tutorials and tutorials on Java, Angular, Flask, AWS, and more!"
  twitterImage="https://images.courseecho.com/dark-logo.webp"
  twitterCreator="@CourseEcho"
  schemaMarkup={generateSchemaData()}
/>
) : (
  <></> // Using an empty fragment instead of <br> for cleaner output when not imported
)}

      <h2 className='header text-center'>{categoryDisplayId ? `Tutorials in Category ${categoryDisplayId}` : 'Explore Our Tutorials'}</h2>

      {/* Search Bar and Clear Filter Button */}
      <div className="search-sort-container">
        <input
          type="text"
          placeholder="Search tutorials..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />

        {/* Clear Search Icon */}
        {searchTerm && (
          <button className="clear-button" onClick={clearFilters}>
            <span className="clear-icon">✖</span>
          </button>
        )}

        {/* Sort Dropdown */}
        <select value={sortOption} aria-label="Sort by price" title="Sort by price" onChange={handleSortChange} className="sort-dropdown">
          <option value="asc">Total: Low to High</option>
          <option value="desc">Total: High to Low</option>
        </select>
      </div>

      {/* Items Per Page Dropdown */}
      <div className="items-per-page-container">
        <label htmlFor="items-per-page" className="items-per-page-label">Items per page:</label>
        <select id="items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange} className="items-per-page-select">
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Display Tutorials */}
      <div className="tutorials-container">
        <div className="row">
          {currentTutorials.map((tutorials) => (
            <div className="col-md-3 col-sm-4 mb-3" key={tutorials.id}>
              <TutorialsCard tutorials={tutorials} />
            </div>
          ))}
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        {totalPages > 1 && (
          <>
            <button
              className="page-button"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &laquo; First
            </button>
            <button
              className="page-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt; Prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="page-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next &gt;
            </button>
            <button
              className="page-button"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last &raquo;
            </button>
          </>
        )}
      </div>
    </div>
  );
}
};

export default TutorialsPage;
