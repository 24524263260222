import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'; // Import Link
import { getDashboardCourseById, getDashboardCourses, getDashboardUsers } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { UserInterface } from '../../data/userData';
import { Course, Topics } from '../../data/coursesData';
import { NULL } from 'sass';
import ReactPlayer from 'react-player';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const TopicsPage: React.FC = () => {
  const [Courses, setCourse] = useState<Course>();
  const [Topics, setTopics] = useState<Topics[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardCourseById(id || '');
        setCourse(response[0]);
        setTopics(response[0].Topics || [])
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: number) => {

  };


  const filteredData = Topics.filter((request) => {
    return (
      request.title.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.description.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item>
            <Link to="/dashboard/courses"> Courses : {Courses?.title}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Topics : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>

        <div className="mb-3">
          <Link to={`/dashboard/add-topic/${Courses?.id}`} className="btn btn-primary text-success">
            Add New Topic
          </Link>
        </div>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>IsFree</th>
              <th>Source</th>
              <th>Parent</th>
              <th>IsTrailer</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>
                  {request.parentId !== null ? (
                    <div>
                      <ReactPlayer
                        title={request?.title}
                        url={request?.externalLink}
                        playing={false}
                        controls={true}
                        muted={false}
                        width="200px"
                        height="220px"
                      />

                      <br></br>
                    </div>
                  ) : (null)}
                  {request.title}</td>
                <td>
                  <p className='truncate'>
                    {request.description}
                  </p>
                </td>
                <td>
                  <Badge bg='info'>
                    {request.isFree ? 'YES' : 'NO'}
                  </Badge>
                </td>
                <td>
                  <div className="source-card-container">
                    <div
                      key={request.source}
                      className={`source-${request.source.toLowerCase()} source-card selected}`}
                    >
                      {request.source}
                    </div>
                  </div>
                </td>
                <td>
                  {request.parentId != null ? (
                    Courses?.Topics?.find((v) => v.id === request.parentId)?.title || null
                  ) : null}
                </td>

                <td>
                  <Badge bg='info'>
                    {request.isTrailer === '1' ? 'YES' : 'NO'}
                  </Badge>
                </td>
                <td>{request.dateAdded}</td>
                <td>{request.dateUpdated}</td>
                <td>
                  <Link
                    className="me-2 btn btn-warning navBtn"
                    to={`/dashboard/edit-topic/${request.courseId}/${request.id}`} // Pass the course ID for editing
                  >
                    Edit
                  </Link>
                  {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(request.id)}
                      className="me-2 navBtn"
                    >
                      Delete
                    </Button>
                  ) : (null)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </div>
  );
};

export default TopicsPage;
