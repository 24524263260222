import React, { useEffect, useState } from 'react';
import { DashboardCard } from '../../data/dashboardCard';
import { getDashboardCards } from '../../services/DashboardService';
import { useNavigate } from 'react-router-dom';
import './Dashboard.scss';
import { HelmetData } from 'react-helmet-async';
import MetaHelmet from '../../components/MetaHelmet';
import MostSoldPage from './MostSoldPage';
import MostClickedPage from './MostClickedPage';
import TopAuthorPage from './TopAuthorPage';
import CampusCounselorBooking from './CampusCounselorBooking';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();



  const [dashboardData, setDashboardData] = useState<DashboardCard[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Replace with your actual API endpoint
    const fetchData = async () => {
      try {
        const response = await getDashboardCards();
        setDashboardData(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to handle navigation based on card id
  const handleCardClick = (cardId: string) => {
    console.log(cardId)
    switch (cardId) {
      case 'total_call_request':
        navigate('/dashboard/callrequest'); // Navigate to respective page 1
        break;
      case 'total_inquery':
        navigate('/dashboard/courseinquery'); // Navigate to respective page 2
        break;
      case 'total_students':
        navigate('/dashboard/students'); // Navigate to respective page 3
        break;
      case 'total_mentors':
        navigate('/dashboard/mentors'); // Navigate to respective page 3
        break;
      case 'courses':
        navigate('/dashboard/courses'); // Navigate to respective page 3
        break;
      case 'total_completed_order':
        navigate('/dashboard/orders/Completed'); // Navigate to respective page 3
        break;
      case 'total_completed_order_sales':
        navigate('/dashboard/orders/Completed'); // Navigate to respective page 3
        break;
      case 'total_inprocess_order':
        navigate('/dashboard/orders/Processing'); // Navigate to respective page 3
        break;
      case 'total_interview':
        navigate('/dashboard/interviews'); // Navigate to respective page 3
        break;
      case 'user_courses':
        navigate('/dashboard/purchased-courses'); // Navigate to respective page 3
        break;
        case 'session_booked_by_me':
          navigate('/dashboard/booked-session-by-me'); // Navigate to respective page 3
          break;
          case 'session_booked_for_me':
            navigate('/dashboard/booked-session-for-me'); // Navigate to respective page 3
            break;
      // Add more cases based on your card ids
      default:
        console.log('Invalid card ID');
        break;
    }
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>{error}</div>;
  }
  const claim = localStorage.getItem('defaultClaim') || '';
  const isClaimed = (claim !== 'STUDENT' && claim !== 'COUNSELOR')
  return (
    <div className="dashboard-container py-5">
      <MetaHelmet
        title="Dashboard | Course Echo"
        description="Dashboard to manage"
        keywords="dashboard, course echo"
        author="Course Echo"
      />
      {!loading ? (
        <div className="row">

          {isClaimed ?
            (
              <div className="col-12 col-md-4 col-lg-4 mb-4">

                <div className="soldCard">
                  <h6>Top 10 Sold Courses</h6>
                  <MostSoldPage />
                </div>

                <div className="soldCard mt-2">
                  <h6>Courses Count</h6>
                  <MostClickedPage />
                </div>
                {claim === 'SUPERADMIN' ?
                  (
                    <div className="soldCard mt-2">
                      <h6>Top 10 Authors By Revenue</h6>
                      <TopAuthorPage />
                    </div>
                  ) : (null)}
              </div>

            ) : (null)}


          <div className={`${claim === 'STUDENT' ? 'col-12 col-md-12 col-lg-12 mb-4' : ' col-12 col-md-8 col-lg-8 mb-4'}`}>
            <div className="row">

              {dashboardData.map((card, index) => (
                <div
                  key={index}
                  className={`${claim === 'STUDENT' ? 'col-6 col-md-2 col-lg-2 mb-4' : 'col-6 col-md-3 col-lg-3 mb-4'}`}
                  title={`${card.card_description} ${card.card_total}`}
                  onClick={() => handleCardClick(card.card_id)} // Handle the click event
                >
                  <div className="dashboard-card" >
                    {/* Card content here */}
                    <div className="dashboard-card-body text-center" style={{ backgroundColor: `${card.card_bg_color}` }}>
                      <div className="dashboard-card-icon mb-1">
                        <i
                          className={card.card_icon}
                          style={{ fontSize: '28px' }}
                        ></i>
                      </div>
                      <p className="dashboard-card-title">{card.card_title}</p>
                      <div className="dashboard-card-total display-4">
                        {card.card_total}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {claim==="COUNSELOR" ?
          (
          <div className="col-12">
          <div className="soldCard">
                  <h6>Booking Details</h6>
                  <CampusCounselorBooking />
                </div>
          </div>
          ):(null)}
        </div>
      ) : (
        <div className='row'>
          <h6 className='text-center'>
            Loading....
          </h6>
        </div>
      )}

    </div>
  );
};

export default Dashboard;
