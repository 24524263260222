// Function to generate a secure 8-character password with uppercase, lowercase, digits, and symbols
export const generatePassword = (): string => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const symbols = '!@#$%^&*()_+[]{}|;:,.<>?';
  
    const allCharacters = uppercase + lowercase + digits + symbols;
    let password = '';
  
    // Ensure the password contains at least one of each category
    password += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
    password += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    password += digits.charAt(Math.floor(Math.random() * digits.length));
    password += symbols.charAt(Math.floor(Math.random() * symbols.length));
  
    // Add random characters to make the password 8 characters long
    for (let i = password.length; i < 8; i++) {
      password += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }
  
    // Shuffle the password to ensure the order is random
    password = password.split('').sort(() => Math.random() - 0.5).join('');
  
    return password;
  };
  