import React from 'react';
import { Author } from '../data/authorData';

interface AuthorCardProps {
  author: Author;
  onClick: (id: string,name:string) => void;
}

const AuthorCard: React.FC<AuthorCardProps> = ({ author, onClick }) => (
  <div
    className="card shadow-sm"
    style={{ width: '18rem', cursor: 'pointer', margin: '10px' }}
    onClick={() => onClick(author.id,author.name)}
    role="button"
    aria-label={`View details of ${author.name}`}
  >
    {author.image && (
      <img
        src={author.image}
        className="card-img-top img-fluid"
        alt={author.name}
        style={{ height: '250px', objectFit: 'cover' }}
      />
    )}
    <div className="card-body authorBody">
      <h5 className="card-title text-truncate" title={author.name}>
        {author.name}
      </h5>
      <div className='card-text interview-description p-2 text-white' dangerouslySetInnerHTML={{ __html: author?.description || '' }}/>
      <button
        className="btn btn-primary viewBtn"
        onClick={(e) => {
          e.stopPropagation(); // Prevent the parent onClick from firing
          onClick(author.id,author.name);
        }}
      >
        View Details
      </button>
    </div>
  </div>
);

export default AuthorCard;
