import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./../../components/AuthorDetails.scss";
import {
  deleteCounselorCollege,
  deleteCounselorSchool,
  getCounselorByToken
} from "../../services/DashboardService";
import { UserProfileInterface } from "../../data/userData";

const CounselorEditDetails: React.FC = () => {
  const [counselor, setCounselor] = useState<UserProfileInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [isUpdated, setUpdated] = useState<boolean>(true);
  useEffect(() => {
    const fetchCounselorDetails = async () => {
      try {
        setLoading(true);
        const data = await getCounselorByToken();
        setCounselor(data[0]); // Assuming the response is an array
        setError(null);
      } catch (err) {
        console.error("Error fetching details:", err);
        setError("Failed to load details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCounselorDetails();
  }, [isUpdated]);


  const handleDeleteWorkHistory = async (id: string| undefined) => {
  if (!window.confirm("Are you sure you want to delete this?")) return;
  try {
    setLoading(true);
    await deleteCounselorCollege(id || "");
    setUpdated(true)

  } catch (err) {
    console.error("Error deleting:", err);
    setError("Failed to delete. Please try again.");
  } finally {
    setLoading(false);
    window.location.reload()
  }
};

const handleDeleteSocialLink = async (id: string | undefined) => {
  if (!window.confirm("Are you sure you want to delete this?")) return;
  try {
    setLoading(true);
    await deleteCounselorSchool(id || "");
    setUpdated(true)

  } catch (err) {
    console.error("Error deleting:", err);
    setError("Failed to delete. Please try again.");
  } finally {
    setLoading(false);
    window.location.reload()
  }
};



if (loading) {
  return <div className="text-center mt-5">Loading counselor details...</div>;
}

if (error) {
  return (
    <div className="alert alert-danger text-center mt-5">{error}</div>
  );
}

if (!counselor) {
  return (
    <div className="text-center mt-5">
      <p>No author details found.</p>
      <Link to="/" className="navBtn mt-4">
        Back to Counselor
      </Link>
    </div>
  );
}

return (
  <div className="container authorDetailsPage">
    <Helmet>
      <title>{counselor?.counselor?.name} - Campus Counselor Details | Course Echo</title>
      <meta
        name="description"
        content={`Learn more about ${counselor?.counselor?.name}, including their colleges and schools.`}
      />
    </Helmet>
    <div className="row shadow">
      <div className="col-sm-12">
        <h2 className=" detail-h1 text-center">Campus Counselor Profile
        </h2>
      </div>

      <div className="col-sm-12">
        <h2 className="sectionH detail-h1">Description <Link to={`/dashboard/update-counselor/${counselor?.counselor?.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
        </Link>
        </h2>

        <div dangerouslySetInnerHTML={{ __html: counselor?.counselor?.description || '' }} />


      </div>

      <div className="col-sm-12">
        <h2 className="sectionH detail-h1">Colleges
          <Link to={`/dashboard/add-counselor-college/${counselor?.counselor?.id}`}><i className="fa fa-plus-circle" title="Click to add"></i>
          </Link>
        </h2>
        <ul className="list-group mb-4">
          {counselor?.counselor?.colleges?.map((work) => (
            <li key={work.id} className="list-group-item">
              <div className="row">
                <div className="col-11">
                  <h3>
                    <a href={work.link} title={`Visit to ${work.title}`} target="_blank" rel="noopener noreferrer">
                      {work.title}
                    </a>
                  </h3>
                  {work.description && (
                    <div dangerouslySetInnerHTML={{ __html: work?.description || '' }} />
                  )}

                </div>
                <div className="col-1">
                  <span>
                    <Link to="#" onClick={() => handleDeleteWorkHistory(work.id)}><i className="fa fa-trash text-danger" title="Click to delete"></i>
                    </Link> <hr></hr><Link to={`/dashboard/edit-counselor-college/${counselor?.counselor?.id}/${work.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
                    </Link>
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="col-sm-12">
        <h2 className="sectionH detail-h1">Schools

          <Link to={`/dashboard/add-counselor-school/${counselor?.counselor?.id}`}><i className="fa fa-plus-circle" title="Click to add"></i>
          </Link>

        </h2>

        <ul className="list-group mb-4">
          {counselor?.counselor?.schools?.map((link) => (
            <li key={link.id} className="list-group-item">
              <div className="row">
                <div className="col-11">
                  {/* Add icon for social link */}
                  <a href={link.link} target="_blank" rel="noopener noreferrer">
                    {link.title}
                  </a>
                  {link.description && (
                    <div dangerouslySetInnerHTML={{ __html: link?.description || '' }} />
                  )}
                </div>
                <div className="col-1">
                  <span>
                    <Link to="#" onClick={() => handleDeleteSocialLink(link.id)}><i className="fa fa-trash text-danger" title="Click to delete"></i>
                    </Link> <hr></hr><Link to={`/dashboard/edit-counselor-school/${counselor?.counselor?.id}/${link.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
                    </Link>
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);
};

export default CounselorEditDetails;
