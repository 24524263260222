import React, { useEffect, useState } from 'react';
import './SessionBookedForMe.scss';
import { campusService } from '../services/CampusService';
import { SessionBooking } from '../data/campusData';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Modal } from 'react-bootstrap';
import { PROJECTNAME } from '../utils/constants';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import notify from '../services/notificationService';

const SessionBookedForMe: React.FC = () => {
  const [cartItems, setCartItems] = useState<number>(0);
  const [booking, setBooking] = useState<SessionBooking[]>([]); // State to store courses
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [events, setEvents] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [layout, setLayout] = useState<'calendar' | 'list'>('calendar');
  const navigate = useNavigate();

  const isSessionExpired = (sessionDate: string, sessionTime: string) => {
    const [hour, minute] = sessionTime.split(":");
    const sessionDateTime = new Date(sessionDate);
    sessionDateTime.setHours(parseInt(hour), parseInt(minute));

    return sessionDateTime < new Date(); // Compare with current date and time
  };
  const handleAddToCart = () => {
    setCartItems(cartItems + 1);
  };
  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const data = await campusService.getSessionBookedForMe();
        setBooking(data);
        // Transform bookings to FullCalendar event format
        const transformedEvents = data.map((booking) => {
          const [hour, minute] = booking.time.split(":");
          return {
            id: booking.id,
            title: `${booking.name} (${booking.duration})`,
            start: new Date(
              new Date(booking.date).setHours(parseInt(hour), parseInt(minute))
            ),
            end: new Date(
              new Date(booking.date).setHours(
                parseInt(hour) + (booking.duration === "1-hour" ? 1 : 0),
                parseInt(minute)
              )
            ),
            extendedProps: {
              ...booking,
              title: `${booking.name} (${booking.duration})`,

            },
          };
        });

        setEvents(transformedEvents);
        console.log(events)
      } catch (err) {
        setError('Failed to fetch. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBooking();
  }, []);
  const handleEventClick = (info: any) => {
    setSelectedEvent(info.event.extendedProps); // Set the event details
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };
  const handleActionNavigation = (bookingInfo: SessionBooking, action: string) => {
    if (action === "join" && isSessionExpired(bookingInfo.date, bookingInfo.time)) {
      notify.info('Video Session Expired', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }
  const handleChat = (bookInfo: SessionBooking) => {
    console.log(bookInfo)
    navigate(`/dashboard/session/chat/${bookInfo.id}`);
  }
  if (loading) {
    return <div className="container courses-container">Loading booking...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container session-container">
      <Helmet>
        <title>{localStorage.getItem('email')} Session Booked For Me | Course Echo</title>
        <meta name="description" content="Find your booking and help users" />
        <meta name="keywords" content="booking, course echo" />
        <meta name="author" content={PROJECTNAME} />
      </Helmet>
      <h2>Booking {layout === 'list' ? ("List") : ("Calendar")}</h2>
      <div className="layout-selector customLayout">

        <button
          className={`layout-icon ${layout === 'calendar' ? 'active' : ''}`}
          onClick={() => setLayout('calendar')}
          title="Calendar Layout"
        >
          <i className='fa fa-calendar'></i>
        </button>
        <button
          className={`layout-icon ${layout === 'list' ? 'active' : ''}`}
          onClick={() => setLayout('list')}
          title="List Layout"
        >
          <i className='fa fa-list'></i>

        </button>
      </div>
      {layout === 'calendar' ?
        (
          <div className="row">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth" // Default view: week view with time slots
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              events={events} // Pass the events to FullCalendar
              editable={false} // Disable drag-and-drop editing
              selectable={true} // Enable date and time slot selection
              eventClick={handleEventClick}
              slotMinTime="01:00:00" // Calendar start time
              slotMaxTime="24:00:00" // Calendar end time
            />
          </div>) : (null)}
      {
        layout === 'list' ? (
          <div className="row">
            <h5 className="mb-3">
              Total Booking: <span className="badge bg-secondary">{booking.length}</span>
            </h5>
            <div className="list-group">
              {booking.map((item) => (
                <div
                  key={item.id}
                  className="list-group-item d-flex customd-flex  justify-content-between align-items-center"
                >
                  {/* Counselor Info */}
                  <div>
                    <h6 className="mb-1">
                      <span className="text-primary">
                        {item.name}</span>
                      {item.user?.defaultClaim && (
                        <span className="badge bg-success ms-2">
                          <i className="fa fa-check-circle text-white"> {item.user?.defaultClaim}</i>
                        </span>
                      )}
                    </h6>
                    <div className='row'>
                      <div className="col-12">
                        <ul className='list-group'>
                          <li className='d-flex customd-flex  justify-content-between align-items-center'>
                            <span className='p-2'><strong>Date:</strong> {item.date}</span>

                            <span className='p-2'><strong>Time:</strong> {item.time}</span>

                            <span className='p-2'><strong>Duration:</strong> {item.duration}</span>

                            <span className='p-2'><strong>Payment:</strong> ₹{item.totalPrice}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <p>
                          <strong>Message: </strong> {item.message}
                        </p>
                      </div>
                    </div>
                    <div className='linkIcon mt-2'>
                      {item.mobile ?
                        (
                          <Link to={`tel:${item.mobile}`} title='click to call' className='text-secondary'><i className='fa fa-phone-square'></i> {item.mobile}</Link>) : (null)} &nbsp;
                      {item.email ?
                        (
                          <Link to={`mailto:${item.email}`} title='click to send mail' className='text-secondary'><i className='fa fa-envelope'></i> {item.email}</Link>) : (null)}

                    </div>
                  </div>
                  <div className='button-container'>
                    <button onClick={() => handleActionNavigation(item, 'join')} className="btn btn-outline-primary me-2 viewBtn" title='click to join meeting'>
                      <i className='fa fa-external-link'></i>

                    </button>
                    <button onClick={() => handleActionNavigation(item, 'feedback')} title='click to write feedback' className="btn btn-outline-primary me-2 viewBtn">
                      <i className='fa fa-pencil-square-o'></i>

                    </button>

                    <button
                      onClick={() => handleActionNavigation(item, 'notification')}
                      title='click to send notification mail'
                      className="btn btn-primary applyBtn"
                    >
                      <i className='fa fa-envelope-open'></i>

                    </button>
                    <button
                      onClick={() => handleChat(item)}
                      title='click to chat'
                      className="btn btn-primary applyBtn"
                    >
                      <i className='fa fa-comments'></i>
                    </button>
                  </div>

                </div>
              ))}
            </div>
          </div>
        ) : (null)}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div className="row">
              <div>
                <p><strong>Title:</strong> {selectedEvent.title}</p>
                <p><strong>Date:</strong> {selectedEvent.date}</p>
                <p><strong>Time:</strong> {selectedEvent.time}</p>
                <p><strong>Duration:</strong> {selectedEvent.duration}</p>
                <p><strong>Total Price:</strong> ₹{selectedEvent.totalPrice}</p>
                <p><strong>Booked By User:</strong> {selectedEvent.user.name}</p>
                <p><strong>Booked For Counselor:</strong> {selectedEvent.counselor.name}</p>
              </div>
              <div className='button-container'>
                <button onClick={() => handleActionNavigation(selectedEvent, 'join')} className="btn btn-outline-primary me-2 viewBtn" title='click to join meeting'>
                  <i className='fa fa-external-link'></i>

                </button>
                <button onClick={() => handleActionNavigation(selectedEvent, 'feedback')} title='click to write feedback' className="btn btn-outline-primary me-2 viewBtn">
                  <i className='fa fa-pencil-square-o'></i>

                </button>

                <button
                  onClick={() => handleActionNavigation(selectedEvent, 'notification')}
                  title='click to send notification mail'
                  className="btn btn-primary applyBtn"
                >
                  <i className='fa fa-envelope-open'></i>

                </button>
                <button
                  onClick={() => handleChat(selectedEvent)}
                  title='click to chat'
                  className="btn btn-primary applyBtn"
                >
                  <i className='fa fa-comments'></i>
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SessionBookedForMe;