import React, { useEffect, useState, useMemo } from "react";
import "./Sidenav.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import CourseInquiryForm from "./CourseInquiryForm";
import { Course } from "../data/coursesData";
import { getCourses } from "../services/CourseService";
import { useTheme } from "../context/ThemeContext";
import { MenuItem } from "../data/menuItem";
import { getDashboardMenu } from "../services/DashboardService";

interface SidenavProps {
    isSidenavActive: boolean;
    toggleSidenav: (status: boolean) => void;
}

const Sidebar: React.FC<SidenavProps> = ({ isSidenavActive, toggleSidenav }) => {
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const { logout } = useAuth();
    const [showModal, setShowModal] = useState<boolean>(false);
    const { theme, toggleTheme } = useTheme();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
  const [showRequest, setShowRequest] = useState<boolean>(true);

    // Fetch claim value from localStorage
    const claim = localStorage.getItem('defaultClaim') || '';
    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const data = await getDashboardMenu();
                setMenuItems(data);
            } catch (error) {
                console.error("Error fetching menu items:", error);
            }
        };
        fetchMenuItems();
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const courseData = await getCourses();
                setCourses(courseData);
            } catch (error) {
                console.error("Failed to load courses:", error);
            }
        };
        fetchCourses();
    }, []);

    const handleFormSubmit = (formData: { name: string; mobile: string; email: string; courseId: string; message: string }) => {
        console.log("Form submitted:", formData);
        setShowModal(false); // Close the modal after submission
    };

    const toggleSubMenu = (item: MenuItem) => {
        toggleSidenav(false);
        setActiveMenu(activeMenu === item.id ? null : item.id); // Toggle submenu visibility
    };

    const handleLogout = () => {
        logout();
        window.location.href = "/login"; // Redirect to login after logout
    };
    const handleRquestClick = () => {
        setShowRequest(false);
      };
    // Memoize filtered courses
    const filteredCourses = useMemo(() => {
        return courses;
    }, [courses]);

    const courseData = useMemo(
        () =>
            filteredCourses.map((course) => ({
                id: course.id.toString(),
                title: course.title,
            })),
        [filteredCourses]
    );

    return (
        <div>
            {/* Check if claim exists and is not empty */}
            {claim !== '' ? (
                <div className={`app ${isSidenavActive ? "sidenav-collapsed" : "sidenav-expanded"}`}>
                    {/* Sidebar */}
                    <div className={`sidenav ${isSidenavActive ? "active" : ""}`}>
                        <div className="sidenav-header sticky-top">
                            <button className={`toggle-button ${isSidenavActive ? "button-active" : ""}`} onClick={() => toggleSidenav(!isSidenavActive)}>
                                {isSidenavActive ? <i className="fa fa-bars"></i> : <i className="fa fa-angle-double-left"></i>}
                            </button>
                        </div>
                        <ul className="menu-list">
                            {menuItems.map((item) => (
                                <li key={item.id} className="menu-item">
                                    {item.url !== "#" && (!item.subMenu || item.subMenu.length === 0) ? (
                                        <Link to={item.url} className="menu-link" onClick={() => toggleSidenav(false)}>
                                            <span className={`menu-icon ${item.icon}`}></span>
                                            <span className="menu-text">{item.text}</span>
                                        </Link>
                                    ) : (
                                        <div className="menu-link" onClick={() => toggleSubMenu(item)}>
                                            <span className={`menu-icon ${item.icon}`}></span>
                                            <span className="menu-text">{item.text}</span>
                                            {item.subMenu && (
                                                <span className="submenu-toggle-icon">
                                                    {activeMenu === item.id ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {item.subMenu && activeMenu === item.id && (
                                        <ul className="sub-menu">
                                            {item.subMenu.map((subItem) => (
                                                <li key={subItem.id} onClick={() => toggleSidenav(false)}>
                                                    <Link to={subItem.url} className="sub-menu-item">
                                                        <span className={`menu-icon ${subItem.icon}`}></span> {subItem.text}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {/* Footer Section */}
                        <div className="sidenav-footer">
                            <ul className="menu-list">

                                <li className="menu-item">
                                    <Link to="/profile" className="menu-link">
                                        <span className="menu-icon fa fa-user"></span> Profile
                                    </Link>

                                </li>
                                <li className="menu-link" title="Click to logout" onClick={handleLogout}>
                                    <span className={`menu-icon fa fa-sign-out`}></span>
                                    <span className="menu-text">Logout</span>
                                </li>
                                <li className="menu-link" title="Click to change theme" onClick={toggleTheme}>
                                    {theme === "light" ? <span className="menu-icon fa fa-moon-o"></span> : <span className="menu-icon fa fa-sun-o"></span>}
                                    <span className="menu-text">Theme</span>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                /* Main Content */
                <div>
                    <div onClick={toggleTheme} title='click to change theme' className="vertical-links-button">
                        {theme === 'light' ? (
                            <i className="fa fa-moon-o"></i> // Dark mode icon
                        ) : (
                            <i className="fa fa-sun-o"></i> // Light mode icon
                        )}
                    </div>
                    {showRequest?
                    (<div className="in">

                        <div className="vertical-button">
                        <div className='fa fa-close divRight' onClick={handleRquestClick} title='close this'></div>

                            <span onClick={() => setShowModal(true)}>Quick Inquiry</span>
                        </div>
                        {showModal && (
                            <CourseInquiryForm
                                onClose={() => setShowModal(false)}
                                onSubmit={handleFormSubmit}
                                courses={courseData}
                            />
                        )}
                    </div>):(null)}
                </div>
            )}
        </div>
    );
};

export default Sidebar;
