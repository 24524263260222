import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define a generic interface for notifications
interface NotifyOptions extends ToastOptions {}

// Fetch theme from localStorage or default to 'dark'
const theme = (localStorage.getItem('theme') || 'dark') as 'light' | 'dark' | 'colored';

// Create the notify object with default theme applied
const notify = {
  success: (message: string, options?: NotifyOptions) =>
    toast.success(message, { theme, ...options }),
  error: (message: string, options?: NotifyOptions) =>
    toast.error(message, { theme, ...options }),
  info: (message: string, options?: NotifyOptions) =>
    toast.info(message, { theme, ...options }),
  warning: (message: string, options?: NotifyOptions) =>
    toast.warn(message, { theme, ...options }),
};

export default notify;
