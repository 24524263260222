import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { UserModel } from '../profile/model/User.model';
import { UserInterface } from '../../data/userData';
import { Author, WorkHistory } from '../../data/authorData';
import { addAuthorWorkHistory, getAuthorsByToken, getDashboardAuthors, updateAuthorWorkHistory, uploadSocialImage } from '../../services/DashboardService';
import { getAuthorsById } from '../../services/AuthorsService';

const AddEditMentorWorkPage: React.FC = () => {


    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [authors, setAuthors] = useState<Author[]>([]);
    const [users, setUsers] = useState<UserInterface[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { id, work_id } = useParams<{ id: string, work_id: string }>();
    const [imageFile, setImageFile] = useState<File | null>(null); // State for handling file upload
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);  // State to manage URL or file selection
    const [workHistory, setWorkHistory] = useState<WorkHistory>({
        id: '',
        companyName: '',
        description: '',
        image: '',
        webLink: '',
        authorId: id,
        achivements:'',
        skills:'',
        isCurrent:'true'
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const workHistorysResponse = await getDashboardAuthors();
                setAuthors(workHistorysResponse);
            } catch (error) {
                setErrorMessage('Error fetching dropdown data');
                setShowError(true);
            }
        };

        fetchDropdownData();

        if (id) {
            setIsEditing(false)
            setLoading(true);
            setIsUrlSelected(true);
            if (localStorage.getItem("defaultClaim") !== "MENTOR") {
                getAuthorsById(id)
                    .then((author: Author[]) => {
                        if (work_id) {
                            const socialData = author[0]?.WorkHistory?.find((v: WorkHistory) => v.id === work_id);
                            if (socialData) {
                                setWorkHistory(socialData)
                                setIsEditing(true);

                            }
                        }
                    })
                    .catch((error: any) => {
                        setErrorMessage('Error fetching social Link data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
            else {
                getAuthorsByToken()
                    .then((author: Author[]) => {
                        if (work_id) {
                            const socialData = author[0]?.WorkHistory?.find((v: WorkHistory) => v.id === work_id);
                            if (socialData) {
                                setWorkHistory(socialData)
                                setIsEditing(true);

                            }
                        }
                    })
                    .catch((error: any) => {
                        setErrorMessage('Error fetching social Link data');
                        setShowError(true);
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWorkHistory((prevCourse: WorkHistory) => ({ ...prevCourse, [name]: value }));
    };
    const handleBadgeTextChange = (badge: string) => {
        setWorkHistory((prevCourse) => ({ ...prevCourse, isCurrent: badge }));
    };

    const handleEditorChange = (value: string) => {
        setWorkHistory((prevCourse: WorkHistory) => ({ ...prevCourse, conclusion: value }));
    };

    const handleDescriptionEditorChange = (value: string) => {
        setWorkHistory((prevCourse: WorkHistory) => ({ ...prevCourse, description: value }));
    };

    const handleSaveWorkHistory = async () => {
        const validateWorkHistory = (workHistory: WorkHistory): string[] => {
            const errors: string[] = [];
            if (!workHistory.companyName) errors.push('company name is required.');
            if (!workHistory.description) errors.push('Description is required.');
            if (!workHistory.webLink) errors.push('website link required.');
            if (!workHistory.skills) errors.push('skill required.');
            if (!workHistory.image && !imageFile) errors.push('Image is required.');
            return errors;
        };

        // Perform Validation
        const errors = validateWorkHistory(workHistory);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);

        try {
            // Handle image upload if a file is selected
            if (imageFile) {
                const imageUrl = await uploadSocialImage(imageFile);
                workHistory.image = imageUrl; // Set the image URL after upload
            }


            if (isEditing) {
                await updateAuthorWorkHistory(workHistory); // Call the update service

            } else {
                const data = { ...workHistory }
                await addAuthorWorkHistory(data); // Call the add service
            }
            if (localStorage.getItem("defaultClaim") !== "MENTOR") {
                navigate('/dashboard/authors');
            }
            else {
                navigate('/profile')
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'An error occurred while saving the workHistory.';
            setErrorMessage(message);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    // Handle image file selection
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setWorkHistory((prevCourse) => ({ ...prevCourse, image: '' })); // Clear the image URL if file is selected
        }
    };

    const handleStatusChange = (status: string) => {
        setWorkHistory((prevCourse) => ({ ...prevCourse, status: status }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit WorkHistory' : 'Add New WorkHistory'} - ${localStorage.getItem('email')} - WorkHistory Echo`}
                description="Dashboard to add/edit workHistorys"
                keywords="workHistory echo dashboard,Dashboard to add/edit workHistorys"
                author="WorkHistory Echo"
            />
            {localStorage.getItem("defaultClaim") !== "MENTOR" ? (
                <Breadcrumb className='p-1'>
                    <Breadcrumb.Item>
                        <Link to="/"> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard"> Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dashboard/mentors"> WorkHistorys</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit WorkHistory' : 'Add WorkHistory'}</Breadcrumb.Item>
                </Breadcrumb>) : (null)}
            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6} xs={12} className='mb-5'>
                        <Form.Group controlId="formCompanyName">
                            <Form.Label>Company name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                name="companyName"
                                value={workHistory.companyName}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('company name is required')}
                            />
                        </Form.Group>



                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={workHistory.description}
                                onChange={handleDescriptionEditorChange}
                                placeholder="Write ..."
                            />
                        </Form.Group>





                        <Form.Group controlId="formImage">
                            <Form.Label>Company Image</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Image URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setImageFile(null); // Clear file if URL is selected
                                    }}
                                />
                                {workHistory.image && (
                                    <div className="uploaded-image-preview">
                                        <img src={workHistory.image} alt="Preview" />
                                    </div>
                                )}

                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL"
                                    name="image"
                                    value={workHistory.image}
                                    onChange={handleChange}
                                    isInvalid={validationErrors.includes('Image is required')}
                                    disabled={!isUrlSelected} // Disable if not providing URL
                                />
                                <Form.Check
                                    type="radio"
                                    label="Upload Image"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setWorkHistory((prevCourse) => ({ ...prevCourse, image: '' })); // Clear URL if image is selected
                                    }}
                                />
                                {imageFile && (
                                    <div className="uploaded-image-preview">
                                        <img src={URL.createObjectURL(imageFile)} alt="Preview" />
                                    </div>
                                )}
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageFileChange}
                                    disabled={isUrlSelected} // Disable if URL is selected
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={12} className='mb-5'>
                        <Form.Group controlId="formIsCurrent">
                            <Form.Label>Is Current Company</Form.Label>
                            <div className="badge-card-container">
                                {['true', 'false'].map((badge) => (
                                    <div
                                        key={badge}
                                        className={`badge-${badge.toLowerCase()} badge-card ${workHistory.isCurrent === badge ? 'selected' : ''}`}
                                        onClick={() => handleBadgeTextChange(badge)}
                                    >
                                        {badge}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formAchivements">
                            <Form.Label>Achivements</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter achivements"
                                name="achivements"
                                value={workHistory.achivements}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>

                    <Col md={3} xs={12} className='mb-5'>
                        <Form.Group controlId="formwebLink">
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter web link"
                                name="webLink"
                                value={workHistory.webLink}
                                onChange={handleChange}
                                isInvalid={validationErrors.includes('website is required')}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSkills">
                            <Form.Label>Skills ( comma seperated)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5} placeholder="Enter Skills"
                                name="skills"
                                value={workHistory.skills}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveWorkHistory} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditMentorWorkPage;
