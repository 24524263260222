import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { searchService } from '../../services/SearchService';
import { Course } from '../../data/coursesData';
import { Tutorial } from '../../data/tutorialsData';
import CourseCard from '../../components/CourseCard';
import { Helmet } from 'react-helmet-async';
import './Search.scss';
import { InterviewModel } from '../../data/interviewData';

const Search: React.FC = () => {
    const [query, setQuery] = useState<string>('');
    const [courses, setCourses] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [tutorials, setTutorials] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [cartItems, setCartItems] = useState<number>(0);
    const [interviews, setInterviews] = useState<any[]>([]);


    const handleAddToCart = () => {
        setCartItems(cartItems + 1);
    };

    const navigate = useNavigate();
    const { search } = useLocation();
    const searchQ = new URLSearchParams(search).get('query');

    useEffect(() => {
        if (searchQ) {
            setQuery(searchQ);
            performSearch(searchQ);
        }
    }, [searchQ]);

    let debounceTimeout: NodeJS.Timeout;

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);
        navigate(`/search?query=${value}`, { replace: true });

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        debounceTimeout = setTimeout(() => {
            performSearch(value);
        }, 1000);
    };

    const performSearch = async (query: string) => {
        if (query.trim() === '') return;

        setLoading(true);
        try {
            const results = await searchService.searchAll(query);
            setCourses(results.courses || []);
            setCategories(results.categories || []);
            setTutorials(results.tutorials || []);
            setInterviews(results.interviews || []);
        } catch (error) {
            console.error('Error fetching search results', error);
        } finally {
            setLoading(false);
        }
    };

    const resetSearch = () => {
        setQuery('');
        setCourses([]);
        setCategories([]);
        setTutorials([]);
        navigate('/search', { replace: true });
    };

    const onCategoryClick = (categoryDisplayId: string) => {
        navigate(`/courses?course-category=${categoryDisplayId}`);
    };

    return (
        <div className="container searchPage">
            {/* Dynamic SEO Metadata */}
            <Helmet>
                <title>{query ? `Search Results for "${query}" - Course Echo` : 'Search | Explore Courses, Tutorials & Categories At Course Echo'}</title>
                <meta
                    name="description"
                    content={
                        query
                            ? `Find the best results for "${query}" including courses, categories, and tutorials at course echo.`
                            : 'Search and explore a wide range of courses, tutorials, and categories to enhance your knowledge at course echo.'
                    }
                />
                <meta
                    name="keywords"
                    content={`course echo, search, courses, tutorials, categories, ${query || ''}`}
                />
            </Helmet>

            <h1 className="text-center"><span className='highlight'>Discover Knowledge</span> at Your Fingertips</h1>
            <p className="text-center">
                Search through thousands of courses, tutorials, and categories to unlock your potential.
                Find the resources that match your goals and start learning today!
            </p>

            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label htmlFor="query">Search</label>
                    <input
                        type="text"
                        id="query"
                        className="form-control"
                        value={query}
                        onChange={handleSearchChange}
                        placeholder="Enter search term"
                    />
                </div>
                <div className="button-container">
                    <button type="button" className="btn btn-primary mt-2" disabled={loading}>
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                    <button type="button" className="btn btn-secondary mt-2 ml-2" onClick={resetSearch}>
                        Reset
                    </button>
                </div>
            </form>

            {query && !loading && (
                <div className="mt-4">
                    {courses.length > 0 && (
                        <div className="result-section">
                            <h6 className='mb-5'>Courses <sup>({courses.length})</sup></h6>
                            <div className="row">
                                {courses.map((course: Course) => (
                                    <div className="col-md-4 col-sm-12 mb-3" key={course.id}>
                                        <CourseCard course={course} onAddToCart={handleAddToCart} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {categories.length > 0 && (
                        <div className="result-section">
                            <h6 className='mb-4'>Categories <sup>({categories.length})</sup></h6>
                            <div className="row">
                                {categories.map((category) => (
                                    <div
                                        key={category.categoryDisplayId}
                                        className="col-sm-12 col-md-4"
                                        onClick={() => onCategoryClick(category.categoryDisplayId)}
                                    >
                                        <div
                                            className="categoryCrd mb-2"
                                            style={{
                                                backgroundImage: `url(${category.backgroundImageLink})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                            }}
                                        >
                                            <br></br>
                                            <i className={`category-icon fa ${category.categoryIcon}`} />
                                            <h1 className="mt-1">{category.name}</h1>
                                            <p title={category.description}>{category.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {tutorials.length > 0 && (
                        <div className="result-section">
                            <h6>Tutorials <sup>({tutorials.length})</sup></h6>
                            <ul>
                                {tutorials.map((tutorial: Tutorial) => (
                                    <li key={tutorial.id}>
                                        <Link to={`/tutorials-details/${tutorial.courseId}`}>
                                            {tutorial.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

{interviews.length > 0 && (
                        <div className="result-section">
                            <h6>Interviews <sup>({interviews.length})</sup></h6>
                            <div className="row">
                                {interviews.map((interview) => (
                                          <div key={interview.id} className="col-12 col-md-3 col-lg-3 mb-4 ">
                                            <Link to={`/interview-details/${interview.interviewDisplayId}/${interview.id}`} className='NormalLink'>
                                              <div
                                                className="interview-card shadow-sm"
                                                style={{ cursor: 'pointer' }}
                                              >
                                
                                                {/* <img
                                                  src={interview.image}
                                                  alt={interview.title}
                                                  className="img-fluid rounded interview-img"
                                                  height={200}
                                                  width={200}
                                                /> */}
                                
                                                <h5 className="mt-1 mb-3 text-center">{interview.title}</h5>
                                                <div className='mt-3 interview-description'  dangerouslySetInnerHTML={{ __html: interview?.description || '' }} />
                                              </div>
                                            </Link>
                                          </div>
                                        ))}
                            </div>
                        </div>
                    )}

                    {courses.length === 0 && categories.length === 0 && tutorials.length === 0 && interviews.length === 0 && (
                        <p>No results found for "{query}".</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Search;
