import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'; // Import Link
import './CallRequestPage.scss';
import { Author } from '../../data/authorData';
import { getTopAuthors } from '../../services/DashboardService';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const TopAuthorPage: React.FC = () => {
  const [Authors, setAuthor] = useState<Author[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTopAuthors();
        setAuthor(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = Authors.filter((request) => {
    return (
      request.name.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
        <Table striped hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>Author</th>
              <th>Revenue</th>
              <th>Sold QTY</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>
                <Link
                    className="me-2 text-primary"
                    to={`/mentor-details/${request.name}/${request.id}`} // Pass the Author ID for editing
                  >
                  {request.name}
                  </Link>
                  </td>
                <td  className='text-center'>
                  <i className='fa fa-inr text-danger'></i>
                  {request.totalRevenue}</td>
                <td className='text-center'>
                  #{request.totalOrders}</td>
              </tr>
            ))}
          </tbody>
        </Table>

  );
};

export default TopAuthorPage;
