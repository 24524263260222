import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { useAuth } from '../../context/AuthContext';
import './Signup.scss'; // Import custom CSS if needed
import { ENDPOINT } from '../../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
interface MentorProps {
  isImported: boolean;
}
const MentorSignup: React.FC<MentorProps> = ({ isImported }) => {
  const navigate = useNavigate();
  if (localStorage.getItem('token')) {
    switch (localStorage.getItem('defaultClaim')) {
      case 'MANAGER':
        navigate('/dashboard')
        break;
      case 'SUPERADMIN':
        navigate('/dashboard')
        break;
      case 'MENTOR':
        navigate('/dashboard')
        break;
      case 'STUDENT':
        navigate('/dashboard')
        break;
      default:
        navigate('/dashboard')
    }
  }
  const [userData, setUserData] = useState({ name: '', email: '', mobile: '', password: '', insertDate: new Date(), updateDate: new Date(), defaultClaim: 'MENTOR' });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [loading, setLoading] = useState(false); // State to handle loading
  const [error, setError] = useState<string | null>(null); // State to handle errors
  const { login } = useAuth(); // Simulate login after signup

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner
    setError(null); // Reset error state

    try {
      // API call to save user data (replace URL with actual endpoint)
      const response = await axios.post(`${ENDPOINT}/users`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`

        }
      });

      // Handle API response
      if (response.status === 201) {
        login(userData.email, response.data.token, response.data.defaultClaim); // Simulate login after successful signup
        switch (response.data.defaultClaim) {
          case 'MANAGER':
            navigate('/dashboard')
            break;
          case 'SUPERADMIN':
            navigate('/dashboard')
            break;
          case 'MENTOR':
            navigate('/dashboard')
            break;
          case 'STUDENT':
            navigate('/dashboard')
            break;
          default:
            navigate('/dashboard')
        }
        window.location.reload();
      } else {
        setError('Signup failed. Please try again.');
      }
    } catch (err: any) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false); // Hide loading spinner after request completion
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container signups">
      {!isImported ? (
        <Helmet>
          <title>Signup mentor - Course Echo | Create Your Account</title>
          <meta
            name="description"
            content="Sign up as a mentor on Course Echo to share your expertise, create courses, and inspire learners globally. Start your journey today!"
          />
          <meta
            name="keywords"
            content="Course Echo, mentor signup, create courses, share knowledge, educational platform, online teaching, sell courses"
          />
          <meta name="author" content="Course Echo Team" />

          {/* Open Graph meta tags for social media sharing */}
          <meta property="og:title" content="Signup mentor - Course Echo | Create Your Account" />
          <meta
            property="og:description"
            content="Sign up as a mentor on Course Echo to share your expertise, create courses, and inspire learners globally. Start your journey today!"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://courseecho.com/signup" />
          <meta
            property="og:image"
            content="https://images.courseecho.com/dark-logo.webp"
          />
          <meta property="og:image:alt" content="Course Echo Logo" />

          {/* Twitter card meta tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Signup mentor - Course Echo | Create Your Account" />
          <meta
            name="twitter:description"
            content="Sign up as a mentor on Course Echo to share your expertise, create courses, and inspire learners globally. Start your journey today!"
          />
          <meta
            name="twitter:image"
            content="https://images.courseecho.com/dark-logo.webp"
          />
          <meta name="twitter:image:alt" content="Course Echo Logo" />
        </Helmet>
      ) : (null)}
      <div className="row justify-content-center">

        <div className="col-12" id='joinMentor'>
          <div className="shadowBox min-height">
            <div className=" formDiv">
              <h2 className="card-title text-center whiteScreenColor pt-5 detail-h1">  Become a Mentor, Inspire Lifelong Learning
              </h2>
              <p className="text-center whiteScreenColor subHeading">
                Join our global community of mentors, share your knowledge, and make a difference in the lives of learners worldwide.
                <br></br>
                 Sign up today and start teaching with Course Echo!

              </p>

              <form onSubmit={handleSubmit} className='login-form'>
                <div className="">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={userData.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile"
                    value={userData.mobile}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className=" input-group">
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    name="password"
                    placeholder="Password"
                    value={userData.password}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                  <span
                    className="input-group-text bg-white border-0 passIcon"
                    onClick={togglePasswordVisibility}
                  >
                    <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-primary w-100 mt-1 loginbtn navBtn" disabled={loading}>
                  {loading ? 'Signing Up...' : 'Signup'}
                </button>
              </form>
            </div>
            <div className='text-center bottomText'>
              <p className='text-center'> <span className="text-primary">Already mentor? </span>
                <Link to='/login' className='secandoryText noUnderLine'>Login now</Link>

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorSignup;
