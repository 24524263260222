// src/data/coursesData.ts

import { UserModel } from "../pages/profile/model/User.model";
import { Author } from "./authorData";
export interface TopicProgress
{
  id?:string;
  courseId:string;
  topicId:string;
  status?:string;
  statusData:string;
  userId:string;
  dateAdded?: string;
  dateUpdated?: string;
}
export interface Course {
  id: number;
  title: string;
  description: string;
  keywords: string;
  image: string;
  fee: number;
  rating: number;
  numberOfTopics: number;
  badgeText: string; // Badge text (e.g., "Premium")
  source: string; // Add this property to define if the course is local or external
  externalLink?: string; // Optional external link
  categoryDisplayId?: string;
  courseDisplayId?: string;
  Topics?: Array<Topics>;
  Resources?: Array<CourseResource>;
  Prerequisites?: Array<CoursePrerequisit>;
  Faqs?: Array<CourseFaq>;
  Tests?: Array<CourseTest>;
  objective?: string;
  CourseFor?: Array<CourseFor>;
  author?: Author;
  authorId?: string;
  projects?: Array<CourseProject>;
  certificate?: Array<CourseCertificate>;
  CoursePerksBenefits?: Array<CoursePerksBenifites>
  learnings?: CourseLearnings;
  coupons?: Array<CourseCoupons>;
  discount?: number;
  CourseBulletPoints?: Array<CourseBulletPoints>;
  orders?: Array<CourseOrders>
  syllabusUrl?: string;
  dateAdded?: string;
  dateUpdated?: string;
  status?: string;
  totalPrice?:string;
  totalOrders?:string;
  CourseCount?:CountData;

}
export interface CountData {
  id?: string;
  courseId?:string;
  pageUrl?:string;
  userId?:string;
  buyNowCount?:string;
  viewDetailCount?:string;
  watchNowCount?:string;
  dateAdded?: string;
  dateUpdated?: string;
  metaData?:string;
  countColumn?:string;
}
export interface CourseOrders {
  id?: number;
  userId: string;
  quantity: number;
  totalPrice: number;
  orderDate: string;
  status: string;
  created_at: string;
  updated_at: string;
  email?: string;
  mobile?: string;
  name?: string;
  courseId: number;
  course?: Course;
}
export interface CourseBulletPoints {
  id?: string;
  title: string;
  icon?: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;
}
export interface CourseCoupons {
  id?: number;
  code: string;
  price: number;
  isExpired: boolean;
  courseId: number;

}
export interface CourseLearnings {
  id?: number;
  title: string;
  topicList?: Array<CourseLearningsTopics>
  courseId: number;

}
export interface CourseLearningsTopics {
  id?: number;
  title: string;
  icon?: string;
  learningId: string;
}
export interface CoursePerksBenifites {
  id?: string;
  title: string;
  description: string;
  icon?: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;

}
export interface CourseCertificate {
  id?: number;
  title: string;
  description: string;
  image?: string;
  courseId: number;

}
export interface CourseProject {
  id?: number;
  title: string;
  description: string;
  image?: string;
  link?: string;
  features?: Array<ProjectFeatures>;
  courseId: number;

}
export interface ProjectFeatures {
  id?: number;
  title: string;
  courseId: number;

}
export interface CourseAuthor {
  id?: number;
  title: string;
  description: string;
  image?: string;
  linkedin?: string;
  courseId: number;

}

export interface CourseFor {
  id?: string;
  title: string;
  description: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;

}
export interface CourseTest {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId: number;
  options: Array<string>;
  correctAnswer: string;
  dateAdded?: string;
  dateUpdated?: string;
}
export interface CourseFaq {
  id: string;
  title: string;
  description: string;
  externalLink?: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;

}
export interface CoursePrerequisit {
  id: string;
  title: string;
  description: string;
  externalLink?: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;

}
export interface CourseResource {
  id: string;
  title: string;
  description: string;
  externalLink?: string;
  courseId: number;
  dateAdded?: string;
  dateUpdated?: string;

}
export interface CourseQA {
  id: string;
  title: string;
  description: string;
  courseId: string;
  userId: string;
  status:string;
  dateAdded?: string;
  dateUpdated?: string;
  CourseQAReply?:Array<CourseQAReply>;
  course?:Course;
  user?:UserModel;
  upvotes?:number;
}
export interface CourseQAReply {
  id: string;
  title: string;
  description: string;
  userId: string;
  status:string;
  dateAdded?: string;
  dateUpdated?: string;
  user?:UserModel;
}
export interface Topics {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  parentId?: number;
  source: string;
  isFree: boolean;
  courseId: number;
  isTrailer: string;
  dateAdded?: string;
  dateUpdated?: string;
  TopicProgress?:TopicProgress;
}

const courses: Course[] = [];

export default courses;
