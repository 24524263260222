import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CourseFaq } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourseFaq, updateCourseFaq } from '../../services/DashboardService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditCourseFaqPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [courseFaq, setCourseFaq] = useState<CourseFaq>({
        id: '',
        title: '',
        description: '',
        courseId: courseid ? parseInt(courseid) : 0
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.Faqs?.find((v: CourseFaq) => v.id === id);
                    if (foundItem) setCourseFaq(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCourseFaq((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (courseFaq: CourseFaq): string[] => {
        const errors: string[] = [];
        if (!courseFaq.title) errors.push('Title is required.');
        if (!courseFaq.description) errors.push('Description is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(courseFaq);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCourseFaq(courseFaq.id || '', courseFaq);
            } else {
                await addCourseFaq(courseFaq);
            }
            navigate(`/dashboard/course-faq/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateChange = (title: string, description: string) => {
        setCourseFaq((prev) => ({ ...prev, title: title, description: description }));

    };
    const handleEditorChange = (value: string) => {
        setCourseFaq((prev) => ({ ...prev, description: value }));
    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit perks & reward' : 'Add New perks & reward'} - Dashboard`}
                description="Dashboard to add/edit perks & reward"
                keywords="dashboard, perks & reward management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/course-faq/${courseid}`}>Course FAQ ({courseItem?.CoursePerksBenefits?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            <Row>

                <Col md={12} xs={12}>
                    <h5>Use Templates: </h5>
                    <div className="perks-card-container">
                        {[
                            {
                                title: `📦 What does this ${courseItem?.title} course include?`,
                                description: '📦 This course provides in-depth high-quality video lectures, downloadable resources, quizzes, and real-world projects to enhance your learning experience.',
                            },
                            {
                                title: '♾️ Do I get lifetime access to the course?',
                                description: '♾️ Yes, once you enroll, you will have lifetime access to all the course materials, including any future updates.',
                            },
                            {
                                title: '🎓 Is prior experience required?',
                                description: '🎓 No prior experience is required. This course is designed for both beginners and professionals seeking to enhance their skills.',
                            },
                            {
                                title: '📁 What kind of projects will I work on?',
                                description: '📁 You will work on hands-on projects that simulate real-world challenges, helping you apply the concepts you learn effectively.',
                            },
                            {
                                title: '🏅 Will I receive a certificate upon completion?',
                                description: '🏅 Yes, upon successful completion of the course, you will receive a professional certificate to showcase your skills and accomplishments.',
                            },
                            {
                                title: '🤝 What support is available during the course?',
                                description: '🤝 You will have access to a vibrant community of learners and instructors to help you resolve doubts and share insights.',
                            },
                            {
                                title: '📱 Can I access the course on mobile devices?',
                                description: '📱 Yes, the course is fully responsive and accessible on mobile devices, tablets, and desktops for your convenience.',
                            },
                            {
                                title: '✅ Are there any prerequisites for this course?',
                                description: '✅ No prerequisites are required. This course is designed to cater to learners of all levels, from beginners to advanced.',
                            },
                            {
                                title: '💬 Can I interact with the instructor?',
                                description: '💬 Yes, you can interact with the instructor via <strong>Q&A forums</strong>, live sessions (if available), and community discussions.',
                            },
                            {
                                title: '🔄 How do I access updates to the course?',
                                description: '🔄 Once enrolled, you will automatically receive updates to the course content at no additional cost.',
                            },
                        ].map((item, index) => (
                            <div
                                key={index}
                                className={`perks-common perks-card ${item.title === courseFaq?.title ? 'selected' : ''}`}
                                onClick={() => handleTemplateChange(item.title, item.description)}
                            >
                                <h6>{item.title}</h6>
                                <p dangerouslySetInnerHTML={{ __html: item?.description || '' }} />

                            </div>
                        ))}
                    </div>

                </Col>
            </Row>
            <hr></hr>
            <Form>
                <Row>
                    <h6>Add Custom:</h6>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={courseFaq.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIcon">
                            <Form.Label>External Link
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter link"
                                name="externalLink"
                                value={courseFaq.externalLink}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formIcon">
                            <Form.Label>Description
                            </Form.Label>
                            <ReactQuill
                                value={courseFaq.description}
                                onChange={handleEditorChange}
                                placeholder="Write..."
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditCourseFaqPage;
