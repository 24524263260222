import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./../../components/AuthorDetails.scss";
import {
  addAuthorSocialLink,
  addAuthorWorkHistory,
  deleteAuthorSocialLink,
  deleteAuthorWorkHistory,
  editAuthorsByToken,
  getAuthorsByToken,
  updateAuthorSocialLink,
  updateAuthorWorkHistory,
} from "../../services/DashboardService";
import { Author, SocialLink, WorkHistory } from "../../data/authorData";

const AuthorEditDetails: React.FC = () => {
  const [author, setAuthor] = useState<Author | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [mentorBanner, setMentorBanner] = useState<string>("");
  // State for adding/editing work history and social link
  const [newWorkHistory, setNewWorkHistory] = useState<WorkHistory>({
    id: "",
    companyName: "",
    image: "",
    isCurrent: "",
    skills: "",
    achivements: "",
    webLink: "",
    authorId: "",
  });

  const [newSocialLink, setNewSocialLink] = useState<SocialLink>({
    id: "",
    title: "",
    description: "",
    link: "",
    image: "",
    authorId: "",
  });
  const getRandomBannerImage = () => {
    const banners = [
      "https://images.courseecho.com/backgrounds/contact-banner-1.webp",
      "https://images.courseecho.com/backgrounds/contact-banner.webp",
    ];
    return banners[Math.floor(Math.random() * banners.length)];
  };
  const [isUpdated, setUpdated] = useState<boolean>(true);
  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        setLoading(true);
        const data = await getAuthorsByToken();
        setMentorBanner(getRandomBannerImage());
        setAuthor(data[0]); // Assuming the response is an array
        setError(null);
      } catch (err) {
        console.error("Error fetching author details:", err);
        setError("Failed to load author details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAuthorDetails();
  }, [isUpdated]);


  const handleDeleteWorkHistory = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this work history?")) return;
    try {
      setLoading(true);
      await deleteAuthorWorkHistory(id);
      setUpdated(true)

    } catch (err) {
      console.error("Error deleting work history:", err);
      setError("Failed to delete work history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSocialLink = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this social link?")) return;
    try {
      setLoading(true);
      await deleteAuthorSocialLink(id);
      setUpdated(true)

    } catch (err) {
      console.error("Error deleting social link:", err);
      setError("Failed to delete social link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddWorkHistory = async () => {
    try {
      setLoading(true);
      await addAuthorWorkHistory(newWorkHistory);
      setNewWorkHistory({
        id: "",
        companyName: "",
        image: "",
        isCurrent: "",
        skills: "",
        achivements: "",
        webLink: "",
        authorId: author?.id || "",
      });
      setUpdated(true);
    } catch (err) {
      console.error("Error adding work history:", err);
      setError("Failed to add work history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddSocialLink = async () => {
    try {
      setLoading(true);
      await addAuthorSocialLink(newSocialLink);
      setNewSocialLink({
        id: "",
        title: "",
        description: "",
        link: "",
        image: "",
        authorId: author?.id || "",
      });
      setUpdated(true);
    } catch (err) {
      console.error("Error adding social link:", err);
      setError("Failed to add social link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateWorkHistory = async (updatedWorkHistory: WorkHistory) => {
    try {
      setLoading(true);
      await updateAuthorWorkHistory(updatedWorkHistory);
      setUpdated(true);
    } catch (err) {
      console.error("Error updating work history:", err);
      setError("Failed to update work history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSocialLink = async (updatedSocialLink: SocialLink) => {
    try {
      setLoading(true);
      await updateAuthorSocialLink(updatedSocialLink);
      setUpdated(true);
    } catch (err) {
      console.error("Error updating social link:", err);
      setError("Failed to update social link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-5">Loading author details...</div>;
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5">{error}</div>
    );
  }

  if (!author) {
    return (
      <div className="text-center mt-5">
        <p>No author details found.</p>
        <Link to="/" className="navBtn mt-4">
          Back to Authors
        </Link>
      </div>
    );
  }

  return (
    <div className="container authorDetailsPage">
      <Helmet>
        <title>{author.name} - Mentor Details | Course Echo</title>
        <meta
          name="description"
          content={`Learn more about ${author.name}, including their work history and social links.`}
        />
      </Helmet>
      <div className="row shadow">
      <div className="col-sm-12">
          <h2 className=" detail-h1 text-center">Mentor Profile
          </h2>
        </div>
        <div className="col-sm-12">
          <div
            className="card mx-auto d-flex customd-flex  align-items-center mb-4 authorImgCard"
            style={{
              backgroundImage: `url(${mentorBanner})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <img
              src={author.image}
              className="card-img-top img-fluid shadow"
              style={{ width: "200px" }}
              alt={author.name}
            />
            <div className="card-body shadow">
              <div className="card-text" dangerouslySetInnerHTML={{ __html: author?.description || '' }} />

            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <h2 className="sectionH detail-h1">Description <Link to={`/dashboard/update-mentor/${author.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
          </Link>
          </h2>

          <div dangerouslySetInnerHTML={{ __html: author?.description || '' }} />


        </div>

        <div className="col-sm-12">
          <h2 className="sectionH detail-h1">Work History
            <Link to={`/dashboard/add-mentor-work/${author.id}`}><i className="fa fa-plus-circle" title="Click to add"></i>
            </Link>
          </h2>
          <ul className="list-group mb-4">
            {author.WorkHistory?.map((work) => (
              <li key={work.id} className="list-group-item">
                <div className="row">
                  <div className="col-11">
                    <h3>
                      <a href={work.webLink} title={`Visit to ${work.companyName}`} target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${work.image}`} // Replace with appropriate URL or path
                          alt={work.companyName}
                          className="social-icon me-2"
                          style={{ width: '50px' }} // Small icon size
                        />{work.companyName}
                      </a>
                    </h3>
                    {work.description && (
                      <div dangerouslySetInnerHTML={{ __html: work?.description || '' }} />
                    )}
                    {/* Styling for "Currently working" */}
                    <div className="badge-card-container">
                        <div
                          key={work.isCurrent}
                          className={`badge-${work.isCurrent?.toLowerCase()} badge-card ${work.isCurrent ? 'selected' : ''}`}
                        >
                          {work.isCurrent === 'true' ? 'Currently working' : 'Previously worked'}
                        </div>
                    </div>

                    {work.skills && (
                      <p>
                        <strong>Skills:</strong> {work.skills}
                      </p>
                    )}
                    {work.achivements && (
                      <p>
                        <strong>Achievements:</strong> {work.achivements}
                      </p>
                    )}
                  </div>
                  <div className="col-1">
                    <span>
                      <Link to="#" onClick={() => handleDeleteWorkHistory(work.id)}><i className="fa fa-trash text-danger" title="Click to delete"></i>
                      </Link> <hr></hr><Link to={`/dashboard/edit-mentor-work/${author.id}/${work.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
                      </Link>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="col-sm-12">
          <h2 className="sectionH detail-h1">Social Links

            <Link to={`/dashboard/add-mentor-social/${author.id}`}><i className="fa fa-plus-circle" title="Click to add"></i>
            </Link>

          </h2>

          <ul className="list-group mb-4">
            {author.SocialLink?.map((link) => (
              <li key={link.id} className="list-group-item">
                <div className="row">
                  <div className="col-11">
                    {/* Add icon for social link */}
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={`${link.image}`} // Replace with appropriate URL or path
                        alt={link.title}
                        className="social-icon me-2"
                        style={{ width: '50px' }} // Small icon size
                      />
                    </a>
                    {link.description && (
                      <div dangerouslySetInnerHTML={{ __html: link?.description || '' }} />
                    )}
                  </div>
                  <div className="col-1">
                    <span>
                      <Link to="#" onClick={() => handleDeleteSocialLink(link.id)}><i className="fa fa-trash text-danger" title="Click to delete"></i>
                      </Link> <hr></hr><Link to={`/dashboard/edit-mentor-social/${author.id}/${link.id}`}><i className="fa fa-pencil" title="Click to edit"></i>
                      </Link>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuthorEditDetails;
