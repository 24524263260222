import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import courses from './data/coursesData'; // Import the courses data
import './styles.scss';
import CartPage from './pages/CartPage';
import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import Profile from './pages/profile/Profile';
import CareerPage from './pages/CareerPage';
import OfflineClass from './pages/services/OfflineClass';
import OnlineClass from './pages/services/OnlineClass';
import ProjectRequirement from './pages/services/ProjectRequirement';
import RoadmapPage from './pages/roadmap/RoadmapPage';
import CourseDetails from './components/CourseDetails';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsAndConditions from './pages/legal/TermsAndConditions';
import CancellationAndRefund from './pages/legal/CancellationAndRefund';
import ShippingAndDelivery from './pages/legal/ShippingAndDelivery';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from './pages/NotFound';
import { ToastContainer } from 'react-toastify';
import TutorialsPage from './pages/tutorials/tutorials';
import { tutorials } from './data/tutorialsData';
import TutorialsDetails from './components/TutorialsDetails';
import GoogleLoginComponent from './components/GoogleLoginComponent';
import Mentor from './pages/Mentor';
import AuthorDetails from './components/AuthorDetails';
import AuthorsPage from './pages/authors/AuthorsPage';
import { authorList } from './data/authorData';
import Search from './pages/search/Search';
import Dashboard from './pages/dashboard/Dashboard';
import CallRequestsPage from './pages/dashboard/CallRequestPage';
import PrivateRoute from './components/PrivateRoute';
import CourseInqueryPage from './pages/dashboard/CourseInqueryPage';
import StudentsPage from './pages/dashboard/StudentsPage';
import MentorPage from './pages/dashboard/MentorsPage';
import CoursesPage from './pages/dashboard/CoursesPage';
import OrdersPage from './pages/dashboard/OrdersPage';
import InterviewsList from './pages/interviews/InterviewsList';
import InterviewDetails from './pages/interviews/InterviewDetails';
import InterviewQuestionDetails from './pages/interviews/InterviewQuestionDetails';
import AddEditCoursePage from './pages/dashboard/AddEditCoursePage';
import TopicsPage from './pages/dashboard/TopicsPage';
import AddEditTopicPage from './pages/dashboard/AddEditTopicPage';
import BulletPointsPage from './pages/dashboard/BulletPointsPage';
import AddEditBulletPointPage from './pages/dashboard/AddEditBulletPointPage';
import CourseForPage from './pages/dashboard/CourseForPage';
import AddEditCourseForPage from './pages/dashboard/AddEditCourseForPage';
import PerksRewardPage from './pages/dashboard/PerksRewardPage';
import AddEditPerkRewardPage from './pages/dashboard/AddEditPerkRewardPage';
import CourseFaqPage from './pages/dashboard/CourseFaqPage';
import AddEditCourseFaqPage from './pages/dashboard/AddEditCourseFaqPage';
import CourseResourcePage from './pages/dashboard/CourseResourcePage';
import AddEditCourseResourcesPage from './pages/dashboard/AddEditCourseResourcesPage';
import CoursePrerequisitPage from './pages/dashboard/CoursePrerequisitPage';
import AddEditCoursePrerequisitPage from './pages/dashboard/AddEditCoursePrerequisitPage';
import CourseQuizPage from './pages/dashboard/CourseQuizPage';
import AddEditCourseQuizPage from './pages/dashboard/AddEditCourseQuizPage';
import InterviewPage from './pages/dashboard/InterviewPage';
import AddEditInterviewPage from './pages/dashboard/AddEditInterviewPage';
import InterviewQuestionPage from './pages/dashboard/InterviewQuestionPage';
import AddEditInterviewQuestionPage from './pages/dashboard/AddEditInterviewQuestionPage';
import Sidenav from './components/Sidenav';
import UserCourses from './components/UserCourses';
import MentorSignup from './pages/authentication/MentorSignup';
import AddEditMentorPage from './pages/dashboard/AddEditMentorPage';
import AddEditMentorSocialPage from './pages/dashboard/AddEditMentorSocialPage';
import AddEditMentorWorkPage from './pages/dashboard/AddEditMentorWorkPage';
import MentorEarningCalculatorPage from './pages/MentorEarningCalculatorPage';
import QADetailPage from './components/QADetailPage';
import CampusCounselorPage from './pages/campus/CampusCounselorPage';
import CampusCounselorSignup from './pages/authentication/CampusCounselorSignup';
import AddEditCounselorPage from './pages/dashboard/AddEditCounselorPage';
import AddEditCounselorCollegePage from './pages/dashboard/AddEditCounselorCollegePage';
import AddEditCounselorSchoolPage from './pages/dashboard/AddEditCounselorSchoolPage';
import CounselorDetails from './components/CounselorDetails';
import SessionBooking from './pages/campus/SessionBooking';
import SessionBookedForMe from './components/SessionBookedForMe';
import SessionBookedByMe from './components/SessionBookedByMe';
import ChatComponent from './components/ChatComponent';
import CampusCounselorIntro from './components/CampusCounselorIntro';
const Footer = React.lazy(() => import('./components/Footer'));

const App: React.FC = () => {
  const claim = localStorage.getItem('defaultClaim') || '';
  const [isSidenavActive, setSidenavActive] = useState(true);
  const ClsName = claim !== '' ? (isSidenavActive ? 'main-content main-content-collapsed' : 'main-content main-content-expanded') : 'main-c'
  const [isMobile, setIsMobile] = useState(false);


  // Set the sidenav to a specific state (true or false)
  const toggleSidenav = (status: boolean) => {
    setSidenavActive(status);  // Directly set the status, don't toggle it
  };

  // Detect if the device is mobile or desktop
  useEffect(() => {
    const handleResize = () => {
      const isM = window.innerWidth <= 768
      if (!isM) toggleSidenav(false)
      setIsMobile(isM); // Update mobile state based on screen width
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Add resize event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <ToastContainer position="top-right" autoClose={3000} />
        <GoogleLoginComponent isLoginScreen={false} />
        <Navbar />
        <Sidenav isSidenavActive={isSidenavActive} toggleSidenav={toggleSidenav} />

        <div className={ClsName}>
          {/* <div className="container"> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/courses" element={<Courses courses={courses} isImported={false} />} /> {/* Pass the courses prop */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/course-details/:courseDisplayid/:courseid" element={<CourseDetails course={courses[0]} />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/services/offline-class" element={<OfflineClass />} />
            <Route path="/services/online-class" element={<OnlineClass />} />
            <Route path="/services/project" element={<ProjectRequirement />} />
            <Route path="/resources/roadmap" element={<RoadmapPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cancellation-refund-policy" element={<CancellationAndRefund />} />
            <Route path="/shipping-delivery-policy" element={<ShippingAndDelivery />} />
            <Route path="/tutorials" element={<TutorialsPage tutorials={tutorials} isImported={false} />} /> {/* Pass the courses prop */}
            <Route path="/tutorials-details/:courseId" element={<TutorialsDetails />} />
            <Route path="/mentor" element={<Mentor />} />
            <Route path="/mentors" element={<AuthorsPage authors={authorList} isImported={false} />} /> {/* Pass the courses prop */}
            <Route path="/mentor-details/:name/:id" element={<AuthorDetails />} />
            <Route path="/search" element={<Search />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/callrequest" element={<CallRequestsPage />} />
            <Route path="/dashboard/courseinquery" element={<CourseInqueryPage />} />
            <Route path="/dashboard/students" element={<StudentsPage />} />
            <Route path="/dashboard/mentors" element={<MentorPage />} />
            <Route path="/dashboard/courses" element={<CoursesPage />} />
            <Route path="/dashboard/orders/:status" element={<OrdersPage />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/interviews" element={<InterviewsList isImported={false} />} /> {/* Pass the courses prop */}
            <Route path="/interview-details/:interviewDisplayId/:id" element={<InterviewDetails />} />
            <Route path="/interview-question/:questionDisplayId/:interview_id/:id" element={<InterviewQuestionDetails />} />

            <Route path="/dashboard/add-course/" element={<AddEditCoursePage />} />
            <Route path="/dashboard/edit-course/:courseDisplayid/:courseid" element={<AddEditCoursePage />} />

            <Route path="/dashboard/topics/:id" element={<TopicsPage />} />

            <Route path="/dashboard/add-topic/:courseid" element={<AddEditTopicPage />} />
            <Route path="/dashboard/edit-topic/:courseid/:id" element={<AddEditTopicPage />} />

            <Route path="/dashboard/bullet-points/:id" element={<BulletPointsPage />} />

            <Route path="/dashboard/add-bullet-point/:courseid" element={<AddEditBulletPointPage />} />
            <Route path="/dashboard/edit-bullet-point/:courseid/:id" element={<AddEditBulletPointPage />} />


            <Route path="/dashboard/course-for/:id" element={<CourseForPage />} />
            <Route path="/dashboard/add-course-for/:courseid" element={<AddEditCourseForPage />} />
            <Route path="/dashboard/edit-course-for/:courseid/:id" element={<AddEditCourseForPage />} />

            <Route path="/dashboard/perks-rewards/:id" element={<PerksRewardPage />} />
            <Route path="/dashboard/add-perk-reward/:courseid" element={<AddEditPerkRewardPage />} />
            <Route path="/dashboard/edit-perk-reward/:courseid/:id" element={<AddEditPerkRewardPage />} />


            <Route path="/dashboard/course-faq/:id" element={<CourseFaqPage />} />
            <Route path="/dashboard/add-course-faq/:courseid" element={<AddEditCourseFaqPage />} />
            <Route path="/dashboard/edit-course-faq/:courseid/:id" element={<AddEditCourseFaqPage />} />

            <Route path="/dashboard/course-resource/:id" element={<CourseResourcePage />} />
            <Route path="/dashboard/add-course-resource/:courseid" element={<AddEditCourseResourcesPage />} />
            <Route path="/dashboard/edit-course-resource/:courseid/:id" element={<AddEditCourseResourcesPage />} />

            <Route path="/dashboard/course-prerequisit/:id" element={<CoursePrerequisitPage />} />
            <Route path="/dashboard/add-course-prerequisit/:courseid" element={<AddEditCoursePrerequisitPage />} />
            <Route path="/dashboard/edit-course-prerequisit/:courseid/:id" element={<AddEditCoursePrerequisitPage />} />

            <Route path="/dashboard/course-quiz/:id" element={<CourseQuizPage />} />
            <Route path="/dashboard/add-course-quiz/:courseid" element={<AddEditCourseQuizPage />} />
            <Route path="/dashboard/edit-course-quiz/:courseid/:id" element={<AddEditCourseQuizPage />} />

            <Route path="/dashboard/interviews" element={<InterviewPage />} />
            <Route path="/dashboard/add-interview/" element={<AddEditInterviewPage />} />
            <Route path="/dashboard/edit-interview/:interviewDisplayid/:interview_id" element={<AddEditInterviewPage />} />

            <Route path="/dashboard/interview-questions/:id" element={<InterviewQuestionPage />} />

            <Route path="/dashboard/add-interview-question/:interview_id" element={<AddEditInterviewQuestionPage />} />
            <Route path="/dashboard/edit-interview-question/:interview_id/:id" element={<AddEditInterviewQuestionPage />} />
            <Route path="/dashboard/purchased-courses" element={<UserCourses />} />

            <Route path="/join-mentor" element={<MentorSignup isImported={false} />} />
            <Route path="/dashboard/add-mentor" element={<AddEditMentorPage />} />
            <Route path="/dashboard/update-mentor/:id" element={<AddEditMentorPage />} />
            <Route path="/dashboard/add-mentor-social/:id" element={<AddEditMentorSocialPage />} />
            <Route path="/dashboard/edit-mentor-social/:id/:social_id" element={<AddEditMentorSocialPage />} />

            <Route path="/dashboard/add-mentor-work/:id" element={<AddEditMentorWorkPage />} />
            <Route path="/dashboard/edit-mentor-work/:id/:work_id" element={<AddEditMentorWorkPage />} />
            <Route path="/mentor-earning-calculator" element={<MentorEarningCalculatorPage />} />
            <Route path="/course-details/qa/:id/:course_id" element={<QADetailPage />} />
            <Route path="/campus-counselor" element={<CampusCounselorPage />} />
            <Route path="/join-campus-counselor" element={<CampusCounselorSignup isImported={false} />} />


            <Route path="/dashboard/update-counselor/:id" element={<AddEditCounselorPage />} />

            <Route path="/dashboard/add-counselor-college/:id" element={<AddEditCounselorCollegePage />} />
            <Route path="/dashboard/edit-counselor-college/:id/:counselor_id" element={<AddEditCounselorCollegePage />} />

            <Route path="/dashboard/add-counselor-school/:id" element={<AddEditCounselorSchoolPage />} />
            <Route path="/dashboard/edit-counselor-school/:id/:counselor_id" element={<AddEditCounselorSchoolPage />} />
            <Route path="/campus-counselor-details/:id" element={<CounselorDetails />} />
            <Route path="/book-session/:id" element={<SessionBooking />} />
            <Route path="/dashboard/booked-session-for-me" element={<SessionBookedForMe />} />
            <Route path="/dashboard/booked-session-by-me" element={<SessionBookedByMe />} />
            <Route path="/dashboard/session/chat/:id" element={<ChatComponent />} />
            <Route path="/become-a-campus-counselor" element={<CampusCounselorIntro  isImported={false}/>} />



            <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
          </Routes>
          {/* </div> */}
          <Suspense fallback={<div className='container'>Loading footer...</div>}>

            <Footer />
          </Suspense>
        </div>
        {/* <TawkToWidget /> */}
      </Router>
    </HelmetProvider>
  );
};

export default App;
