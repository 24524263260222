import { APPLYNOW } from '../utils/Endpoints';

// URLs for the Google Forms
// const applyFormURL = APPLYNOW; // Replace with the actual form URL
const applyFormURL = '/join-mentor'
export interface JobCategoryModel {
    id: string;
    title: string;
    description: string;
    jobs?: Array<JobModel>;
}
export interface JobModel {
    id: number;
    title: string;
    location: string;
    description: string;
    formURL: string;
    qualifications: string;
    payment: string;
    schedule: string;
}
export const techjobList: JobModel[] = [
    {
        id: 1,
        title: 'Full Stack Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Seeking experienced full stack trainer proficient in React and Node.js for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 2,
        title: 'Java Backend Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Looking for a backend trainer with strong Java and Spring Boot skills for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 3,
        title: 'UI/UX Trainer',
        location: 'Remote',
        description: 'Creative designer needed with user-centric design experience for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (Design), B.Sc (Design), relevant diploma or course can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 4,
        title: 'PHP Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Experienced PHP trainer required for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 5,
        title: 'Angular Developer',
        location: 'Remote',
        description: 'Seeking Angular trainer with strong front-end skills for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 6,
        title: 'AWS Cloud Trainer',
        location: 'Remote',
        description: 'AWS cloud expert needed for content creation on cloud.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 7,
        title: 'React Trainer',
        location: 'Remote',
        description: 'Looking for a React trainer skilled in building modern web apps for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 8,
        title: 'Android Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Android trainer with strong Kotlin/Java skills needed for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), relevant diploma or pursuing courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 9,
        title: 'Testing Engineer',
        location: 'Remote',
        description: 'QA trainer needed for automated and manual testing content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 10,
        title: 'Vue.js Trainer',
        location: 'Remote',
        description: 'Trainer required for Vue.js content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    }
];
export const mathjobList: JobModel[] = [
    {
        id: 1,
        title: 'Math Tutor',
        location: 'Remote, Delhi, Bangalore',
        description: 'Teach high school and college-level mathematics.',
        formURL: applyFormURL,
        qualifications: 'B.Tech, BCA, MCA, M.Tech, M.Sc, B.Sc, pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    }
]
export const managementJobList: JobModel[] = [
    {
        id: 1,
        title: 'Leadership Coach',
        location: 'Remote, Delhi, Bangalore',
        description: 'Mentor individuals in leadership skills, team management, and organizational behavior.',
        formURL: applyFormURL,
        qualifications: 'MBA, BBA, or experience in management roles. Relevant certifications preferred.',
        payment: '500-1000 INR per session, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or per session.'
    }
];

export const artsJobList: JobModel[] = [

    {
        id: 2,
        title: 'Music Coach',
        location: 'Remote, Delhi, Bangalore',
        description: 'Provide guidance in music theory, vocal training, or instrument mastery.',
        formURL: applyFormURL,
        qualifications: 'Degree in music or proven experience in teaching music.',
        payment: '500-1000 INR per session.',
        schedule: 'Flexible schedule; payment per session.'
    }
];

export const financeJobList: JobModel[] = [
    {
        id: 1,
        title: 'Investment Mentor',
        location: 'Remote, Delhi, Bangalore',
        description: 'Teach financial literacy, investment strategies, and the stock market.',
        formURL: applyFormURL,
        qualifications: 'Degree in finance or related fields, or substantial experience in financial analysis or investment.',
        payment: '700-1500 INR per session.',
        schedule: 'Flexible; hourly or per session payments.'
    },
    {
        id: 2,
        title: 'Accounting Guide',
        location: 'Remote, Delhi, Bangalore',
        description: 'Help learners master accounting principles, bookkeeping, and financial reporting.',
        formURL: applyFormURL,
        qualifications: 'Degree in Accounting, Finance, or related field, or experience in the field.',
        payment: '500-1000 INR per session.',
        schedule: 'Flexible; payment per session.'
    }
];

export const medicalJobList: JobModel[] = [
    {
        id: 1,
        title: 'Anatomy Instructor',
        location: 'Remote, Delhi, Bangalore',
        description: 'Teach anatomy to medical students using modern tools and resources.',
        formURL: applyFormURL,
        qualifications: 'Medical degree or expertise in anatomy.',
        payment: '1000-2000 INR per session.',
        schedule: 'Flexible schedule; payment per session.'
    },
    {
        id: 2,
        title: 'Nursing Mentor',
        location: 'Remote, Delhi, Bangalore',
        description: 'Guide nursing students on patient care and clinical procedures.',
        formURL: applyFormURL,
        qualifications: 'Nursing degree or experience in healthcare.',
        payment: '800-1500 INR per session.',
        schedule: 'Flexible; payment per session.'
    }
];


export const jobList: JobCategoryModel[] =
    [
        {
            id: '1',
            title: 'Tech Jobs',
            description: 'Empower others by sharing your expertise while earning a rewarding income. Join a community of professionals making an impact in the tech world.',
            jobs: techjobList
        },
        {
            id: '2',
            title: 'Math Jobs',
            description: 'Share your passion for numbers and problem-solving. Mentor students and professionals in mathematics, helping them excel in academics and real-world applications.',
            jobs: mathjobList
        },
        {
            id: '3',
            title: 'Finance Jobs',
            description: 'Guide learners to navigate the financial world. Mentor in topics like investment strategies, accounting principles, and financial management.',
            jobs: financeJobList
        },
        {
            id: '4',
            title: 'Arts Jobs',
            description: 'Inspire creativity by mentoring in fine arts, design, or performing arts. Help individuals unlock their artistic potential.',
            jobs: artsJobList
        },
        {
            id: '5',
            title: 'Medical Jobs',
            description: 'Help aspiring medical professionals by sharing your knowledge in healthcare, medicine, and life sciences.',
            jobs: medicalJobList
        },
        {
            id: '6',
            title: 'Management Jobs',
            description: 'Guide learners in leadership, project management, and organizational skills to succeed in business and management roles.',
            jobs: managementJobList
        }

    ]