import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb, Badge, Col,Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import { getDashboardCourses, getDashboardUsers } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { UserInterface } from '../../data/userData';
import { Course } from '../../data/coursesData';
import MetaHelmet from '../../components/MetaHelmet';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="m-3">
    <Row>
      <Col md={1} xs={12}>
      <span>Search: </span>

      </Col>
      <Col md={10} xs={12}>

        <input
          className="form-control mb-2"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
          placeholder="Search records..."
        />
      </Col>
      <Col md={1} xs={12}>

        <Link to="/dashboard/add-course" title='Add new course' className="navBtn p-2 mb-2">
          <i className='fa fa-plus-circle'></i>
        </Link>
      </Col>
    </Row>

  </div>
);

const CoursesPage: React.FC = () => {
  const [Courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardCourses();
        setCourses(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: number) => {
    axios
      .delete(`http://localhost/api/dashboard/courses/call-requests/${id}`)
      .then(() => {
        setCourses(Courses.filter((request) => request.id !== id));
      })
      .catch((error) => {
        setError('Error deleting call request');
      });
  };


  const filteredData = Courses.filter((request) => {
    return (
      request.title.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.description.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
       <MetaHelmet
                title={` courses dashboard - ${localStorage.getItem('email')} - Course Echo`}
                description="Dashboard to manage courses"
                keywords="course echo dashboard,Dashboard to manage courses"
                author="Course Echo"
            />
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>Courses : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>


        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Status</th>
              <th>Author</th>
              <th>Description</th>
              <th>Fee</th>
              <th>Badge</th>
              <th>Source</th>
              <th>CategoryDisplay</th>
              <th>Seo Keywords</th>
              <th>Syllabus</th>
              <th>No Of Topics</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          {!loading ? (
            <tbody>
              {filteredData.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>
                    <img src={request.image} height={100} width={100} className='img-fluid'></img>
                    <Link title='click to see preview' to={`/course-details/${request.courseDisplayId}/${request.id}`}
                    >
                      {request.title}
                    </Link>
                  </td>
                  <td>
                    <div className="badge-card-container">
                      <div
                        key={request.status}
                        className={`badge-${request.status?.toLowerCase()} badge-card`}
                      >
                        {request.status}
                      </div>
                    </div>
                  </td>
                  <td>
                    <img src={request.author?.image} height={50} width={50} className='img-fluid rounded'></img>
                    <br></br>
                    {request.author?.name}
                    <div>

                      <Link
                        className="m-5 text-primary"
                        to={`/mentor-details/${request.author?.name}/${request.author?.id}`}
                      ><i className='fa fa-eye'></i>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <p className='truncate'>
                      {request.description}
                    </p>
                  </td>
                  <td> <i className='fa fa-inr text-danger'></i>  {request.fee}</td>
                  <td>
                    <div className="badge-card-container">
                      <div
                        key={request.badgeText}
                        className={`badge-${request.badgeText.toLowerCase()} badge-card selected}`}
                      >
                        {request.badgeText}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="source-card-container">
                      <div
                        key={request.source}
                        className={`source-${request.source.toLowerCase()} source-card selected}`}
                      >
                        {request.source}
                      </div>
                    </div>
                  </td>
                  <td>{request.categoryDisplayId}</td>
                  <td>
                    <div className="truncate p-2">
                      {request.keywords.split(',').map((keyword, index) => (
                        <Badge pill bg="info" className="small d-inline-flex align-items-center mx-1 m-1" key={index}>
                          {keyword.trim()}
                        </Badge>
                      ))}
                    </div>

                  </td>
                  <td>{request.syllabusUrl}</td>
                  <td>{request.Topics?.filter((v) => { return v.parentId === null }).length || 0}</td>
                  <td>{request.dateAdded}</td>
                  <td>{request.dateUpdated}</td>
                  <td>
                    <div className="action-container">
                      <Link
                        title='Click to edit course'
                        to={`/dashboard/edit-course/${request.categoryDisplayId}/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-pencil text-center"
                        ></i>
                      </Link>
                      {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                        <i
                          title='Click to delete'
                          onClick={() => handleDelete(request.id)}
                          className="me-3 fa fa-trash text-center"
                        >
                        </i>
                      ) : (null)}

                      <Link
                        title='Manage Topics : Add/Edit'
                        to={`/dashboard/topics/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-book text-center">
                          <div className="countNo" title={`Total ${request.Topics?.length || 0} Topics`}>
                            {request.Topics?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Bullet Points : Add/Edit'
                        to={`/dashboard/bullet-points/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-check-square text-center">
                          <div className="countNo" title={`Total ${request.CourseBulletPoints?.length || 0} Bullet Points`}>
                            {request.CourseBulletPoints?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Course For : Add/Edit'
                        to={`/dashboard/course-for/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-graduation-cap text-center">
                          <div className="countNo" title={`Total ${request.CourseFor?.length || 0} Course For`}>
                            {request.CourseFor?.length || 0}
                          </div>

                        </i>

                      </Link>

                      <Link
                        title='Manage Course Perks & Rewards : Add/Edit'
                        to={`/dashboard/perks-rewards/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-gift text-center">
                          <div className="countNo" title={`Total ${request.CoursePerksBenefits?.length || 0} Perks & Rewards`}>
                            {request.CoursePerksBenefits?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Course FAQ : Add/Edit'
                        to={`/dashboard/course-faq/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-question-circle text-center">
                          <div className="countNo" title={`Total ${request.Faqs?.length || 0} FAQ`}>
                            {request.Faqs?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Course Resources : Add/Edit'
                        to={`/dashboard/course-resource/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-bookmark text-center">
                          <div className="countNo" title={`Total ${request.Resources?.length || 0} Resources`}>
                            {request.Resources?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Course Prerequisites : Add/Edit'
                        to={`/dashboard/course-prerequisit/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-check-circle text-center">
                          <div className="countNo" title={`Total ${request.Prerequisites?.length || 0} Prerequisite`}>
                            {request.Prerequisites?.length || 0}
                          </div>
                        </i>

                      </Link>

                      <Link
                        title='Manage Course Quiz : Add/Edit'
                        to={`/dashboard/course-quiz/${request.id}`} // Pass the course ID for editing
                      >
                        <i className="me-3 fa fa-check-square-o text-center">
                          <div className="countNo" title={`Total ${request.Tests?.length || 0} Quiz`}>
                            {request.Tests?.length || 0}
                          </div>
                        </i>

                      </Link>

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (<div className='row'><h6 className='text-center mt-5'>Loading...</h6></div>)}
        </Table>

      </div>
    </div>
  );
};

export default CoursesPage;
