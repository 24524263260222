import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Author } from '../data/authorData';
import { getAuthorsById } from '../services/AuthorsService';
import { Helmet } from 'react-helmet-async';
import './AuthorDetails.scss';

const AuthorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [author, setAuthor] = useState<Author | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [mentorBanner, setMentorBanner] = useState<string>('');

  // Function to select random banner image
  const getRandomBannerImage = () => {
    const banners = ['https://images.courseecho.com/backgrounds/contact-banner-1.webp', 'https://images.courseecho.com/backgrounds/contact-banner.webp'];
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  };
  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        setLoading(true);
        const data = await getAuthorsById(id || '');
        setMentorBanner(getRandomBannerImage());
        setAuthor(data[0]); // Assuming the response is an array and you're interested in the first item
        setError(null);
      } catch (err) {
        console.error('Error fetching author details:', err);
        setError('Failed to load author details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchAuthorDetails();
    }
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Loading mentors details...</div>;
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5">
        {error}
      </div>
    );
  }

  if (!author) {
    return (
      <div className="text-center mt-5">
        <p>No author details found.</p>
        <Link to="/" className="navBtn mt-4">
          Back to Authors
        </Link>
      </div>
    );
  }

  return (
    <div className="container authorDetailsPage">
      <Helmet>
        <title>{author.name} - Mentor Details | Course Echo</title>
        <meta name="keywords" content={`${author.keywords}`} />

        <meta name="description" content={`Learn more about ${author.name}, including their work history and social links.`} />
        <meta property="og:title" content={author.name} />
        <meta property="og:description" content={`Explore the work history and social links of ${author.name}.`} />
        <meta property="og:image" content={`${author.image}`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="row">
        <div className="col-sm-12">
          <h1 className="text-center mb-4">{author.name}</h1>
          <div className="card mx-auto d-flex customd-flex  align-items-center mb-4 authorImgCard"
            style={{
              backgroundImage: `url(${mentorBanner})`,
              backgroundRepeat: 'no-repeat', // Prevents repetition
              backgroundSize: 'cover',      // Ensures the image covers the whole element
            }}
          >
            <img src={`${author.image}`} className="card-img-top img-fluid shadow"
              style={{ width: '200px' }} alt={author.name} />
            <div className="card-body shadow">
              <div className='card-text p-2' dangerouslySetInnerHTML={{ __html: author?.description || '' }} />

            </div>
          </div>
        </div>
        <div className="col-sm-12">

          {author.WorkHistory && author.WorkHistory.length > 0 && (
            <>
              <h2 className='detail-h1'>Work History</h2>
              <ul className="list-group mb-4">
                {author.WorkHistory.map((work) => (
                  <li key={work.id} className="list-group-item">
                    <h3>
                      <a href={work.webLink} title={`Visit to ${work.companyName}`} target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${work.image}`} // Replace with appropriate URL or path
                          alt={work.companyName}
                          className="social-icon me-2"
                          style={{ width: '50px' }} // Small icon size
                        />{work.companyName}
                      </a>
                    </h3>
                    {work.description && (
                      <div dangerouslySetInnerHTML={{ __html: work?.description || '' }} />
                    )}
                    {/* Styling for "Currently working" */}

                    <div className="badge-card-container mt-2 mb-2">
                      <div
                        key={work.isCurrent}
                        className={`badge-${work.isCurrent?.toLowerCase()} badge-card ${work.isCurrent ? 'selected' : ''}`}
                      >
                        {work.isCurrent === 'true' ? 'Currently working' : 'Previously worked'}
                      </div>
                    </div>

                    {work.skills && (
                      <p>
                        <strong>Skills:</strong> {work.skills}
                      </p>
                    )}
                    {work.achivements && (
                      <p>
                        <strong>Achievements:</strong> {work.achivements}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="col-sm-12">


          {author.SocialLink && author.SocialLink.length > 0 && (
            <>
              <h3 className='detail-h1'>Social Links</h3>
              <ul className="list-group mb-4">
                {author.SocialLink.map((link) => (
                  <li key={link.id} className="list-group-item d-flex customd-flex  align-items-center">
                    {/* Add icon for social link */}
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={`${link.image}`} // Replace with appropriate URL or path
                        alt={link.title}
                        className="social-icon me-2"
                        style={{ width: '50px' }} // Small icon size
                      />
                    </a>
                    {link.description && (
                      <div dangerouslySetInnerHTML={{ __html: link?.description || '' }} />
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="col-sm-12">

          <Link to="/mentors" className="navBtn mt-4">
            Back to mentors
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthorDetails;
