import React, { Suspense, useEffect, useState } from 'react';
import { CountData, Course, TopicProgress, Topics } from '../data/coursesData';
import './CourseDetails.scss'; // Add your styling if needed
import { getCourseById } from '../services/CourseService';
import { Accordion, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { addToCart } from '../services/CartService';
import { Helmet } from 'react-helmet-async';
import TestComponent from './TestComponent';
import { PROJECTNAME } from '../utils/constants';
import ReactPlayer from 'react-player';
import GoogleLoginComponent from './GoogleLoginComponent';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { addCourseCount, updateTopicProgress } from '../services/DashboardService';
import CertificateGenerator from './CertificateGenerator';
import TopicProgressComponent from './TopicProgressComponent';
const CourseFor = React.lazy(() => import('./CourseFor'));
const CoursePerks = React.lazy(() => import('./CoursePerks'));
const SyllabusViewer = React.lazy(() => import('./SyllabusViewer'));
const CourseFaqs = React.lazy(() => import('./CourseFaqs'));
const CoursePrerequisites = React.lazy(() => import('./CoursePrerequisites'));
const CourseResources = React.lazy(() => import('./CourseResources'));
const CourseQA = React.lazy(() => import('./QAManager'));

interface CourseDetailsProps {
  course: Course;
}
const CourseDetails: React.FC<CourseDetailsProps> = ({ course }) => {
  const [isPlaying, setIsPlaying] = useState(false); // To track if video is playing or paused
  const [progress, setProgress] = useState(0); // To track the progress of the video
  const [volume, setVolume] = useState(0.8); // Default volume set to 80%
  const [duration, setDuration] = useState(0); // To track the video's total duration
  const [played, setPlayed] = useState(0); // The percentage of video that has been played
  const isLoggedIn = localStorage.getItem('token') ? true : false;
  const [totalTopics, setTotalTopics] = useState(0);
  const navigate = useNavigate();
  const { updateCartItems } = useCart();
  const updateCourseCount = async (column: string, courseId: string) => {
    try {
      // Create a payload with the required data
      const payload: CountData = {
        courseId: courseId, // Replace with dynamic course ID
        pageUrl: window.location.href, // Replace with the current page URL
        metaData: "", // Optional, add if needed
        buyNowCount: column === 'BUY-NOW' ? "1" : '0', // Or update with appropriate counts
        viewDetailCount: column === 'VIEW-DETAIL' ? "1" : '0', // Or update with appropriate counts
        watchNowCount: column === 'WATCH-NOW' ? "1" : '0', // Or update with appropriate counts
        countColumn: column,
      };

      // Sending the payload to the backend service to update course counts
      const response = await addCourseCount(payload);

      // Handle successful response (you can use response for further actions)
      console.log("Course count updated:", response);

    } catch (err) {
      console.error("Error updating course count:", err);
    }
  };
  const handleAddToCart = () => {
    updateCourseCount('BUY-NOW', courseResponse.id.toString())
    addToCart(courseResponse); // Add the course to the cart
    updateCartItems(); // Update the cart count in Navbar
    navigate('/cart')
  };
  const [loading, setLoading] = useState(true); // State to handle loading
  const { courseDisplayid, courseid } = useParams<{ courseDisplayid: string; courseid: string }>();

  const [courseResponse, setCourse] = useState<Course>(course);

  const [activeTab, setActiveTab] = useState<string>('details');
  const [groupedTopics, setGroupedTopics] = useState<
    Record<number, { parent: Topics; children: Topics[] }>
  >({});
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [selectedTopics, setSelectedTopics] = useState<Topics | null>(null);
  const [mentorBanner, setMentorBanner] = useState<string>('');

  // Function to select random banner image
  const getRandomBannerImage = () => {
    const banners = ['https://images.courseecho.com/backgrounds/contact-banner-1.webp', 'https://images.courseecho.com/backgrounds/contact-banner.webp'];
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  };

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (!courseid) return;

      setLoading(true);
      const courseData = await getCourseById(courseid);
      setCourse(courseData);
      setMentorBanner(getRandomBannerImage());


      if ((courseData.orders?.length ?? 0) > 0 || courseData.fee < 1 || courseData.Topics?.some((v) => { return v.isTrailer === "1" })) {
        setActiveTab('Topics');
        if (courseData.Topics?.some((v) => { return v.isTrailer === "1" })) {
          const trailerData = courseData.Topics?.filter((v) => { return v.isTrailer === "1" })[0]
          console.log(courseData.Topics?.filter((v) => { return v.isTrailer === "1" }))
          console.log(trailerData)
          setSelectedTopics(trailerData)
        }
        else {
          setSelectedTopics(courseData.Topics?.[1] ?? null)
        }
      }
      const TopicsData = courseData.Topics || []

      const parentTopics = TopicsData.filter((item) => item.parentId === null);
      const childTopics = TopicsData.filter((item) => item.parentId !== null);
      setTotalTopics(childTopics.length)
      const grouped = parentTopics.reduce((acc, parent) => {
        acc[parent.id] = {
          parent,
          children: childTopics.filter((child) => child.parentId === parent.id),
        };
        return acc;
      }, {} as Record<number, { parent: Topics; children: Topics[] }>);

      setGroupedTopics(grouped);

      const firstGroupId = Object.keys(grouped)[0];
      if (firstGroupId) {
        const firstGroup = grouped[Number(firstGroupId)];
        setActiveKey(firstGroupId);
        if (firstGroup.children.length > 0)
          setSelectedTopics(firstGroup.children[0] || null);
      }

      setLoading(false);
    };

    fetchCourseDetails();
  }, [courseid]);

  // Automatically select the first topic when the 'Topics' tab is selected
  // Automatically select the first topic when the 'Topics' tab is selected
  useEffect(() => {
    if (activeTab === 'Topics' && courseResponse && courseResponse.Topics && courseResponse.Topics.length > 1) {
      setSelectedTopics(courseResponse.Topics[1]); // Set the first topic as the default selected topic
    }
  }, [activeTab, courseResponse]);

  const handleVideoEnd = async () => {
    try {
      const status =
        parseInt(selectedTopics?.TopicProgress?.status ?? "0") > Math.ceil(played)
          ? (parseInt(selectedTopics?.TopicProgress?.status ?? "0") > 98
            ? 'completed'
            : 'in-process')
          : (played > 98
            ? 'completed'
            : 'in-process');

      const statusData =
        parseInt(selectedTopics?.TopicProgress?.status ?? "0") > Math.ceil(played)
          ? (parseInt(selectedTopics?.TopicProgress?.status ?? "0") > 90
            ? selectedTopics?.TopicProgress?.status
            : Math.ceil(played))
          : Math.ceil(played);
      const user = localStorage.getItem('token') ? true : false
      if (!user) return;
      if (statusData ?? 0 > 0) {

        const payload: TopicProgress = {
          courseId: selectedTopics?.courseId?.toString() ?? "",
          userId: '',
          topicId: selectedTopics?.id?.toString() ?? "",
          status: status,
          statusData: statusData ? statusData?.toString() : Math.ceil(played).toString()
        };
        const latestData = await updateTopicProgress(payload); // Use the service here
        if (selectedTopics != null) {
          const updatedStatus: Topics = {
            ...selectedTopics,
            TopicProgress: {
              ...selectedTopics?.TopicProgress,
              status: latestData.status,
              topicId: selectedTopics?.TopicProgress?.topicId ?? "", // Ensure `courseId` is not undefined
              courseId: selectedTopics?.TopicProgress?.courseId ?? "", // Ensure `courseId` is not undefined
              userId: selectedTopics?.TopicProgress?.userId ?? "",     // Ensure `userId` is not undefined
              statusData: latestData.statusData,
            },
          };
          selectedTopics.TopicProgress = updatedStatus.TopicProgress
          // setSelectedTopics(updatedStatus);
        }
      }
    } catch (error) {
      console.error('Error in notifying video completion:', error);
    }
  };
  // Play/Pause the video
  const togglePlay = () => setIsPlaying(!isPlaying);

  // Update progress when the video is playing
  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    setProgress(state.playedSeconds); // In seconds
    setPlayed((state.played * 100)); // In percentage
    switch (Math.ceil(played)) {
      case 30:
        handleVideoEnd();
        break;
      case 60:
        handleVideoEnd();
        break;
      case 90:
        handleVideoEnd();
        break;
      default:
        console.log(`progress ${Math.ceil(played)} %`)

    }
  };
  // Update video duration
  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  // Seek the video to a specific time
  const handleSeek = (e: any) => {
    const seekTo = (e.target.value / 100) * duration; // Convert percentage to seconds
    setProgress(seekTo);
    setPlayed(e.target.value);
    handleVideoEnd();
  };
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleAccordionSelect = (key: AccordionEventKey) => {
    if (Array.isArray(key)) {
      setActiveKey(key[0] ?? null); // Use the first active key
    } else {
      setActiveKey(key ?? null); // Handle string or null
    }
  };

  const getNextTopics = () => {
    if (!selectedTopics) return;

    const currentGroup = groupedTopics[selectedTopics.parentId!];
    const currentIndex = currentGroup.children.findIndex((t) => t.id === selectedTopics.id);
    const nextIndex = currentIndex + 1;

    if (nextIndex < currentGroup.children.length) {
      handleVideoEnd();
      setSelectedTopics(currentGroup.children[nextIndex]);
      setPlayed(0)
    } else {
      const nextGroupId = Object.keys(groupedTopics)
        .map(Number)
        .find((groupId) => groupId > selectedTopics.parentId!);

      if (nextGroupId) {
        setActiveKey(String(nextGroupId));
        const nextGroup = groupedTopics[nextGroupId];
        handleVideoEnd();
        setSelectedTopics(nextGroup.children[0]);
        setPlayed(0)
      }
    }
  };

  const getPreviousTopics = () => {
    if (!selectedTopics) return;

    const currentGroup = groupedTopics[selectedTopics.parentId!];
    const currentIndex = currentGroup.children.findIndex((t) => t.id === selectedTopics.id);
    const prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      handleVideoEnd();
      setSelectedTopics(currentGroup.children[prevIndex]);
      setPlayed(0)
    } else {
      const prevGroupId = Object.keys(groupedTopics)
        .map(Number)
        .reverse()
        .find((groupId) => groupId < selectedTopics.parentId!);

      if (prevGroupId) {
        setActiveKey(String(prevGroupId));
        const prevGroup = groupedTopics[prevGroupId];
        handleVideoEnd();
        setSelectedTopics(prevGroup.children[prevGroup.children.length - 1]);
        setPlayed(0)
      }
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'certificate':
        return (
          <CertificateGenerator
            studentName={courseResponse.orders && courseResponse.orders.length > 0 ? courseResponse.orders[0].name ?? "STUDENT" : "STUDENT"}
            courseName={courseResponse.title || "COURSE"}
            description={"Has successfully completed the course "}
            author={courseResponse.author?.name ?? 'CourseEcho'}
            directorName='HR CourseEcho'
            image={courseResponse.image}
            uid={courseResponse.orders && courseResponse.orders.length > 0
              ? courseResponse.id + "-" + (courseResponse.orders[0].id || '')
              : ""}

          />
        );

      case 'details':
        return (
          <div className='container details-container'>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 no-paddinng">
                <div className="details-image-container">
                  <img
                    src={courseResponse?.image}
                    alt={courseResponse?.title}
                    className="img-fluid"
                  />
                </div>
                <div className="ribbon-container" title={`${courseResponse.badgeText.toLowerCase()} Course`}>
                  <span className={`ribbon ${courseResponse.badgeText.toLowerCase()}`}>{courseResponse.badgeText}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 card-body card-body-radius">

                <div className="details-content card-body p-3 card-body-radius">
                  <h3>{courseResponse?.title}</h3>

                  <div className="course-details ">
                    {courseResponse?.fee < 1 ? (
                      <div className="course-badge free-course">Free</div>
                    ) : (
                      <div className="course-badge paid-course">
                        👑{courseResponse?.fee} INR</div>

                    )}

                    <div className="rating ">
                      <span>{courseResponse?.rating} ⭐</span>
                    </div>
                    <div className='course-badge course-topics'>Topics: {totalTopics}</div>
                    <div className="course-badge free-course">{courseResponse?.badgeText}</div>
                  </div>
                  <p className="card-text truncate" title={courseResponse?.description}>{courseResponse?.description}</p>

                  <div className='button-container'>
                    {courseResponse?.fee < 1 || (courseResponse.orders?.length ?? 0) > 0 ? (
                      <button onClick={() => {
                        updateCourseCount('WATCH-NOW', courseResponse.id.toString()); // Call the function with appropriate parameters
                        setActiveTab('Topics'); // Set the active tab
                      }} className='button viewBtn mt-2'>Watch Now</button>

                    ) : null}

                    {courseResponse?.fee > 0 && (courseResponse.orders?.length ?? 0) < 1 ? (
                      <button onClick={handleAddToCart} className="button applyBtn mt-2">Buy Now</button>
                    ) : null}  {/* Don't show the button when orders length > 0 */}

                    {/* Only show the "Proceed to Enroll" button if orders.length is 0 */}
                    {courseResponse?.orders?.length === 0 && (
                      <a onClick={() => updateCourseCount('BUY-NOW', courseResponse.id.toString())} href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
                        className="button applyBtn mt-2">
                        WhatsApp Enquiry
                      </a>
                    )}
                  </div>

                </div>
              </div>

            </div>


          </div>
        );


      case 'Topics':
        return (
          <div className="Topics-tab container-fluid">
            <div className="row">
              {/* Sidebar */}


              {/* Video Player */}
              <div
                className='col-12'
              >

                <div className="video-container" style={{ position: 'relative', paddingTop: '41%' /* 16:9 aspect ratio */ }}>
                  {courseResponse?.fee < 1 || (courseResponse.orders?.length ?? 0) > 0 || selectedTopics?.isTrailer === "1" ? (
                    <ReactPlayer
                      title={selectedTopics?.title}
                      url={selectedTopics?.externalLink}
                      playing={isPlaying}
                      controls={true}
                      volume={volume}
                      onProgress={handleProgress}
                      onDuration={handleDuration}
                      onSeek={handleSeek}
                      onEnded={handleVideoEnd}
                      width="100%"
                      height="100%"
                      config={{ file: { attributes: { preload: 'auto' } } }}
                      style={{ position: 'absolute', top: 0, left: 0 }}
                    />

                  ) : !isLoggedIn ? (
                    <p className='text-center'>
                      <Link
                        to="/login"
                        className="btn btn-primary viewBtn enroll-button mt-2"
                      >
                        Login first to access this
                      </Link>
                      <GoogleLoginComponent isLoginScreen={true} />
                    </p>
                  ) : (
                    <button
                      onClick={handleAddToCart}
                      className="btn btn-primary applyBtn"
                    >
                      Buy Now to start learning
                    </button>
                  )}
                </div>
                <div className="button-container mt-5">
                  <Button
                    variant="secondary"
                    onClick={getPreviousTopics}
                    disabled={!selectedTopics}
                    className="mr-2"
                  >
                    Previous
                  </Button>
                  <Button variant="primary" onClick={getNextTopics} disabled={!selectedTopics}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>Topics</h3>
                <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
                  {Object.entries(groupedTopics).map(([parentId, group]) => (
                    <Accordion.Item eventKey={parentId} key={parentId}>
                      <Accordion.Header>
                        {group.parent.title} ({group.children.length})
                      </Accordion.Header>
                      <Accordion.Body>
                        {group.children.map((Topics) => (
                          <div
                            key={Topics.id}
                            title="Click to watch"
                            onClick={() => setSelectedTopics(Topics)}
                            className={selectedTopics?.id === Topics.id ? "selected-Topics" : ""}
                          >
                            <i className="fa fa-video-camera"></i> {Topics.title}

                            {/* Handle topic progress */}
                            {selectedTopics?.id === Topics.id && played > 0 ? (
                              // Show current playing progress
                              <TopicProgressComponent
                                Topics={Topics}
                                selectedTopics={selectedTopics}
                                played={played}
                              />
                            ) : Topics.TopicProgress?.statusData?.includes("99") || Topics.TopicProgress?.status === "completed" ? (
                              // Show completed status
                              <TopicProgressComponent
                                Topics={Topics}
                                selectedTopics={Topics}
                                played={100} // Completed means 100%
                              />
                            ) : Topics.TopicProgress?.statusData ? (
                              // Show the stored progress
                              <TopicProgressComponent
                                Topics={Topics}
                                selectedTopics={Topics}
                                played={parseInt(Topics.TopicProgress.statusData || "0")}
                              />
                            ) : null}
                          </div>
                        ))}


                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <br></br>
              </div>
            </div>
          </div>
        );

      case 'resources':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Resources?.length ? (
                  <Suspense fallback={<div>Loading Course Resources...</div>}>
                    <CourseResources course={courseResponse} />
                  </Suspense>
                ) : (
                  <p>No resources available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'prerequisites':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Prerequisites?.length ? (
                  <Suspense fallback={<div>Loading Course Prerequisits...</div>}>
                    <CoursePrerequisites course={courseResponse} />
                  </Suspense>
                ) : (
                  <p>No prerequisit available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'faq':
        return (
          <div className="resources-tab">
            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Faqs?.length ? (
                  <CourseFaqs course={courseResponse} />

                ) : (
                  <p>No faq available.</p>
                )}
              </div>
            </div>
          </div>
        );
      case 'qa':
        return (
          <div className="qa-tab mb-2">
            <Suspense fallback={<div>Loading Course QA...</div>}>
              <CourseQA courseId={courseResponse.id.toString()} />
            </Suspense>
          </div>
        );
      case 'test':
        return (
          <div className="tests-tab">
            <div className="container">
              <div className="col-sm-12">
                <h3>{courseResponse.title} Quiz</h3>
                <p>
                  You can test your {courseResponse.title} skills with '{PROJECTNAME}' Quiz.
                </p>
              </div>
            </div>
            <hr></hr>
            <div className="container">
              <div className="col-sm-12">
                <h6>The test</h6>
                <p>
                  The test contains ({courseResponse?.Tests?.length}) questions and there is no time limit.

                  The test is not official, it's just a nice way to see how much you know, or don't know, about {courseResponse.title}.
                </p>
              </div>
              <div className="col-sm-12">
                <h6>Count your score</h6>
                <p>
                  You will get 1 point for each correct answer. At the end of the Quiz, your total score will be displayed. Maximum score is ({courseResponse?.Tests?.length}) points.

                </p>
              </div>
            </div>
            <hr></hr>

            <div className='container'>
              <div className='col-sm-12'>
                {courseResponse?.Tests?.length ? (
                  <TestComponent tests={courseResponse.Tests} />

                ) : (
                  <p>No test available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'enroll':
        return (
          <div>
            <h4>Enroll in Online Classes</h4>
            <div className="button-container">
              {courseResponse?.fee > 0 && (courseResponse.orders?.length ?? 0) < 1 ? (
                <button onClick={handleAddToCart} className="btn btn-primary navBtn">Buy Now</button>
              ) : null}
              <a href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
                className="btn btn-primary navBtn">Enroll on WhatsApp</a>
            </div>

          </div>
        );

      default:
        return null;
    }
  };
  if (loading) {
    return (
      <div className="course-details-container">
        <p className='text text-center'>Loading details...</p>
      </div>
    );
  }
  else {
    return (
      <div className="course-details-container">
        <Helmet>
          <title>{courseResponse?.title} | Course Echo</title>
          <meta name="description" content={courseResponse?.description} />
          <meta name="keywords" content={courseResponse?.keywords || 'courses, online learning, education'} />
          <meta name="author" content={PROJECTNAME} />
          <meta property="og:title" content={courseResponse?.title} />
          <meta property="og:description" content={courseResponse?.description} />
          <meta property="og:image" content={courseResponse?.image} />
          <meta property="og:url" content={`https://courseecho.com/course-details/${courseDisplayid}/${courseid}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={courseResponse?.title} />
          <meta name="twitter:description" content={courseResponse?.description} />
          <meta name="twitter:image" content={courseResponse?.image} />
          <meta name="twitter:url" content={`https://courseecho.com/course-details/${courseDisplayid}/${courseid}`} />
          <meta name="twitter:creator" content="@CourseEcho" />
        </Helmet>
        <div className="tabs">
          <div className="tab-buttons">
            {/* Details Tab */}
            <button
              className={`tab-button ${activeTab === 'details' ? 'active' : ''}`}
              onClick={() => setActiveTab('details')}
            >
              <i className='fa fa-info-circle text-primary'></i> Details
            </button>

            {/* Topics Tab */}
            <button
              className={`tab-button ${activeTab === 'Topics' ? 'active' : ''}`}
              onClick={() => setActiveTab('Topics')}
            >
              <i className='fa fa-book text-danger'></i> Topics ({totalTopics})
            </button>

            {/* Resources Tab */}
            <button
              className={`tab-button ${activeTab === 'resources' ? 'active' : ''}`}
              onClick={() => setActiveTab('resources')}
            >
              <i className='fa fa-bookmark text-info'></i> Resources ({courseResponse?.Resources?.length})
            </button>

            {/* Prerequisites Tab */}
            <button
              className={`tab-button ${activeTab === 'prerequisites' ? 'active' : ''}`}
              onClick={() => setActiveTab('prerequisites')}
            >
              <i className='fa fa-check-circle text-warning'></i> Prerequisites ({courseResponse?.Prerequisites?.length})
            </button>

            {/* FAQ Tab */}
            <button
              className={`tab-button ${activeTab === 'faq' ? 'active' : ''}`}
              onClick={() => setActiveTab('faq')}
            >
              <i className='fa fa-question-circle text-warning'></i> FAQ ({courseResponse?.Faqs?.length})
            </button>

            {/* Quiz Tab */}
            <button
              className={`tab-button ${activeTab === 'test' ? 'active' : ''}`}
              onClick={() => setActiveTab('test')}
            >
              <i className='fa fa-pencil text-danger'></i> Quiz ({courseResponse?.Tests?.length})


            </button>

            <button
              className={`tab-button ${activeTab === 'qa' ? 'active' : ''}`}
              onClick={() => setActiveTab('qa')}
            >
              <i className='fa fa-comments-o text-danger'></i> Q&A

            </button>


            {/* Enroll Tab */}
            {/* {courseResponse?.Topics?.length && ( */}
            <button
              className={`tab-button ${activeTab === 'enroll' ? 'active' : ''}`}
              onClick={() => setActiveTab('enroll')}
            >
              <i className='fa fa-user-plus text-info'></i> Enroll
            </button>
            {/* )} */}

            {/* Certificate Tab */}
            {/* {courseResponse?.Topics?.length && ( */}
            <button
              className={`tab-button ${activeTab === 'certificate' ? 'active' : ''}`}
              onClick={() => setActiveTab('certificate')}
            >
              <i className='fa fa-certificate text-success'></i> Certificate
            </button>
            {/* )} */}

          </div>

        </div>
        <div className="tab-content">{renderTabContent()}</div>
        <hr></hr>
        <div className="container">
          {courseResponse?.CourseBulletPoints?.length ? (
            <div className="row">
              {courseResponse?.CourseBulletPoints.map((item, index) => (
                <div key={index} className="col-sm-12 col-md-6 mb-3">
                  <div className="d-flex customd-flex  align-items-center">
                    <div className="icon-container me-3">
                      <i className={`fa ${item.icon} fa-2x`}></i>
                    </div>
                    <div className="text-container">
                      <p className="mb-0">{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No bullet points available</p>
          )}
        </div>
        <div className='objective'>
          <div className="container">
            <div className="col-sm-12">
              <h1 className='detail-h1'>Our Objective</h1>
              <div dangerouslySetInnerHTML={{ __html: courseResponse?.objective || '' }} />
            </div>
          </div>
        </div>
        {courseResponse?.syllabusUrl ? (
          <div className='objective'>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className='detail-h1'>Course Syllabus 
                  <span className='m-3'>
                    <a href={courseResponse.syllabusUrl} className='p-2 navBtn' target="_blank" rel="noopener noreferrer">
                      Download</a>
                  </span></h1>

                  {/* Paragraph about the syllabus */}
                  <p className="syllabus-description">
                    The <strong> {courseResponse.title} </strong> syllabus provides a comprehensive outline of the course, detailing the topics, learning objectives, and key resources that will be covered throughout the course. It serves as a roadmap for students, helping them understand the structure, timeline, and requirements of the course.
                    The <strong> {courseResponse.title} </strong> syllabus also includes important information such as assessment methods, reading materials, and any prerequisites. By reviewing the <strong> {courseResponse.title} </strong> syllabus, students can better prepare for the course and stay on track with their learning journey.
                  </p>
                  {/* Displaying the Syllabus Viewer */}
                  <SyllabusViewer pdfUrl={courseResponse.syllabusUrl} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Suspense fallback={<div>Loading Course for...</div>}>

          <CourseFor course={courseResponse}></CourseFor>
        </Suspense>
        {courseResponse?.author && (
          <div className="author-details my-4 shadow container p-4 rounded"
          // style={{ 
          //   backgroundImage: `url(${mentorBanner})`, 
          //   backgroundRepeat: 'no-repeat', // Prevents repetition
          //   backgroundSize: 'cover',      // Ensures the image covers the whole element
          // }}
          >
            <h5 className='detail-h1'>Mentor: {courseResponse.author.name}</h5>
            {courseResponse.author.description && <div dangerouslySetInnerHTML={{ __html: courseResponse.author?.description || '' }} />
            }
            {courseResponse.author.image && (
              <img
                src={courseResponse.author.image}
                alt={courseResponse.author.name}
                className="author-profile-image"
                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
              />
            )}
            <Link
              className="m-5 text-primary"
              to={`/mentor-details/${courseResponse.author.name}/${courseResponse.author.id}`}
            >View Details
            </Link>
          </div>
        )}

        <Suspense fallback={<div>Loading Perks...</div>}>

          <CoursePerks course={courseResponse}></CoursePerks>
        </Suspense>


        <Suspense fallback={<div>Loading FAQ...</div>}>

          <CourseFaqs course={courseResponse}></CourseFaqs>
        </Suspense>

        <Suspense fallback={<div>Loading Course QA...</div>}>
          <CourseQA courseId={courseResponse.id.toString()} />
        </Suspense>
      </div>
    );
  }
};

export default CourseDetails;
