import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Counselor, CounselorFilter } from "../../data/campusData";
import { campusService } from "../../services/CampusService";
import { Helmet } from "react-helmet-async";
import './CampusCounselorPage.scss';
import notify from "../../services/notificationService";

const CampusCounselorPage: React.FC = () => {
    const navigate = useNavigate();

    const [counselors, setCounselors] = useState<Counselor[]>([]);
    const [filters, setFilters] = useState<CounselorFilter>({
        search: "",
        type: "college",
        city: "",
    });

    const [loading, setLoading] = useState<boolean>(false);

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const fetchCounselors = async () => {
        setLoading(true);
        try {
            const data = await campusService.getCounselors(filters);
            setCounselors(data);
        } catch (error) {
            console.error("Failed to fetch counselors.");
        } finally {
            setLoading(false);
        }
    };

    const handleFindCounselor = () => {
        fetchCounselors();
    };
    const handleFollow = () => {
        if (!localStorage.getItem('token')) {
            notify.info('Please login first', {
                position: 'top-right',
                autoClose: 2000,
            });
        }
    }

    useEffect(() => {
        // Fetch all counselors on initial load
        fetchCounselors();
    }, []);

    return (
        <div className="campus">
            <div className="container my-5">
                <Helmet>
                    <title>Campus Counselor - Course Echo | Connect with Experts</title>
                    <meta
                        name="description"
                        content="Explore the Campus Counselor module on Course Echo to connect with mentors, learn about colleges, schools, courses, and placements. Find the right guidance for your academic journey today!"
                    />
                    <meta
                        name="keywords"
                        content="Course Echo, campus counselor, mentor guidance, college insights, school information, career counseling, academic mentorship, education platform"
                    />
                    <meta name="author" content="Course Echo Team" />

                    {/* Open Graph meta tags for social media sharing */}
                    <meta property="og:title" content="Campus Counselor - Course Echo | Connect with Experts" />
                    <meta
                        property="og:description"
                        content="Explore the Campus Counselor module on Course Echo to connect with mentors, learn about colleges, schools, courses, and placements. Find the right guidance for your academic journey today!"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://courseecho.com/campus-counselor" />
                    <meta
                        property="og:image"
                        content="https://images.courseecho.com/counselor-banner.webp"
                    />
                    <meta property="og:image:alt" content="Course Echo Campus Counselor Banner" />

                    {/* Twitter card meta tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Campus Counselor - Course Echo | Connect with Experts" />
                    <meta
                        name="twitter:description"
                        content="Explore the Campus Counselor module on Course Echo to connect with mentors, learn about colleges, schools, courses, and placements. Find the right guidance for your academic journey today!"
                    />
                    <meta
                        name="twitter:image"
                        content="https://images.courseecho.com/counselor-banner.webp"
                    />
                    <meta name="twitter:image:alt" content="Course Echo Campus Counselor Banner" />
                </Helmet>


                <h1 className="text-center mb-2 detail-h1">Campus Counselor</h1>
                <p className="text-center">Connect with experienced mentors, explore information about top colleges,
                    schools, courses, and placement opportunities. Get personalized guidance and
                    insights to make informed decisions about your academic and career path.
                    Start your journey to success with expert advice today! </p>

                {/* Filter Section */}
                <div className="card p-4 mb-4 shadow-sm">
                    <div className="row g-3 align-items-center">
                        {/* Search Campus Name */}
                        <div className="col-md-4">
                            <input
                                type="text"
                                name="search"
                                value={filters.search}
                                onChange={handleFilterChange}
                                className="form-control"
                                placeholder="Search campus name"
                            />
                        </div>

                        {/* Radio Buttons for College/School */}
                        <div className="col-md-3">
                            <div>
                                <label className="me-3">
                                    <input
                                        type="radio"
                                        name="type"
                                        value="college"
                                        checked={filters.type === "college"}
                                        onChange={handleFilterChange}
                                    />{" "}
                                    College
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="type"
                                        value="school"
                                        checked={filters.type === "school"}
                                        onChange={handleFilterChange}
                                    />{" "}
                                    School
                                </label>
                            </div>
                        </div>

                        {/* City Dropdown */}
                        <div className="col-md-3">
                            <select
                                name="city"
                                value={filters.city}
                                onChange={handleFilterChange}
                                className="form-select"
                            >
                                <option value="">Select city</option>
                                <option value="bangalore">Bangalore</option>
                                <option value="delhi-ncr">Delhi/NCR</option>
                                <option value="pune">Pune</option>
                                <option value="bhopal">Bhopal</option>
                                <option value="lucknow">Lucknow</option>
                                <option value="patna">Patna</option>
                                <option value="kolkata">Kolkata</option>
                                <option value="mumbai">Mumbai</option>

                            </select>
                        </div>

                        {/* Find Counselor Button */}
                        <div className="col-md-2">
                            <button className="btn btn-primary w-100 navBtn m-0" onClick={handleFindCounselor}>
                                Find Counselor
                            </button>
                        </div>
                    </div>
                </div>

                {/* Counselor List */}
                <div>
                    {loading ? (
                        <p className="text-center">Loading counselors...</p>
                    ) : (
                        <>
                            <h5 className="mb-3">
                                Total Counselors: <span className="badge bg-secondary">{counselors.length}</span>
                            </h5>
                            <div className="list-group">
                                {counselors.map((counselor) => (
                                    <div
                                        key={counselor.id}
                                        className="list-group-item d-flex customd-flex  justify-content-between align-items-center"
                                    >
                                        {/* Counselor Info */}
                                        <div>
                                            <h6 className="mb-1">
                                                <Link to={`/campus-counselor-details/${counselor.id}`} className="text-primary">
                                                    {counselor.name}</Link>
                                                {counselor.verified == '1' && (
                                                    <span className="badge bg-success ms-2">
                                                        <i className="fa fa-check-circle"></i> Verified
                                                    </span>
                                                )}
                                            </h6>
                                            <div dangerouslySetInnerHTML={{ __html: counselor?.description || '' }} />


                                            {filters.type === 'college' ? (
                                                <div>
                                                    {counselor?.colleges?.map((college) => (

                                                        <span className="badge bg-primary ms-2 wrapSpan">
                                                            {college.title} - ({college.city})
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (null)}

                                            {filters.type === 'school' ? (
                                                <div>
                                                    {counselor?.schools?.map((college) => (

                                                        <span className="badge bg-primary ms-2 wrapSpan">
                                                            {college.title} - ({college.city})
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (null)}
                                        </div>

                                        {/* Action Buttons */}
                                        <div>
                                            <button className="btn btn-outline-primary me-2 viewBtn" onClick={() => handleFollow()}>Follow</button>
                                            <button
                                                className="btn btn-primary applyBtn"
                                                onClick={() => navigate(`/book-session/${counselor.id}`)}
                                            >
                                                Book Video Session
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="container">
                    <Link to="/join-campus-counselor" className="navBtn p-2">
                        <i className="fa fa-mortar-board"></i>
                        Join as Campus Counselor</Link>
                </div>
            </div>
        </div>
    );
};

export default CampusCounselorPage;
