import React, { Suspense, useEffect, useState } from 'react';
import courses from '../data/coursesData';
import { useNavigate } from 'react-router-dom';
import MetaHelmet from '../components/MetaHelmet';
const HomeTemplate1 = React.lazy(() => import('./HomeTemplate1'));
const HeroSection = React.lazy(() => import('../components/HeroSection'));

const Category = React.lazy(() => import('../components/Category'));
const Courses = React.lazy(() => import('./Courses'));
const CareerServiceCard = React.lazy(() => import('../components/CareerServiceCard'));
const JourneyCard = React.lazy(() => import('../components/JourneyCard'));
const BecomeMentorCard = React.lazy(() => import('../components/BecomeMentorCard'));
const HowItWorks = React.lazy(() => import('../components/HowItWorks'));
const MentorEarningCalculator = React.lazy(() => import('../components/MentorEarningCalculator'));

const CampusCounselorIntro = React.lazy(() => import('../components/CampusCounselorIntro'));



const Home: React.FC = () => {
  const navigate = useNavigate();

  const [selectedTemplate, setTemplate] = useState<string>("HeroSection");
  useEffect(() => {
    const templates = ["HeroSection", "HomeTemplate1"];
    const randomIndex = Math.floor(Math.random() * templates.length);
    setTemplate(templates[randomIndex])
  }, []);
  const [selectedCategoryDisplayId, setSelectedCategoryDisplayId] = useState<string | null>(null);

  // Filter courses based on the selected category
  const filteredCourses = selectedCategoryDisplayId
    ? courses.filter((course) => course.categoryDisplayId === selectedCategoryDisplayId)
    : courses;

  // Handle category selection and navigate to the Courses page
  const handleCategoryClick = (categoryDisplayId: string) => {
    setSelectedCategoryDisplayId(categoryDisplayId === selectedCategoryDisplayId ? null : categoryDisplayId); // Toggle category
    navigate(`/courses?course-category=${categoryDisplayId === selectedCategoryDisplayId ? '' : categoryDisplayId}`); // Navigate with categoryDisplayId
  };

  const renderTemplate = () => {
    switch (selectedTemplate) {
      case 'HeroSection':
        return (
          <Suspense fallback={<div>Loading Course Echo Hero Section...</div>}>
            <HeroSection
              imageUrl="https://images.courseecho.com/backgrounds/great-product-by-great-team.webp"
              link="/mentor"
              heading="Great Product is built by great teams"
              paragraph="Join our community of learners and industry experts. We provide top-tier tech courses designed to help you build in-demand skills, advance your career, and achieve your goals in the ever-evolving tech landscape."
              buttonText="Join Us"
            />
          </Suspense>
        );
      case 'HomeTemplate1':
        return (
          <Suspense fallback={<div>Loading Course Echo Template...</div>}>
            <HomeTemplate1 />
          </Suspense>
        );
      default:
        return null;
    }
  };

  // Generate schema.org structured data dynamically
  const generateSchemaData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://courseecho.com",
      "name": "Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers",
      "description": "Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning.",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://courseecho.com"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Course Echo",
        "logo": {
          "@type": "ImageObject",
          "url": "https://courseecho.com/logo.webp"
        }
      },
      "image": "https://courseecho.com/logo.webp",
      "keywords": "Course Echo, programming tutorials, online classes, offline courses, free programming courses, paid programming courses, hiring freelance trainers, coding classes",
      "author": {
        "@type": "Organization",
        "name": "Course Echo"
      },
      "sameAs": [
        "https://www.facebook.com/CourseEcho",
        "https://twitter.com/CourseEcho",
        "https://www.linkedin.com/company/courseecho"
      ],
      "mainEntity": [
          ...filteredCourses.map(course => ({
            "@type": "Course",
            "name": course.title,
            "description": course.description,
            "provider": {
              "@type": "Organization",
              "name": "Course Echo"
            },
            "educationalCredentialAwarded": "Certificate of Completion",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 4,
              "ratingCount": 1234,
              "reviewCount": 450
            },
            "offers": {
              "@type": "Offer",
              "url": `https://courseecho.com/course-details/${course.courseDisplayId}/${course.id}`,
              "priceCurrency": "INR",
              "price": course.fee,
              "eligibleRegion": {
                "@type": "Place",
                "name": "Worldwide"
              },
              "priceValidUntil": "2025-12-31",
              "category": course.categoryDisplayId || "General",  // Add category field
            },
            "hasCourseInstance": {
              "@type": "CourseInstance",
              "courseMode": "Online",  // All courses are online, recorded only
              "location": {
                "@type": "Place",
                "name": "Online"
              },
              "startDate": new Date(),  // Replace with actual course start date
              "courseWorkload": "P1D",  // Indicating it's a self-paced course
            }
          }))
        ],
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://courseecho.com"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Courses",
            "item": "https://courseecho.com/courses"
          },
          {
            "@type": "ListItem",
            "position": 3,
            "name": "Career",
            "item": "https://courseecho.com/career"
          },
          {
            "@type": "ListItem",
            "position": 4,
            "name": "Become a Mentor",
            "item": "https://courseecho.com/mentor"
          },
          {
            "@type": "ListItem",
            "position": 5,
            "name": "Roadmap",
            "item": "https://courseecho.com/resources/roadmap"
          }
        ]
      },
      "schema.org:additionalType": "https://schema.org/WebSite",
      "schema.org:potentialAction": {
        "@type": "SearchAction",
        "target": `https://courseecho.com/search?query={search_term}`,
        "query-input": "required name=search_term"
      }
    };
  };
  


  return (
    <div className="home">
      <MetaHelmet
        title="Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers"
        description="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning."
        keywords="Course Echo, programming tutorials, online classes, offline courses, free programming courses, paid programming courses, hiring freelance trainers, coding classes"
        author="Course Echo"
        ogTitle="Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers"
        ogDescription="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning."
        ogImage="https://courseecho.com/logo.webp"
        ogUrl="https://courseecho.com"
        twitterTitle="Course Echo | Online and Offline Classes, Paid & Free Courses, Hiring Expert Trainers"
        twitterDescription="Course Echo offers top-notch offline and online programming courses, including free and paid options. Hiring expert freelance trainers for personalized learning."
        twitterImage="https://courseecho.com/logo.webp"
        twitterCreator="@CourseEcho"
        schemaMarkup={generateSchemaData()}
      />
      {renderTemplate()}
      <Suspense fallback={<div className='container'>Loading Course Category...</div>}>
        <Category onCategoryClick={handleCategoryClick} selectedCategoryDisplayId={selectedCategoryDisplayId} />
      </Suspense>
      <Suspense fallback={<div className='container'>Loading Courses...</div>}>
        <Courses courses={filteredCourses} isImported={true} />
      </Suspense>
      <Suspense fallback={<div className='container'>Loading Become a Mentor...</div>}>
        <BecomeMentorCard />
      </Suspense>
      
      <Suspense fallback={<div className='container'>Loading Mentor Timeline...</div>}>

      <HowItWorks></HowItWorks>
      </Suspense>
      <Suspense fallback={<div className='container'>Loading Career Services...</div>}>
        <CareerServiceCard />
      </Suspense>
      <Suspense fallback={<div className='container'>Loading Journey...</div>}>
        <JourneyCard />
      </Suspense>

      <Suspense fallback={<div className='container'>Loading Calculator...</div>}>
        <MentorEarningCalculator />
      </Suspense>

      <Suspense fallback={<div className='container'>Loading campus counselor...</div>}>
        <CampusCounselorIntro isImported={true} />
      </Suspense>

    </div>
  );
};

export default Home;
