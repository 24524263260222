import React from 'react';
import './OnlineClass.scss';
import { Helmet } from 'react-helmet-async';
import { PROJECTNAME } from '../../utils/constants';

const OnlineClass: React.FC = () => {
  return (
    <div className="OnlineClass">
      <Helmet>
        <title>Online Classes - Learn from Anywhere</title>
        <meta name="description" content="Join our online classes and learn from the comfort of your home. Our expert instructors provide live sessions, recorded materials, and interactive Q&A sessions to enhance your learning experience." />
        <meta name="keywords" content="online classes, e-learning, interactive learning, live sessions" />
        <meta name="author" content={PROJECTNAME} />

        {/* Open Graph (OG) meta tags */}
        <meta property="og:title" content="Online Classes - Learn from Anywhere" />
        <meta property="og:description" content="Join our online classes and learn from the comfort of your home. Our expert instructors provide live sessions, recorded materials, and interactive Q&A sessions to enhance your learning experience." />
        <meta property="og:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://courseecho.com/services/online-class" /> {/* Replace with the specific page URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CourseEcho" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Online Classes - Learn from Anywhere" />
        <meta name="twitter:description" content="Join our online classes and learn from the comfort of your home. Our expert instructors provide live sessions, recorded materials, and interactive Q&A sessions to enhance your learning experience." />
        <meta name="twitter:image" content="https://images.courseecho.com/dark-logo.webp" /> {/* Replace with actual image URL */}
        <meta name="twitter:creator" content="@CourseEcho" /> {/* Replace with your Twitter handle */}
      </Helmet>

      <h1>Online Classes</h1>
      <p>
        Join our online classes and learn from the comfort of your home. 
        Our expert instructors provide live sessions, recorded materials, and interactive Q&A sessions to enhance your learning experience.
      </p>
      <h2>Key Features:</h2>
      <ul>
        <li>Flexible schedules to fit your routine</li>
        <li>Live interactive sessions with instructors</li>
        <li>Access to recorded classes</li>
        <li>Comprehensive course materials</li>
      </ul>
      <p>
        For more information, feel free to contact us. We look forward to helping you achieve your goals!
      </p>
    </div>
  );
};

export default OnlineClass;
