import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Breadcrumb, Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import './CallRequestPage.scss';
import { InterviewModel } from '../../data/interviewData';
import { getDashboardInterviews } from '../../services/DashboardService';
import MetaHelmet from '../../components/MetaHelmet';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="m-3">
    <Row>
      <Col md={1} xs={12}>
        <span>Search: </span>

      </Col>
      <Col md={10} xs={12}>

        <input
          className="form-control mb-2"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
          placeholder="Search records..."
        />
      </Col>
      <Col md={1} xs={12}>

        <Link to="/dashboard/add-interview" title='Add new interview' className="navBtn p-2 mb-2">
          <i className='fa fa-plus-circle'></i>
        </Link>
      </Col>
    </Row>

  </div>
);

const InterviewPage: React.FC = () => {
  const [Interviews, setInterviews] = useState<InterviewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardInterviews();
        setInterviews(response);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (id: string) => {
    axios
      .delete(`http://localhost/api/dashboard/Interviews/call-requests/${id}`)
      .then(() => {
        setInterviews(Interviews.filter((request) => request.id !== id));
      })
      .catch((error) => {
        setError('Error deleting call request');
      });
  };


  const filteredData = Interviews.filter((request) => {
    return (
      request.title.toLowerCase().includes(globalFilter.toLowerCase()) ||
      request.description.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
       <MetaHelmet
                title={` Interviews dashboard - ${localStorage.getItem('email')} - Course Echo`}
                description="Dashboard to manage interviews"
                keywords="course echo dashboard,Dashboard to manage interviews"
                author="Course Echo"
            />
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>Interviews : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>


        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Status</th>
              <th>Author</th>
              <th>Description</th>
              <th>Conclusion</th>
              <th>Seo Keywords</th>
              <th>DisplayTag</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          {!loading ? (
            <tbody>
              {filteredData.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>
                    <img src={request.image} height={100} width={100} className='img-fluid'></img>
                    <Link title='click to see preview' to={`/interview-details/${request.interviewDisplayId}/${request.id}`}
                    >
                      {request.title}
                    </Link>
                  </td>
                  <td>
                    <div className="badge-card-container">
                      <div
                        key={request.status}
                        className={`badge-${request.status?.toLowerCase()} badge-card`}
                      >
                        {request.status}
                      </div>
                    </div>
                  </td>
                  <td>
                    <img src={request.author?.image} height={50} width={50} className='img-fluid rounded'></img>
                    <br></br>
                    {request.author?.name}
                    <div>

                      <Link
                        className="m-5 text-primary"
                        to={`/mentor-details/${request.author?.name}/${request.author?.id}`}
                      ><i className='fa fa-eye'></i>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.description || '' }} />
                      
                  </td>
                  <td>
                    <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.conclusion || '' }} />
                    
                  </td>
                  
                  <td>
                    <div className="truncate p-2">
                      {request.seoKeywords.split(',').map((keyword, index) => (
                        <Badge pill bg="info" className="small d-inline-flex align-items-center mx-1 m-1" key={index}>
                          {keyword.trim()}
                        </Badge>
                      ))}
                    </div>

                  </td>
                  <td>

                    {request.interviewDisplayId}

                  </td>
                  <td>{request.created_at}</td>
                  <td>{request.updated_at}</td>
                  <td>
                    <div className="action-container">
                      <Link
                        title='Click to edit interview'
                        to={`/dashboard/edit-interview/${request.interviewDisplayId}/${request.id}`} // Pass the interview ID for editing
                      >
                        <i className="me-3 fa fa-pencil text-center"
                        ></i>
                      </Link>
                      {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                        <i
                          title='Click to delete'
                          onClick={() => handleDelete(request.id)}
                          className="me-3 fa fa-trash text-center"
                        >
                        </i>
                      ) : (null)}

                      <Link
                        title='Manage Questions : Add/Edit'
                        to={`/dashboard/interview-questions/${request.id}`} // Pass the interview ID for editing
                      >
                        <i className="me-3 fa fa-question-circle text-center">
                          <div className="countNo" title={`Total ${request.interview_questions?.length || 0} questions`}>
                            {request.interview_questions?.length || 0}
                          </div>
                        </i>

                      </Link>


                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (<div className='row'><h6 className='text-center mt-5'>Loading...</h6></div>)}
        </Table>

      </div>
    </div>
  );
};

export default InterviewPage;
