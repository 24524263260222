import axios from 'axios';
import { CountData, Course, CourseBulletPoints, CourseFaq, CourseFor, CourseOrders, CoursePerksBenifites, CoursePrerequisit, CourseResource, CourseTest, TopicProgress, Topics } from '../data/coursesData';
import { ENDPOINT } from '../utils/Endpoints';
import { DashboardCard } from '../data/dashboardCard';
import { CallRequest } from '../data/callRequestData';
import { UserInterface, UserProfileInterface } from '../data/userData';
import { InterviewModel, InterviewQuestionModel } from '../data/interviewData';
import { MenuItem } from '../data/menuItem';
import { Author, SocialLink, WorkHistory } from '../data/authorData';
import { Counselor, MappedCollege, MappedSchool } from '../data/campusData';

const MENU_API_URL = `${ENDPOINT}/dashboard/menu`;
const CARD_API_URL = `${ENDPOINT}/dashboard/cards`;
const CALL_REQUEST_API_URL = `${ENDPOINT}/dashboard/callrequest`;
const COURSE_INQUERY_API_URL = `${ENDPOINT}/dashboard/courseinquery`;
const USER_API_URL = `${ENDPOINT}/dashboard/users`;
const COURSE_API_URL = `${ENDPOINT}/dashboard/courses`;
const ORDER_API_URL = `${ENDPOINT}/dashboard/orders`;
const COURSE_ADD_API_URL = `${ENDPOINT}/dashboard/courses/add`
const COURSE_UPDATE_API_URL = `${ENDPOINT}/dashboard/courses/update`
const COURSE_IMG_UPLOAD_API_URL = `${ENDPOINT}/dashboard/courses/upload-image`
const COURSE_VDO_UPLOAD_API_URL = `${ENDPOINT}/dashboard/topics/upload-video`
const TOPIC_ADD_API_URL = `${ENDPOINT}/dashboard/topics/add`
const TOPIC_UPDATE_API_URL = `${ENDPOINT}/dashboard/topics/update`
const COURSE_SYLLABUS_UPLOAD_API_URL = `${ENDPOINT}/dashboard/courses/upload-syllabus`
const BULLET_POINT_ADD_API_URL = `${ENDPOINT}/dashboard/bullet-point/add`
const BULLET_POINT_UPDATE_API_URL = `${ENDPOINT}/dashboard/bullet-point/update`

const PERKS_BENEFITS_ADD_API_URL = `${ENDPOINT}/dashboard/perks-benefits/add`
const PERKS_BENEFITS_UPDATE_API_URL = `${ENDPOINT}/dashboard/perks-benefits/update`

const COURSE_FOR_ADD_API_URL = `${ENDPOINT}/dashboard/course-for/add`
const COURSE_FOR_UPDATE_API_URL = `${ENDPOINT}/dashboard/course-for/update`

const COURSE_FAQ_ADD_API_URL = `${ENDPOINT}/dashboard/course-faq/add`
const COURSE_FAQ_UPDATE_API_URL = `${ENDPOINT}/dashboard/course-faq/update`

const COURSE_RESOURCES_ADD_API_URL = `${ENDPOINT}/dashboard/course-resources/add`
const COURSE_RESOURCES_UPDATE_API_URL = `${ENDPOINT}/dashboard/course-resources/update`

const COURSE_PREREQUISITES_ADD_API_URL = `${ENDPOINT}/dashboard/course-prerequisites/add`
const COURSE_PREREQUISITES_UPDATE_API_URL = `${ENDPOINT}/dashboard/course-prerequisites/update`

const COURSE_TEST_ADD_API_URL = `${ENDPOINT}/dashboard/course-quiz/add`
const COURSE_TEST_UPDATE_API_URL = `${ENDPOINT}/dashboard/course-quiz/update`

const INTERVIEW_API_URL = `${ENDPOINT}/dashboard/interviews`;

const INTERVIEW_ADD_API_URL = `${ENDPOINT}/dashboard/interviews/add`
const INTERVIEW_UPDATE_API_URL = `${ENDPOINT}/dashboard/interviews/update`
const INTERVIEW_IMG_UPLOAD_API_URL = `${ENDPOINT}/dashboard/interviews/upload-image`

const INTERVIEW_Q_ADD_API_URL = `${ENDPOINT}/dashboard/interviews-q/add`
const INTERVIEW_Q_UPDATE_API_URL = `${ENDPOINT}/dashboard/interviews-q/update`

const MOST_SOLD_COURSES_API_URL = `${ENDPOINT}/dashboard/courses/most-sold`

const MOST_CLICKED_COURSES_API_URL = `${ENDPOINT}/dashboard/courses/most-clicked`

const COURSE_COUNT_ADD_UPDATE_API_URL = `${ENDPOINT}/dashboard/courses/count`
const DASHBOARD_AUTHORS_API_URL = `${ENDPOINT}/dashboard/authors`;

const DASHBOARD_TOP_AUTHORS_API_URL = `${ENDPOINT}/dashboard/authors/top`;
const DASHBOARD_COUNSELOR_API_URL = `${ENDPOINT}/dashboard/counselor`;

export const updateTopicProgress = async (topicProgressData: TopicProgress): Promise<TopicProgress> => {
  const response = await axios.post(
    `${ENDPOINT}/learning/track`,
    {
      ...topicProgressData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

export const getTopAuthors = async (): Promise<Author[]> => {
  try {
    const response = await axios.get(DASHBOARD_TOP_AUTHORS_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const getDashboardAuthors = async (): Promise<Author[]> => {
  try {
    const response = await axios.get(DASHBOARD_AUTHORS_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};
export const addAuthor = async (authorData: Author): Promise<Author> => {
  const response = await axios.post(
    `${ENDPOINT}/authors/create`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

export const editAuthorsByToken = async (authorData: Author): Promise<Author> => {
  const response = await axios.put(
    `${ENDPOINT}/authors/update/${authorData.id}`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};
export const editAuthorsById = async (authorData: Author): Promise<Author> => {
  const response = await axios.put(
    `${ENDPOINT}/authors/update/${authorData.id}`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

export const editCounselorById = async (authorData: Counselor): Promise<Counselor> => {
  const response = await axios.put(
    `${ENDPOINT}/campus/${authorData.id}`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};
export const addCounselorCollege = async (authorData: MappedCollege): Promise<MappedCollege> => {
  const response = await axios.post(
    `${ENDPOINT}/campus/counselor/college`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};
export const editCounselorCollegeById = async (authorData: MappedCollege): Promise<MappedCollege> => {
  const response = await axios.put(
    `${ENDPOINT}/campus/counselor/college/${authorData.id}`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};


export const addCounselorSchool = async (authorData: MappedSchool): Promise<MappedSchool> => {
  const response = await axios.post(
    `${ENDPOINT}/campus/counselor/school`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};
export const editCounselorSchoolById = async (authorData: MappedSchool): Promise<MappedSchool> => {
  const response = await axios.put(
    `${ENDPOINT}/campus/counselor/school/${authorData.id}`,
    {
      ...authorData, // Payload
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

export const uploadSocialImage = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await axios.post(`${ENDPOINT}/authors/social/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.imageUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};

export const addAuthorSocialLink = async (courseData: SocialLink): Promise<SocialLink> => {
  try {
    const response = await axios.post(
      `${ENDPOINT}/authors/social/create`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateAuthorSocialLink = async (courseData: SocialLink): Promise<SocialLink> => {
  try {
    const response = await axios.put(
      `${ENDPOINT}/authors/social/update/${courseData.id}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteAuthorSocialLink = async (id: string): Promise<SocialLink> => {
  try {
    const response = await axios.delete(
      `${ENDPOINT}/authors/social/delete/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const uploadAuthorWorkImage = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await axios.post(`${ENDPOINT}/authors/work/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.imageUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};

export const addAuthorWorkHistory = async (courseData: WorkHistory): Promise<WorkHistory> => {
  try {
    const response = await axios.post(
      `${ENDPOINT}/authors/work/create`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding :', error.response?.data || error.message);
    throw error;
  }
};

export const updateAuthorWorkHistory = async (courseData: WorkHistory): Promise<WorkHistory> => {
  try {
    const response = await axios.put(
      `${ENDPOINT}/authors/work/update/${courseData.id}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding :', error.response?.data || error.message);
    throw error;
  }
};

export const deleteAuthorWorkHistory = async (id: string): Promise<WorkHistory> => {
  try {
    const response = await axios.delete(
      `${ENDPOINT}/authors/work/delete/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteCounselorCollege = async (id: string): Promise<MappedCollege> => {
  try {
    const response = await axios.delete(
      `${ENDPOINT}/campus/counselor/college/delete/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteCounselorSchool = async (id: string): Promise<MappedSchool> => {
  try {
    const response = await axios.delete(
      `${ENDPOINT}/campus/counselor/school/delete/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding:', error.response?.data || error.message);
    throw error;
  }
};


export const uploadAuthorImage = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await axios.post(`${ENDPOINT}/authors/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.imageUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};
export const getCounselorByToken = async (): Promise<UserProfileInterface[]> => {
  const response = await axios.get<UserProfileInterface[]>(`${ENDPOINT}/dashboard/campus/counselor`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`

    },
  });
  return response.data;
};

export const getCounselorById = async (id:string): Promise<UserProfileInterface[]> => {
  const response = await axios.get<UserProfileInterface[]>(`${ENDPOINT}/dashboard/campus/counselor/by-id/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`

    },
  });
  return response.data;
};

export const getAuthorsByToken = async (): Promise<Author[]> => {
  const response = await axios.get<Author[]>(`${ENDPOINT}/dashboard/authors/details`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`

    },
  });
  return response.data;
};

export const addCourseCount = async (courseData: CountData): Promise<CountData> => {
  try {
    const response = await axios.post(
      `${COURSE_COUNT_ADD_UPDATE_API_URL}/${courseData.countColumn}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const getMostClickedCourses = async (): Promise<Course[]> => {
  try {
    const response = await axios.get(MOST_CLICKED_COURSES_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getMostSoldCourses = async (): Promise<Course[]> => {
  try {
    const response = await axios.get(MOST_SOLD_COURSES_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardMenu = async (): Promise<MenuItem[]> => {
  try {
    const response = await axios.get(MENU_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardCards = async (): Promise<DashboardCard[]> => {
  try {
    const response = await axios.get(CARD_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardCallRequest = async (): Promise<CallRequest[]> => {
  try {
    const response = await axios.get(CALL_REQUEST_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardCourseInquery = async (): Promise<CallRequest[]> => {
  try {
    const response = await axios.get(COURSE_INQUERY_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardUsers = async (userType: string): Promise<UserInterface[]> => {
  try {
    const response = await axios.get(`${USER_API_URL}/${userType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardCourses = async (): Promise<Course[]> => {
  try {
    const response = await axios.get(`${COURSE_API_URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardOrders = async (orderType: string): Promise<CourseOrders[]> => {
  try {
    const response = await axios.get(`${ORDER_API_URL}/${orderType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const addCourse = async (courseData: Course): Promise<Course> => {
  try {
    const response = await axios.post(
      `${COURSE_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourse = async (courseId: string, courseData: Course): Promise<Course> => {
  try {
    const response = await axios.put(
      `${COURSE_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};
export const uploadImage = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await axios.post(COURSE_IMG_UPLOAD_API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.imageUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};
export const uploadVideo = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('video', imageFile);

  try {
    const response = await axios.post(COURSE_VDO_UPLOAD_API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.videoUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};
export const getDashboardCourseById = async (courseId: string): Promise<Course[]> => {
  try {
    const response = await axios.get(`${COURSE_API_URL}/${courseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const addTopic = async (courseData: Topics): Promise<Topics> => {
  try {
    const response = await axios.post(
      `${TOPIC_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateTopic = async (courseId: string, courseData: Topics): Promise<Topics> => {
  try {
    const response = await axios.put(
      `${TOPIC_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};
export const uploadPDF = async (pdfFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('pdf', pdfFile);

  try {
    const response = await axios.post(COURSE_SYLLABUS_UPLOAD_API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.syllabusUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};

export const addCourseBulletPoint = async (courseData: CourseBulletPoints): Promise<CourseBulletPoints> => {
  try {
    const response = await axios.post(
      `${BULLET_POINT_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourseBulletPoint = async (courseId: string, courseData: CourseBulletPoints): Promise<CourseBulletPoints> => {
  try {
    const response = await axios.put(
      `${BULLET_POINT_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCoursePerksBenifites = async (courseData: CoursePerksBenifites): Promise<CoursePerksBenifites> => {
  try {
    const response = await axios.post(
      `${PERKS_BENEFITS_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCoursePerksBenifites = async (courseId: string, courseData: CoursePerksBenifites): Promise<CoursePerksBenifites> => {
  try {
    const response = await axios.put(
      `${PERKS_BENEFITS_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCourseFor = async (courseData: CourseFor): Promise<CourseFor> => {
  try {
    const response = await axios.post(
      `${COURSE_FOR_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourseFor = async (courseId: string, courseData: CourseFor): Promise<CourseFor> => {
  try {
    const response = await axios.put(
      `${COURSE_FOR_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCoursePrerequisit = async (courseData: CoursePrerequisit): Promise<CoursePrerequisit> => {
  try {
    const response = await axios.post(
      `${COURSE_PREREQUISITES_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCoursePrerequisit = async (courseId: string, courseData: CoursePrerequisit): Promise<CoursePrerequisit> => {
  try {
    const response = await axios.put(
      `${COURSE_PREREQUISITES_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCourseTest = async (courseData: CourseTest): Promise<CourseTest> => {
  try {
    const response = await axios.post(
      `${COURSE_TEST_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourseTest = async (courseId: number, courseData: CourseTest): Promise<CourseTest> => {
  try {
    const response = await axios.put(
      `${COURSE_TEST_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCourseFaq = async (courseData: CourseFaq): Promise<CourseFaq> => {
  try {
    const response = await axios.post(
      `${COURSE_FAQ_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourseFaq = async (courseId: string, courseData: CourseFaq): Promise<CourseFaq> => {
  try {
    const response = await axios.put(
      `${COURSE_FAQ_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const addCourseResource = async (courseData: CourseResource): Promise<CourseResource> => {
  try {
    const response = await axios.post(
      `${COURSE_RESOURCES_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCourseResource = async (courseId: string, courseData: CourseResource): Promise<CourseResource> => {
  try {
    const response = await axios.put(
      `${COURSE_RESOURCES_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};

export const getDashboardInterviews = async (): Promise<InterviewModel[]> => {
  try {
    const response = await axios.get(`${INTERVIEW_API_URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getDashboardInterviewById = async (courseId: string): Promise<InterviewModel[]> => {
  try {
    const response = await axios.get(`${INTERVIEW_API_URL}/${courseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const addInterview = async (courseData: InterviewModel): Promise<InterviewModel> => {
  try {
    const response = await axios.post(
      `${INTERVIEW_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateInterview = async (courseId: string, courseData: InterviewModel): Promise<InterviewModel> => {
  try {
    const response = await axios.put(
      `${INTERVIEW_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};
export const uploadInterviewImage = async (imageFile: File): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const response = await axios.post(INTERVIEW_IMG_UPLOAD_API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data.imageUrl; // Assuming the API returns the image URL
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred while uploading the image.');
  }
};

export const addInterviewQ = async (courseData: InterviewQuestionModel): Promise<InterviewQuestionModel> => {
  try {
    const response = await axios.post(
      `${INTERVIEW_Q_ADD_API_URL}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error adding course:', error.response?.data || error.message);
    throw error;
  }
};

export const updateInterviewQ = async (courseId: string, courseData: InterviewQuestionModel): Promise<InterviewQuestionModel> => {
  try {
    const response = await axios.put(
      `${INTERVIEW_Q_UPDATE_API_URL}/${courseId}`,
      courseData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error('Error updating course:', error.response?.data || error.message);
    throw error;
  }
};