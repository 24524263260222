import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, Topics } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addTopic, updateTopic, uploadVideo } from '../../services/DashboardService';
import ReactPlayer from 'react-player';

const AddEditTopicPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [topic, setTopic] = useState<Topics>({
        id: 0,
        title: '',
        description: '',
        externalLink: '',
        isFree: false,
        isTrailer: '0',
        source: 'local',
        courseId: courseid ? parseInt(courseid) : 0,
        parentId: 0,
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [isUrlSelected, setIsUrlSelected] = useState<boolean>(false);
    const [parentTopics, setParentTopics] = useState<Topics[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundTopic = course?.Topics?.find((v: Topics) => v.id.toString() === id);
                    if (foundTopic) setTopic(foundTopic);
                    setParentTopics(course?.Topics?.filter((v) => v.parentId === null) || []);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setTopic((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (topic: Topics): string[] => {
        const errors: string[] = [];
        if (!topic.title) errors.push('Title is required.');
        if (!topic.description) errors.push('Description is required.');
        if (!topic.externalLink && !videoFile) errors.push('Video is required.');
        return errors;
    };

    const handleSaveTopic = async () => {
        const errors = validateTopic(topic);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (videoFile) {
                const videoUrl = await uploadVideo(videoFile);
                topic.externalLink = videoUrl;
            }

            if (isEditing) {
                if (topic.parentId === 0) delete topic['parentId']
                await updateTopic(topic.id.toString(), topic);
            } else {
                if (topic.parentId === 0) delete topic['parentId']
                await addTopic(topic);
            }
            navigate(`/dashboard/topics/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving topic.');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleVideoFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setVideoFile(file);
            setTopic((prev) => ({ ...prev, externalLink: '' }));
        }
    };

    const handleSourceChange = (source: string) => {
        setTopic((prev) => ({ ...prev, source }));
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit Topic' : 'Add New Topic'} - Dashboard`}
                description="Dashboard to add/edit topic"
                keywords="dashboard, topic management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    <Link to={`/dashboard/topics/${courseid}`}>Topics ({courseItem?.Topics?.length})</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{isEditing ? 'Edit Topic' : 'Add Topic'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Topic Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter topic title"
                                name="title"
                                value={topic.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter description"
                                name="description"
                                value={topic.description}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formVideo">
                            <Form.Label>Topic Video</Form.Label>
                            <div className="image-upload-options">
                                <Form.Check
                                    type="radio"
                                    label="Provide Video URL"
                                    checked={isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(true);
                                        setVideoFile(null);
                                    }}
                                />
                                {topic.externalLink
                                    && (
                                        <ReactPlayer
                                            url={topic?.externalLink}
                                            playing={false}
                                            controls={true}
                                            muted={false}
                                            width="200px"
                                            height="220px"
                                        />
                                    )}
                                {isUrlSelected && (
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter video URL"
                                        name="externalLink"
                                        value={topic.externalLink}
                                        onChange={handleChange}
                                    />
                                )}

                                <Form.Check
                                    type="radio"
                                    label="Upload Video"
                                    checked={!isUrlSelected}
                                    onChange={() => {
                                        setIsUrlSelected(false);
                                        setTopic((prev) => ({ ...prev, externalLink: '' }));
                                    }}
                                />
                                {!isUrlSelected && (
                                    <Form.Control
                                        type="file"
                                        accept="video/*"
                                        onChange={handleVideoFileChange}
                                    />
                                )}

                                {videoFile && (
                                    <ReactPlayer
                                        url={URL.createObjectURL(videoFile)}
                                        playing={true}
                                        controls={true}
                                        muted={true}
                                        width="200px"
                                        height="220px"
                                    />
                                )}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={12}>
                        <Form.Group controlId="formSource">
                            <Form.Label>Source</Form.Label>
                            <div className="source-card-container">
                                {['youtube', 'local'].map((source) => (
                                    <div
                                        key={source}
                                        className={`source-${source.toLowerCase()} source-card ${topic.source === source ? 'selected' : ''}`}
                                        onClick={() => handleSourceChange(source)}
                                    >
                                        {source.charAt(0).toUpperCase() + source.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={12}>

                        <Form.Group controlId="formIsTrailer">
                            <Form.Label>Is Trailer</Form.Label>
                            <Form.Control
                                as="select"
                                name="isTrailer"
                                value={topic.isTrailer}
                                onChange={handleChange}
                            >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formParentTopic">
                            <Form.Label>Parent Topic</Form.Label>
                            <Form.Control
                                as="select"
                                name="parentId"
                                value={topic.parentId}
                                onChange={handleChange}
                            >
                                <option value="">Select Parent</option>
                                {parentTopics.map((parent) => (
                                    <option key={parent.id} value={parent.id}>
                                        {parent.title}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveTopic} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add Topic'}
                </Button>
            </Form>
        </div>
    );
};

export default AddEditTopicPage;
