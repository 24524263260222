import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CoursePrerequisit } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCoursePrerequisit, updateCoursePrerequisit } from '../../services/DashboardService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditCoursePrerequisitPage: React.FC = () => {
    const { courseid, id } = useParams<{ courseid: string, id: string }>();
    const [coursePrerequisit, setCoursePrerequisit] = useState<CoursePrerequisit>({
        id: '',
        title: '',
        description: '',
        courseId: courseid ? parseInt(courseid) : 0
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(id ? true : false);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.Prerequisites?.find((v: CoursePrerequisit) => v.id === id);
                    if (foundItem) setCoursePrerequisit(foundItem);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCoursePrerequisit((prev) => ({ ...prev, [name]: value }));
    };

    const validateTopic = (coursePrerequisit: CoursePrerequisit): string[] => {
        const errors: string[] = [];
        if (!coursePrerequisit.title) errors.push('Title is required.');
        if (!coursePrerequisit.description) errors.push('Description is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(coursePrerequisit);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCoursePrerequisit(coursePrerequisit.id || '', coursePrerequisit);
            } else {
                await addCoursePrerequisit(coursePrerequisit);
            }
            navigate(`/dashboard/course-prerequisit/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTemplateChange = (title: string, externalLink: string, description: string) => {
        setCoursePrerequisit((prev) => ({ ...prev, title: title, externalLink: externalLink, description: description }));

    };
    const handleEditorChange = (value: string) => {
        setCoursePrerequisit((prev) => ({ ...prev, description: value }));
    };
    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit course resource' : 'Add New course resource'} - Dashboard`}
                description="Dashboard to add/edit course resource"
                keywords="dashboard, course resource management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/course-prerequisit/${courseid}`}>Course Prerequisit ({courseItem?.CoursePerksBenefits?.length}) </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}
            <Row>

                <Col md={12} xs={12}>
                    <h5>Use Templates: </h5>
                    <div className="perks-card-container">
                        {[

                            {
                                title: 'Eagerness to Learn',
                                externalLink: '',
                                description: 'A proactive attitude and willingness to explore and implement practices.',
                            }

                        ].map((item, index) => (
                            <div
                                key={index}
                                className={`perks-common perks-card ${item.title === coursePrerequisit?.title ? 'selected' : ''}`}
                                onClick={() => handleTemplateChange(item.title, item.externalLink, item.description)}
                            >
                                <h6>{item.title}</h6>
                                {item.externalLink && (<p><Link to={item.externalLink}>Link</Link></p>
                                )}
                                <p dangerouslySetInnerHTML={{ __html: item?.description || '' }} />

                            </div>
                        ))}
                    </div>

                </Col>
            </Row>
            <hr></hr>
            <Form>
                <Row>
                    <h6>Add Custom:</h6>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={coursePrerequisit.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIcon">
                            <Form.Label>External Link
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter link"
                                name="externalLink"
                                value={coursePrerequisit.externalLink}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group controlId="formIcon">
                            <Form.Label>Description
                            </Form.Label>
                            <ReactQuill
                                value={coursePrerequisit.description}
                                onChange={handleEditorChange}
                                placeholder="Write..."
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditCoursePrerequisitPage;
