import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Modal, Breadcrumb, Alert, Row, Col } from 'react-bootstrap';
import { Course, CourseTest } from '../../data/coursesData';
import { getCourseById } from '../../services/CourseService';
import './AddEditCoursePage.scss';
import MetaHelmet from '../../components/MetaHelmet';
import { addCourseTest, updateCourseTest } from '../../services/DashboardService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditCourseQuizPage: React.FC = () => {
    const parseOptions = (options: any): string[] => {
        if (Array.isArray(options)) {
            return options; // Already an array, return as is
        }
        try {
            const parsed = JSON.parse(options);
            if (Array.isArray(parsed)) {
                return parsed; // Successfully parsed to an array
            }
        } catch (err) {
            console.error('Error parsing options JSON:', err);
        }
        return []; // Return an empty array in case of errors
    };
    const { courseid, id } = useParams<{ courseid: string; id: string }>();
    const [courseTest, setCourseTest] = useState<CourseTest>({
        id: 0,
        title: '',
        description: '',
        options: [],
        correctAnswer: '',
        courseId: courseid ? parseInt(courseid) : 0,
    });
    const [courseItem, setCourseItem] = useState<Course>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (courseid) {
            setIsEditing(!!id);
            setLoading(true);
            getCourseById(courseid)
                .then((course) => {
                    setCourseItem(course);
                    const foundItem = course?.Tests?.find((v: CourseTest) => v.id === (id || 0));
                    const data: CourseTest = {
                        ...foundItem || {
                            id: 0,
                            title: '',
                            description: '',
                            options: [],
                            correctAnswer: '',
                            courseId: courseid ? parseInt(courseid) : 0,
                        }, options: parseOptions(foundItem?.options)
                    }
                    console.log(data)
                    if (data) setCourseTest(data);
                })
                .catch(() => {
                    setErrorMessage('Error fetching course data');
                    setShowError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [courseid, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCourseTest((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionChange = (index: number, value: string) => {
        const updatedOptions = [...courseTest.options];
        updatedOptions[index] = value;
        setCourseTest((prev) => ({ ...prev, options: updatedOptions }));
    };

    const addOption = () => {
        const title = `Option No : ${courseTest.options.length + 1}`;
        setCourseTest((prev) => ({ ...prev, options: [...prev.options, title] }));
    };

    const removeOption = (index: number) => {
        const updatedOptions = [...courseTest.options];
        updatedOptions.splice(index, 1);
        setCourseTest((prev) => ({
            ...prev,
            options: updatedOptions,
            correctAnswer: prev.correctAnswer === updatedOptions[index] ? '' : prev.correctAnswer,
        }));
    };

    const validateTopic = (courseTest: CourseTest): string[] => {
        const errors: string[] = [];
        if (!courseTest.title) errors.push('Title is required.');
        if (!courseTest.description) errors.push('Description is required.');
        if (courseTest.options.length === 0) errors.push('At least one option is required.');
        if (!courseTest.correctAnswer) errors.push('Correct answer is required.');
        return errors;
    };

    const handleSaveItem = async () => {
        const errors = validateTopic(courseTest);
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }

        setLoading(true);
        try {
            if (isEditing) {
                await updateCourseTest(courseTest.id || 0, courseTest);
            } else {
                await addCourseTest(courseTest);
            }
            navigate(`/dashboard/course-quiz/${courseid}`);
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || 'Error saving .');
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="add-edit-course-page">
            <MetaHelmet
                title={`${isEditing ? 'Edit course quiz' : 'Add New course quiz'} - Dashboard`}
                description="Dashboard to add/edit course quiz"
                keywords="dashboard, course quiz management"
                author="Course Echo"
            />

            <Breadcrumb className="p-1">
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/courses`}>Courses: {courseItem?.title}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/dashboard/course-quiz/${courseid}`}>
                        Course Quiz ({courseItem?.CoursePerksBenefits?.length})
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{isEditing ? 'Edit' : 'Add'}</Breadcrumb.Item>
            </Breadcrumb>

            {showError && (
                <Modal show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowError(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {validationErrors.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                name="title"
                                value={courseTest.title}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <ReactQuill
                                value={courseTest.description}
                                onChange={(value) =>
                                    setCourseTest((prev) => ({ ...prev, description: value }))
                                }
                                placeholder="Write..."
                            />
                        </Form.Group>
                        <Form.Group controlId="formExternalLink">
                            <Form.Label>External Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter link"
                                name="externalLink"
                                value={courseTest.externalLink}
                                onChange={handleChange}
                            />
                        </Form.Group>

                    </Col>

                    <Col md={6}>

                        <Form.Group controlId="formOptions">
                            <Form.Label>Options <i className='fa fa-plus text-success' title='add' onClick={addOption}></i>
                            </Form.Label>
                            {courseTest.options.map((option, index) => (
                                <Row key={index} className="mb-2">
                                    <Col xs={10}>
                                        <Form.Control
                                            type="text"
                                            value={option}
                                            onChange={(e) => handleOptionChange(index, e.target.value)}
                                            placeholder={`Option ${index + 1}`}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <i className='fa fa-trash text-danger button' title='remove' onClick={() => removeOption(index)}></i>

                                    </Col>
                                </Row>
                            ))}

                        </Form.Group>
                        <Form.Group controlId="formCorrectAnswer">
                            <Form.Label>Correct Answer</Form.Label>
                            <Col xs={10}>
                                <Form.Control
                                    as="select"
                                    name="correctAnswer"
                                    value={courseTest.correctAnswer}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Correct Answer</option>
                                    {courseTest.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSaveItem} disabled={loading}>
                    {isEditing ? 'Save Changes' : 'Add'}
                </Button>
            </Form>
        </div >
    );
};

export default AddEditCourseQuizPage;
