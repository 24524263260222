import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './CourseInquiryForm.module.scss';
import CourseInquiryService, { CourseInquiryData } from '../services/CourseInquiryService';

interface CourseInquiryFormProps {
    onClose: () => void;
    onSubmit: (formData: { name: string; mobile: string; email: string; courseId: string; message: string }) => void;
    courses: { id: string, title: string }[];
}

const CourseInquiryForm: React.FC<CourseInquiryFormProps> = ({ onClose, onSubmit, courses }) => {
    const [formData, setFormData] = useState<CourseInquiryData>({
        name: '',
        mobile: '',
        email: '',
        courseId: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await CourseInquiryService.sendCourseInquiry(formData);
            onSubmit(formData); // Optional: Call parent callback if needed
            onClose(); // Close the form after submission
        } catch (err: any) {
            if (err.messages)
                setError('Failed to submit the inquiry. Please try again. \n ' + JSON.stringify(err.messages));
            else
                setError('Failed to submit the inquiry. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='modalOverlay' onClick={onClose}></div>
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <button className={styles.closeButton} onClick={onClose}>×</button>
                    <h5 className='text text-primary text-center'>Quick Inquiry</h5>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Name:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} className={styles.input} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Mobile:</label>
                            <input type="text" name="mobile" maxLength={10} value={formData.mobile} onChange={handleChange} className={styles.input} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Email:</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} className={styles.input} required />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="courseId" className={styles.label}>Course:</label>
                            <select
                                name="courseId"
                                id="courseId"
                                aria-label="Select a course"
                                title="Select a course"
                                value={formData.courseId}
                                onChange={handleChange}
                                className={styles.select}
                                required
                            >
                                <option value="">--Select a course--</option>
                                {courses.map((course) => (
                                    <option key={course.id} value={course.id}>{course.title}</option>
                                ))}
                                <option key="other" value="other">Other</option>
                            </select>
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.label}>Message:</label>
                            <textarea name="message" value={formData.message} onChange={handleChange} className={styles.textarea}></textarea>
                        </div>
                        <button type="submit" disabled={loading} className='viewBtn'>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                        {error && <p className={styles.error}>{error}</p>}
                    </form>
                </div>
            </div>
        </>
    );

};

export default CourseInquiryForm;
