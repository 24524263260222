import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InterviewModel, InterviewQuestionModel } from '../../data/interviewData';
import './InterviewQuestionDetails.scss';
import { getInterviewByQuestion } from '../../services/InterviewService';
import MetaHelmet from '../../components/MetaHelmet';
import { Breadcrumb } from 'react-bootstrap';

const InterviewQuestionDetails: React.FC = () => {
    const { questionDisplayId, interview_id, id } = useParams<{
        questionDisplayId: string;
        interview_id: string;
        id: string;
    }>();
    
    const decodedQText = decodeURIComponent(questionDisplayId || '');
    const [question, setQuestion] = useState<InterviewQuestionModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
  const [interview, setInterview] = useState<InterviewModel | null>(null);

    useEffect(() => {
        const fetchQuestionDetails = async () => {
            try {
                const response = await getInterviewByQuestion(interview_id!, id!);
                const data = response.length > 0 ? response[0] : null;
                setInterview(data);

                // Check if 'data' and 'data.interview_questions' exist, then set the first question
                if (data && data.interview_questions && data.interview_questions.length > 0) {
                    const question = data.interview_questions.find((q: InterviewQuestionModel) => q.id === id!);
                    setQuestion(question || null); // Set the found question or null if not found
                }
            } catch (err) {
                setError('Error fetching question details');
            } finally {
                setLoading(false);
            }
        };

        fetchQuestionDetails();
    }, [interview_id, id]);

    // Schema and meta data generation for the current interview question
    const generateSchemaData = () => ({
        "@context": "https://schema.org",
        "@type": "Question",
        "name": question?.question_text,
        "text": question?.answer_text,
        "url": `https://courseecho.com/interview-question/${decodedQText}/${interview_id}/${id}`,
        "author": {
            "@type": "Person",
            "name": "Course Echo Team" // Replace with actual author if needed
        },
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://courseecho.com/interview-question/${decodedQText}/${interview_id}/${id}`
        },
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://courseecho.com/search?query={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const pageTitle = `Interview Question: ${question?.question_text || "Loading..."} - Course Echo`;
    const pageDescription = `Detailed explanation of the question: ${question?.question_text || "Loading..."}. Read the answer and understand the concept better.`;

    return (
        <div className="container interviewQDetailsPage">
            <MetaHelmet
                title={pageTitle}
                description={pageDescription}
                keywords={`${question?.question_text} , interview question, interview preparation, programming questions, Course Echo, coding interview, coding answers`}
                author="Course Echo Team"
                ogTitle={pageTitle}
                ogDescription={pageDescription}
                ogImage="https://images.courseecho.com/dark-logo.webp" // Replace with actual image if needed
                ogUrl={`https://courseecho.com/interview-question/${decodedQText}/${interview_id}/${id}`}
                twitterTitle={pageTitle}
                twitterDescription={pageDescription}
                twitterImage="https://images.courseecho.com/dark-logo.webp" // Replace with actual image if needed
                twitterCreator="@CourseEcho"
                schemaMarkup={generateSchemaData()}
            />

<Breadcrumb className='p-1 mb-2'>
          <Breadcrumb.Item>
          <Link to="/interviews"> Interviews</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to={`/interview-details/${interview?.interviewDisplayId}/${interview?.id}`}> {interview?.title}</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item active>{question?.questionDisplayId}</Breadcrumb.Item>
        </Breadcrumb>
<div className="card p-3">
            <h4 className='detail-h1'>Question:</h4>
            <div dangerouslySetInnerHTML={{ __html: question?.question_text || '' }} />

            <h5 className="mt-3 detail-h1">Answer:</h5>
            <div dangerouslySetInnerHTML={{ __html: question?.answer_text || '' }} />
        </div>
        </div>
    );
};

export default InterviewQuestionDetails;
