import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table, Breadcrumb, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'; // Import Link
import { getDashboardCourseById } from '../../services/DashboardService';
import './CallRequestPage.scss';
import { Course, CourseTest, Topics } from '../../data/coursesData';

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mb-3">
    <span>Search: </span>
    <input
      className="form-control"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || '')} // ensure that we always pass a string
      placeholder="Search records..."
    />
  </div>
);

const CourseQuizPage: React.FC = () => {
  const parseOptions = (options: any): string[] => {
    if (Array.isArray(options)) {
      return options; // Already an array, return as is
    }
    try {
      const parsed = JSON.parse(options);
      if (Array.isArray(parsed)) {
        return parsed; // Successfully parsed to an array
      }
    } catch (err) {
      console.error('Error parsing options JSON:', err);
    }
    return []; // Return an empty array in case of errors
  };
  
  const [Courses, setCourse] = useState<Course>();
  const [courseTest, setCourseTest] = useState<CourseTest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardCourseById(id || '');
        setCourse(response[0]);
        setCourseTest(response[0].Tests || [])
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = (id: number) => {

  };


  const filteredData = courseTest.filter((request) => {
    return (
      request.title.toLowerCase().includes(globalFilter.toLowerCase())
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CallRequest">
      <div className="container mt-4">
        {/* Breadcrumb Component */}
        <Breadcrumb className='p-1'>
          <Breadcrumb.Item>
            <Link to="/"> Home</Link>

          </Breadcrumb.Item> {/* Use Link instead of href */}
          <Breadcrumb.Item>
            <Link to="/dashboard"> Dashboard</Link>
          </Breadcrumb.Item> {/* Use Link */}
          <Breadcrumb.Item>
            <Link to="/dashboard/courses"> Courses : {Courses?.title}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Course Quiz : ({filteredData.length})</Breadcrumb.Item>
        </Breadcrumb>

        <div className="mb-3">
          <Link to={`/dashboard/add-course-quiz/${Courses?.id}`} className="btn btn-primary text-success">
            Add New
          </Link>
        </div>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <Table striped bordered hover responsive className='table-fixed-header table-fixed-id-title table-fixed-actions'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>External Link</th>
              <th>Description</th>
              <th>Options</th>
              <th>Answer</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((request) => (
              <tr key={request.id}>
                <td>{request.id}</td>
                <td>
                <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.title || '' }} />

                  </td>
                <td>
                  {request.externalLink}
                </td>
                <td>
                <p className='truncate' dangerouslySetInnerHTML={{ __html: request?.description || '' }} />

                </td>
                <td>
                <ul>
                        {parseOptions(request.options).map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </td>
                <td>
                  <p className='truncate'>
                    {request.correctAnswer}
                  </p>
                </td>
                <td>{request.dateAdded}</td>
                <td>{request.dateUpdated}</td>
                <td>
                  <Link
                    className="me-2 btn btn-warning navBtn"
                    to={`/dashboard/edit-course-quiz/${request.courseId}/${request.id}`} // Pass the course ID for editing
                  >
                    Edit
                  </Link>
                  {localStorage.getItem('defaultClaim') === 'SUPERADMIN' ? (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(request.id)}
                      className="me-2 navBtn"
                    >
                      Delete
                    </Button>
                  ) : (null)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </div>
  );
};

export default CourseQuizPage;
