import React, { useState, useEffect } from 'react';
import { CountData, Course, Topics } from '../data/coursesData';
import { addToCart } from '../services/CartService';
import './CourseCard.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import ReactPlayer from 'react-player';
import { addCourseCount } from '../services/DashboardService';

interface CourseCardProps {
  course: Course;
  onAddToCart: (course: Course) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onAddToCart }) => {
  const { updateCartItems } = useCart();

  const handleAddToCart = () => {
    addToCart(course);
    updateCartItems();
  };

  const {
    id,
    title,
    description,
    image,
    fee,
    rating,
    Topics,
    badgeText,
    source,
    externalLink,
    courseDisplayId,
    orders,
    author
  } = course;

  const [totalTopic, setTotalTopicsData] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [selectedTopics, setSelectedTopics] = useState<Topics | null>(null);
  const navigate = useNavigate();

  const updateCourseCount = async (column: string, courseId: string) => {
    try {
      // Create a payload with the required data
      const payload: CountData = {
        courseId: courseId, // Replace with dynamic course ID
        pageUrl: window.location.href, // Replace with the current page URL
        metaData: "", // Optional, add if needed
        buyNowCount: column === 'BUY-NOW' ? "1" : '0', // Or update with appropriate counts
        viewDetailCount: column === 'VIEW-DETAIL' ? "1" : '0', // Or update with appropriate counts
        watchNowCount: column === 'WATCH-NOW' ? "1" : '0', // Or update with appropriate counts
        countColumn: column,
      };

      // Sending the payload to the backend service to update course counts
      const response = await addCourseCount(payload);

      // Handle successful response (you can use response for further actions)
      console.log("Course count updated:", response);

    } catch (err) {
      console.error("Error updating course count:", err);
    }
  };
  const handleMouseEnter = () => {
    const timeout = setTimeout(() => setShowVideo(true), 1000); // Show video after 1 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setShowVideo(false); // Revert to image
  };
  useEffect(() => {
    // Calculate total topics once when Topics change
    if (Topics) {
      const total = Topics.filter((item) => item.parentId !== null).length || 0;
      setTotalTopicsData(total);

      if ((course.orders?.length ?? 0) > 0 || course.fee < 1 || course.Topics?.some((v) => { return v.isTrailer === "1" })) {
        if (course.Topics?.some((v) => { return v.isTrailer === "1" })) {
          const trailerData = course.Topics?.filter((v) => { return v.isTrailer === "1" })[0]
          setSelectedTopics(trailerData)
        }
        else {
          setSelectedTopics(course.Topics?.[1] ?? null)
        }
      }
    }
  }, [Topics]);
 const  navigateToView = () =>
  {
    console.log("clicked")
  updateCourseCount('VIEW-DETAIL', id.toString());
  navigate(`/course-details/${courseDisplayId}/${id}`)
  }
  return (
    <div className="c-card" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className="card mpt-course-card">
        <div className="card-image-container">
          {showVideo && selectedTopics?.externalLink ? (

            <ReactPlayer
              title={selectedTopics?.title}
              url={selectedTopics?.externalLink}
              playing={true}
              controls={true}
              muted={true}
              width="100%"
              height="220px"
            />
          ) : (
            <img src={image} alt={title} width={400} onClick={() => navigateToView()} height={400} className="img-fluid c-img" />
          )}
          <div className="ribbon-container" title={`${badgeText.toLowerCase()} Course`}>
            <span className={`ribbon ${badgeText.toLowerCase()}`}>
            {badgeText.toLowerCase() === 'free' ? (<i className='fa fa-free-code-camp freeI'></i>) : (null)}
            {badgeText.toLowerCase() === 'standard' ? (<i className='fa fa-check-circle-o standardI'></i>) : (null)}
              {badgeText.toLowerCase() === 'premium' ? (<i className='fa fa-certificate premiumI'></i>) : (null)} {badgeText}</span>
          </div>
        </div>
        <div className="card-body card-radius">
          <div className="authorImg">

            {author?.image ? (
              <Link
                to={`/mentor-details/${author?.name}/${author?.id}`}
                title={`Click to see mentor ${author?.name}`}
              >
                <img src={author.image} alt={author?.name} height={50} width={50} className='img-fluid'></img>
              </Link>
            ) : (null)}
          </div>
          {source !== 'local' && externalLink !== '' ? (
            <p className="card-title truncate1" title={title}>
              {title}
            </p>
          ) : (
            <Link
              to={`/course-details/${courseDisplayId}/${id}`}
              className="card-title truncate1"
              onClick={() => updateCourseCount('VIEW-DETAIL', id.toString())}
            >
              <p title={title}>{title}</p>
            </Link>
          )}
          <div className="course-details" onClick={() => navigateToView()}>
            {fee < 1 ? (
              <div className="course-badge free-course">Free</div>
            ) : (
              <div className="course-badge paid-course">👑{fee} INR</div>
            )}
            <div className="rating">
              <span>{rating} ⭐</span>
            </div>
            <div className="course-badge course-topics">
              Topics: {totalTopic}
            </div>
          </div>
          <p className="card-text truncate" title={description}>
            {description}
          </p>
          <div className="button-container">
            {source !== 'local' && externalLink !== '' ? (
              <a
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="button applyBtn mt-2"
              >
                View
              </a>
            ) : fee < 1 ? (
              <Link
                to={`/course-details/${courseDisplayId}/${id}`}
                className="button viewBtn mt-2"
                onClick={() => updateCourseCount('WATCH-NOW', id.toString())}
              >
                Watch now
              </Link>
            ) : (
              <Link
                to={`/course-details/${courseDisplayId}/${id}`}
                className="button enroll-button mt-2"
                onClick={() => updateCourseCount('BUY-NOW', id.toString())}
              >
                {(orders?.length ?? 0) > 0 ? 'Start Learning' : 'Enroll Now'}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
