import React, { useState } from 'react';
import './CareerPage.model.scss';
import { jobList, JobCategoryModel } from '../data/jobData';
import { APPLYNOW } from '../utils/Endpoints';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const jobPostings: JobCategoryModel[] = jobList;

const CareerPage: React.FC = () => {
  // Function to redirect to the specified form URL
  const redirectToForm = (url: string) => {
    window.location.href = url;
  };

  // State to manage visibility of job details
  const [visibleJobDetails, setVisibleJobDetails] = useState<number | null>(null);

  const toggleJobDetails = (jobId: number) => {
    setVisibleJobDetails((prev) => (prev === jobId ? null : jobId));
  };

  return (
    <div className="career-page">
      <Helmet>
        <title>Career Opportunities - Course Echo | Join Our Team</title>
        <meta
          name="description"
          content="Explore career opportunities at Course Echo. Join a dynamic team or become a freelance trainer in tech, management, arts, finance, medical, and more."
        />
        <meta
          name="keywords"
          content="Course Echo careers, job opportunities, freelance trainers, Java, Angular, React, AWS, .NET, tech jobs, training jobs, management jobs, arts jobs, finance jobs, medical jobs"
        />
        <meta name="author" content="Course Echo Team" />
        <meta property="og:title" content="Career Opportunities - Course Echo | Join Our Team" />
        <meta
          property="og:description"
          content="Looking for a career in tech, management, arts, or medical fields? Or want to become a freelance trainer? Check out opportunities at Course Echo."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://courseecho.com/careers" />
        <meta
          property="og:image"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Career Opportunities - Course Echo | Join Our Team" />
        <meta
          name="twitter:description"
          content="Join Course Echo for exciting career and freelance trainer opportunities in tech, management, arts, finance, medical domains, and more."
        />
        <meta
          name="twitter:image"
          content="https://images.courseecho.com/dark-logo.webp"
        />
        <meta name="twitter:creator" content="@CourseEcho" />
      </Helmet>


      <h1 className="text text-center detail-h1">Career Opportunities</h1>
      <p className="text text-center">
        Join our growing team and become part of a dynamic environment where your skills will make an impact.
      </p>

      <section className="job-listings">
        {jobPostings.map((category) => (
          <div key={category.id} className="container">
            <div className="row">
              {/* Category Title */}
              <h2>{category.title}</h2>
              <p>{category.description}</p>

              {/* Jobs List */}
              {category.jobs?.map((job) => (
                <div key={job.id} className="m-1 job-posting job-posting-width col-sm-12 col-md-3 col-lg-3">
                  {/* Job Title */}
                  <h2>{job.title}</h2>
                  <p><strong>Location:</strong> {job.location}</p>
                  <p><strong>Description:</strong> {job.description}</p>

                  {/* Job Actions */}
                  <div>
                    {/* Toggle Button */}
                    <button
                      onClick={() => toggleJobDetails(job.id)}
                      className="btn btn-warning me-2 viewBtn"
                    >
                      {visibleJobDetails === job.id ? 'View Less' : 'View More'}
                    </button>

                    {/* Additional Details */}
                    {visibleJobDetails === job.id && (
                      <>
                        <p><strong>Qualifications:</strong> {job.qualifications}</p>
                        <p><strong>Payment:</strong> {job.payment}</p>
                        <p><strong>Schedule:</strong> {job.schedule}</p>
                      </>
                    )}

                    {/* Apply Button */}
                    <Link to={job.formURL}
                      className="btn btn-primary me-2 applyBtn"
                    >
                      Apply Now <i className="fa fa-paper-plane-o"></i>
                    </Link>

                    <Link to="/mentor-earning-calculator"
                      className="btn btn-primary me-2 navBtn"
                      title='Click to calculate how much you can earn'
                    >
                      <i className="fa fa-inr text-success"></i>   Calculator
                    </Link>

                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>


      <section className="freelance-trainers card-body formDiv">
        <h2>Freelance Training Opportunities</h2>
        <p>We are looking for freelance trainers with expertise in the following areas:</p>
        <ul>
          <li><strong>Tech</strong>
            <ul>
              <li>Java</li>
              <li>Node.js</li>
              <li>PHP</li>
              <li>Angular</li>
              <li>JavaScript</li>
              <li>React</li>
              <li>.NET</li>
              <li>AWS</li>
              <li>Azure</li>
              <li>Android Development</li>
              <li>Go</li>
              <li>Rust</li>
              <li>C++</li>
            </ul>
          </li>
          <li><strong>Management</strong>
            <ul>
              <li>Leadership Coaching</li>
              <li>Project Management Mentoring (Agile, Scrum, PMP)</li>
            </ul>
          </li>
          <li><strong>Arts</strong>
            <ul>
              <li>Graphic Design Mentoring</li>
              <li>Music Coaching (Theory, Instruments, Vocal Training)</li>
            </ul>
          </li>
          <li><strong>Finance</strong>
            <ul>
              <li>Investment Mentorship</li>
              <li>Accounting Guidance</li>
            </ul>
          </li>
          <li><strong>Medical</strong>
            <ul>
              <li>Anatomy Instruction</li>
              <li>Nursing Mentoring</li>
            </ul>
          </li>
        </ul>
        <p><strong>Qualifications:</strong> B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree, or relevant courses can apply.</p>
        <p><strong>Payment:</strong> 100-300 INR per topic, based on experience. Payment can be weekly, monthly, or daily.</p>
        <p><strong>Schedule:</strong> Flexible working hours.</p>
        <button className="apply-button viewBtn" onClick={() => redirectToForm(APPLYNOW)}>
          Become a Trainer <sup className='fa fa-paper-plane-o'></sup>
        </button>
      </section>

    </div>
  );
};

export default CareerPage;
